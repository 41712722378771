import {
  Recipient,
  ThreadEdge,
  ThreadPreviewEdge,
  nodeIs,
} from "@utility-types";
import BackStackButton from "components/App/AppLayoutMobile/BackStackButton";
import { Skeleton } from "components/Skeleton";
import { Button, ToggleButton } from "components/design-system/Button";
import { Form } from "components/design-system/Forms";
import { RecipientsSelect } from "components/design-system/Forms/RecipientsSelect";
import { Icon } from "components/design-system/icons";
import { routePath } from "components/routing/utils";
import { routeParams as getRouteParams } from "components/routing/utils";
import AutoSuggestSubjectInput from "components/threads/ThreadCompose/AutoSuggestSubjectInput";
import { State } from "components/threads/ThreadCompose/DraftReducer";
import { DraftForm } from "components/threads/ThreadCompose/types";
import {
  Addable,
  FetchThreadEdgeDocument,
  useUpdateThreadMutation,
} from "generated/graphql";
import useThreadCacheUpdate from "hooks/thread/useThreadCacheUpdate";
import useAuthData from "hooks/useAuthData";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router";
import useAppStateStore from "store/useAppStateStore";
import { formatNameEmoji } from "utils/formatNameEmoji";
import env from "utils/processEnv";
import tw from "utils/tw";
import useLlmModel from "../hooks/useLlmModel";
import AIModelSelect from "./AIModelSelect";

type FormData = {
  chatModel: string;
  recipients: Recipient[];
  subject: string;
};

type Props = {
  autoSuggestSubject?: boolean;
  compose: State;
  isModal?: boolean;
  onAddableChange?: (addable: Addable) => void;
  onRecipientsChange?: (recipients: Recipient[]) => void;
  onSubjectChange?: (subject: string) => void;
  onClose?: (() => void) | undefined;
  readOnly?: boolean;
  secondaryPane?: boolean;
  threadEdge?: ThreadEdge | ThreadPreviewEdge | undefined;
  threadLoading?: boolean;
};

const SetFormDefaults = ({
  defaultModel,
  onRecipientsChange,
  threadEdge,
}: {
  defaultModel: string | undefined;
  onRecipientsChange?: (recipients: Recipient[]) => void;
  threadEdge: ThreadEdge | ThreadPreviewEdge | undefined;
}) => {
  const { getFieldState, setValue, watch } = useFormContext();
  const chatModel = watch("chatModel");
  const subject = watch("subject");
  const recipients = watch("recipients");

  useEffect(() => {
    if (!threadEdge) return;

    const otherRecipients =
      threadEdge?.node.recipients.edges.filter(
        e => e.node.id !== env.glueAIBotID
      ) ?? [];

    if (!getFieldState("chatModel").isDirty && defaultModel) {
      setValue("chatModel", defaultModel);
    }
    if (
      !subject &&
      !getFieldState("subject").isDirty &&
      threadEdge.node.subject
    ) {
      setValue("subject", threadEdge.node.subject);
    }
    if (
      recipients.length === 0 &&
      !getFieldState("recipients").isDirty &&
      otherRecipients.length > 0
    ) {
      setValue(
        "recipients",
        otherRecipients.map(e => e.node)
      );
    }
  }, [
    chatModel,
    defaultModel,
    getFieldState,
    recipients.length,
    setValue,
    subject,
    threadEdge,
  ]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (type !== "change") return;
      switch (name) {
        case "recipients":
          onRecipientsChange?.(values.recipients as Recipient[]);
          break;
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onRecipientsChange, watch]);

  return null;
};

const SubmitButton = () => {
  const { authData } = useAuthData();
  const { watch } = useFormContext<DraftForm>();
  const recipients = watch("recipients");
  const otherRecipients = recipients.filter(
    r => r.id !== env.glueAIBotID && r.id !== authData?.me.id
  );

  return (
    <Button
      buttonFont="subheadBold"
      buttonStyle="primary"
      disabled={!otherRecipients.length}
      type="submit"
    >
      Send
    </Button>
  );
};

const AIThreadHeader = ({
  autoSuggestSubject = true,
  compose,
  isModal,
  onAddableChange,
  onRecipientsChange,
  onSubjectChange,
  onClose,
  readOnly,
  threadEdge,
}: Props) => {
  const { breakpointMD } = useAppStateStore(({ breakpointMD }) => ({
    breakpointMD,
  }));
  const history = useHistory();
  const { onRecipientsChange: updateCacheOnRecipientsChange } =
    useThreadCacheUpdate();

  const { pathname, search } = history.location;
  const { d } = getRouteParams({ pathname, search });

  const [makeThread, setMakeThread] = useState(false);
  const { defaultModel, defaultModelLoading } = useLlmModel(
    threadEdge?.node.id
  );
  const subject = threadEdge?.node.subject;
  const addableNone = compose?.draftForm?.recipientsAddable === Addable.None;

  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });

  const handleSend = (data: FormData) => {
    if (threadEdge?.node.__typename !== "Thread") {
      return Promise.resolve();
    }

    const input = {
      recipients: [...data.recipients.map(r => r.id)],
      subject: formatNameEmoji({ name: data.subject }).nameWithEmoji,
    };

    return updateThread({
      refetchQueries: [FetchThreadEdgeDocument],
      variables: {
        id: threadEdge.node.id,
        input,
      },
    })
      .then(({ data }) => {
        setMakeThread(false);
        if (data && nodeIs(threadEdge, ["ThreadEdge"])) {
          const nextThreadEdge = {
            ...threadEdge,
            node: data.updateThread,
          };
          updateCacheOnRecipientsChange(nextThreadEdge);
        }

        history.push(
          routePath({ superTab: "inbox", threadID: threadEdge.node.id }),
          { setDefaultRoute: ["ai"] }
        );
      })
      .catch(err => {
        console.warn("Error: [onSendMessage] -", err);
      });
  };

  const otherRecipients =
    threadEdge?.node.recipients.edges.filter(
      e => e.node.id !== env.glueAIBotID
    ) ?? [];

  return (
    <Form<FormData>
      key={threadEdge?.node.id} // reset form on thread change
      onSubmit={handleSend}
      useFormProps={{
        defaultValues: {
          chatModel: defaultModel ?? "",
          recipients: otherRecipients?.map(e => e.node) ?? [],
          subject: threadEdge?.node.subject ?? "",
        },
      }}
    >
      <SetFormDefaults
        defaultModel={defaultModel}
        onRecipientsChange={onRecipientsChange}
        threadEdge={threadEdge}
      />

      <div
        className={tw(
          "border-b border-border-container flex flex-col items-start shrink-0 w-full relative",
          {
            "pr-36": isModal && !makeThread,
          }
        )}
        data-testid="PaneHeader"
      >
        <div className="flex flex-col w-full">
          <div
            className={tw("flex items-center gap-12 grow min-w-0 pt-16 pr-16", {
              "pb-16": !makeThread,
            })}
          >
            <div className={tw("flex items-center grow min-w-0 pl-16 w-full")}>
              {!!onClose && (
                <Button
                  buttonStyle="subtle"
                  buttonType="text"
                  className="px-8 mr-12"
                  icon="Close"
                  iconSize={20}
                  iconStroke={2}
                  onClick={e => {
                    e.stopPropagation();
                    onClose();
                  }}
                />
              )}
              {!isModal && <BackStackButton size="small" />}

              <div
                className={tw(
                  "flex items-center grow min-w-0 h-28 text-headline-bold text-text-primary",
                  {
                    "pr-36": isModal,
                  }
                )}
              >
                {threadEdge ? (
                  makeThread || !subject ? (
                    <AutoSuggestSubjectInput
                      autoSuggestSubject={autoSuggestSubject}
                      className="w-full"
                      compose={compose}
                      isGlueAI={!makeThread}
                      onSubjectChange={onSubjectChange}
                      readOnly={readOnly}
                      textInputClassName="!pl-0"
                    />
                  ) : (
                    <div className="truncate">{subject}</div>
                  )
                ) : (
                  <Skeleton height="24px" width="160px" />
                )}
              </div>
            </div>

            {!makeThread && (
              <div className="flex gap-8 justify-end grow-0 shrink-0 h-28 relative z-1">
                <Button
                  buttonStyle="icon-secondary"
                  buttonType="none"
                  className={tw(
                    "border-1 border-border-container hover:border-border-container-hover focus-visible-shadow h-28 pl-4 rounded-md",
                    {
                      "pr-8": breakpointMD,
                    }
                  )}
                  onClick={() => {
                    setMakeThread(true);
                  }}
                  type="button"
                >
                  <Icon className="mr-4" icon="UserAdd" size={20} />
                  {breakpointMD && <span className="text-footnote">Add</span>}
                </Button>
                <AIModelSelect
                  defaultModel={defaultModel}
                  defaultModelLoading={defaultModelLoading}
                  mode={!breakpointMD || d ? "icon-only" : "default"}
                  threadID={threadEdge?.node.id}
                />
              </div>
            )}
          </div>

          {makeThread && (
            <>
              <div className="flex items-end min-h-32 mb-8 px-16">
                <RecipientsSelect<DraftForm>
                  autoFocus={false}
                  borderWidth={0}
                  className="z-2 w-full border-none"
                  disabled={readOnly}
                  filterMe={true}
                  maxLines={3}
                  name="recipients"
                  placeholder="To groups, users, or emails..."
                  wrapperClassName="!my-0 grow min-w-0 text-subhead"
                  includeGlueAI={false}
                />
                <ToggleButton
                  buttonStyle="none"
                  className="!p-8 ml-8 mb-2 mr-2"
                  disabledState={{
                    icon: "Unlock",
                    iconClassName: "text-icon-subtle",
                    title: "Lock recipient list",
                  }}
                  enabledState={{
                    icon: "Lock",
                    iconClassName: "text-accent-badge",
                    title: "Unlock recipient list",
                  }}
                  iconSize={18}
                  onClick={() => {
                    onAddableChange?.(
                      addableNone ? Addable.Anyone : Addable.None
                    );
                  }}
                  toggled={addableNone}
                  type="button"
                />
              </div>

              <div className="flex items-center justify-between py-8 px-16 border-t-1 border-border-container">
                <div className="flex items-center text-text-secondary text-subhead select-none">
                  <Icon
                    className="mr-8 text-icon-action"
                    icon="Info"
                    size={20}
                  />
                  Add recipients to start a thread.
                </div>
                <div className="flex items-center gap-8">
                  <Button
                    buttonFont="subheadBold"
                    buttonStyle="icon-secondary"
                    onClick={() => {
                      setMakeThread(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <SubmitButton />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Form>
  );
};

export default AIThreadHeader;
