import { SIMULATE_APP_UPDATE_AVAILABLE } from "components/App/AppUpdate";
import { Button } from "components/design-system/Button";

const SimulateAppUpdateAvailable = () => (
  <div>
    <Button
      onClick={() => {
        localStorage.setItem(SIMULATE_APP_UPDATE_AVAILABLE, "true");
        window.location.reload();
      }}
      type="button"
    >
      Simulate update available
    </Button>
  </div>
);

export default SimulateAppUpdateAvailable;
