import { Button } from "components/design-system/Button";
import useLocalSettingsStore from "store/useLocalSettingsStore";

const NotificationPermission = () => (
  <div className="flex gap-12 items-center">
    <span className="text-subhead-bold">Notifications asked at:</span>

    <Button
      onClick={() => {
        useLocalSettingsStore.setState({
          notificationAskedAt: Date.now() + 8 * 24 * 60 * 60 * 1000, // setting a date 8 days ahead of now
        });
      }}
      type="button"
    >
      + 8 days
    </Button>

    <Button
      onClick={() => {
        useLocalSettingsStore.setState({
          notificationAskedAt: undefined,
        });
      }}
      type="button"
    >
      Clear
    </Button>
  </div>
);

export default NotificationPermission;
