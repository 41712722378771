import { useMemo, useRef, useState } from "react";

import { Message } from "@utility-types";

import MessageAvatar from "components/design-system/ui/MessageAvatar";
import { Timestamp } from "components/thread/ThreadView/stream-components/Message/components";
import { Author } from "components/thread/ThreadView/stream-components/Message/components/Author";
import {
  isOnlyEmojis as isOnlyEmojisFunction,
  renderText,
  renderTextWithOptions,
} from "components/thread/ThreadView/stream-components/Message/utils";
import { MessageActionMenu } from "components/thread/ThreadView/stream-components/MessageActions";
import { MessageAttachments } from "components/thread/ThreadView/stream-components/MessageAttachments";
import useAuthData from "hooks/useAuthData";
import { formatDateWithTime } from "utils/formatDate";
import generateRandomId from "utils/generateRandomId";
import { glueMessageToStreamMessage } from "utils/stream/message";
import { truncateMarkdown } from "utils/thread/truncateMarkdown";
import tw from "utils/tw";

import { ReactionListItems } from "components/Reactions";
import { Skeleton } from "components/Skeleton";

import useGlueAIFeedback from "components/GlueAIFeedback/hooks/useGlueAIFeedback";
import useFeedReactions from "./hooks/useFeedReactions";

const MessageContent = ({
  isFirst,
  message,
  messageActionMenu,
  messageGlueStatus,
}: {
  collapseToggleElement?: JSX.Element;
  isFirst?: boolean;
  message: (Message & { type?: string }) | undefined;
  messageActionMenu?: JSX.Element | null;
  messageGlueStatus?: JSX.Element | null;
}) => {
  const truncateLength = isFirst ? 600 : 300;
  const [collapsed, setCollapsed] = useState(
    message && message.text.length > truncateLength
  );

  const collapseButtonIDRef = useRef(generateRandomId("collapse-btn"));
  const messageText = useMemo(() => renderText(message?.text ?? ""), [message]);
  const collapsedMessageText = useMemo(() => {
    const text = truncateMarkdown(
      message?.text || "",
      truncateLength,
      `<button id=${collapseButtonIDRef.current}>See more</button>`
    );
    const truncatedMessage = renderTextWithOptions(`${text}\n`, {
      allowButton: true,
    });

    return truncatedMessage;
  }, [message?.text, truncateLength]);

  const deleted = message?.type === "deleted";

  const asStreamMessage =
    message &&
    glueMessageToStreamMessage({
      ...message,
    });

  const messageGlueAttachment: JSX.Element | null =
    message && !deleted ? (
      <MessageAttachments
        attachments={asStreamMessage?.attachments || []}
        messageId={message.id}
        compact
      />
    ) : null;

  const isOnlyEmojis = isOnlyEmojisFunction(message?.text);

  return (
    <div className="relative w-full min-w-0">
      {messageActionMenu}
      <div
        className="str-chat-message-content"
        data-testid="message-glue-content"
      >
        {message ? (
          <div
            className={tw({ "text-3xl": isOnlyEmojis })}
            data-testid="message-glue-message"
            onClick={e => {
              const { target } = e;
              if (
                target instanceof HTMLButtonElement &&
                target.id === collapseButtonIDRef.current
              ) {
                setCollapsed(false);
              }
            }}
          >
            {collapsed ? collapsedMessageText : messageText}
          </div>
        ) : (
          <Skeleton />
        )}

        {!collapsed && message?.text === "" && messageGlueAttachment}
      </div>
      {!collapsed && message?.text !== "" && messageGlueAttachment}
      {!collapsed && message?.updatedAt && (
        <div className={"text-caption text-text-subtle mt-2 mb-4"}>
          (edited&nbsp;
          <Timestamp
            customClass="text-caption text-text-subtle"
            date={message.updatedAt}
            formatDate={formatDateWithTime}
          />
          )
        </div>
      )}

      {messageGlueStatus}
    </div>
  );
};

const FeedMessage = ({
  isFirst,
  isGrouped,
  message,
}: {
  isFirst?: boolean;
  isGrouped?: boolean;
  message: Message;
}) => {
  const { authData } = useAuthData();

  const actionWrapperRef = useRef<HTMLDivElement>(null);

  const isMyMessage = message.user.id === authData?.me.id;

  const feedReactions = useFeedReactions({
    message,
  });

  const { sendGeneratedResponseFeedback } = useGlueAIFeedback();
  const handleSendFeedback = () =>
    sendGeneratedResponseFeedback({
      messageText: message.text ?? "",
      threadID: message.threadID,
    });

  const messageActionMenu = MessageActionMenu({
    actionWrapperRef: actionWrapperRef,
    handleReaction: feedReactions.handleReaction,
    handleQuoteReply: () => null,
    handleReplyInThread: () => null,
    handleSendFeedback,
    message: glueMessageToStreamMessage(message),
    messageAction: null,
    showReaction: true,
    showQuoteReply: false,
    showReplyInThread: false,
  });

  const displayEmojiPicker = Object.values(feedReactions.reactionCounts).some(
    r => r > 0
  );

  return (
    <div className="str-chat-message relative py-6 flex items-start w-full min-w-0">
      {isGrouped ? (
        <div className="w-48 shrink-0">
          <Timestamp
            customClass="relative right-4 inline-block mt-6 text-xs text-text-subtle text-right select-none invisible capitalize transition-opacity duration-350 opacity-0 subsequent-timestamp"
            date={message.updatedAt ?? message.createdAt}
          />
        </div>
      ) : (
        <div className="pr-12">
          <MessageAvatar
            clickable={true}
            image={message.user.avatarURL ?? undefined}
            isMyMessage={isMyMessage}
            name={message.user.name}
            userID={message.user.id}
          />
        </div>
      )}

      <div className="flex flex-col min-w-0 w-full">
        {!isGrouped && (
          <Author
            className="!pr-0"
            clickable={true}
            createdAt={message.createdAt}
            isMyMessage={message.user.id === authData?.me.id}
            messageActionMenu={messageActionMenu}
            messageID={message.id}
            name={message.user.name}
            onUserClick={() => null}
            userID={message.user.id}
          />
        )}

        <MessageContent
          isFirst={isFirst}
          message={message}
          messageActionMenu={isGrouped ? messageActionMenu : null}
        />

        {!displayEmojiPicker ? null : (
          <ReactionListItems
            getAllReactions={async () => ({
              duration: "0",
              reactions: feedReactions.allReactions,
            })}
            isOwnReaction={(emojiID: string) =>
              !!feedReactions.ownReactions?.find(r => r.type === emojiID)
            }
            {...feedReactions}
          />
        )}
      </div>
    </div>
  );
};

export default FeedMessage;
