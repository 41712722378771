import { useState } from "react";

import { nodeAs } from "@utility-types";
import { ModalProps } from "components/ModalKit/Modal";
import { Header, Main } from "components/ModalKit/Parts";
import {
  Addable,
  MemberRole,
  useFetchThreadEdgeQuery,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useMemberEdge from "hooks/useMemberEdge";
import { isMobile } from "utils/platform";

import { StandardModal } from "components/Modals";
import { Tabs } from "components/Tabs";
import { ThreadsApps } from "components/apps";

import SubjectForm from "./SubjectForm";
import ThreadRecipients from "./ThreadRecipients";
import useThreadApps from "./hooks/useThreadApps";

type Props = {
  focusSubject?: boolean;
  threadID?: string;
} & ModalProps;

enum ThreadTab { // in order of UI appearance
  Recipients = "recipients",
  Apps = "apps",
}

const ThreadInfoModal = ({ focusSubject, threadID, ...props }: Props) => {
  const [selectedTab, setSelectedTab] = useState<ThreadTab>(
    ThreadTab.Recipients
  );
  const { authData, authReady } = useAuthData();

  const { data } = useFetchThreadEdgeQuery({
    fetchPolicy: authReady ? "cache-first" : "cache-only",
    nextFetchPolicy: "cache-first",
    skip: !authData?.me.id || !threadID,
    variables: { id: `${threadID}-${authData?.me.id}` },
  });

  const threadEdge = nodeAs(data?.node, ["ThreadEdge", "ThreadPreviewEdge"]);

  const { memberEdge, previewEdge } = useMemberEdge(threadEdge);
  const dataEdge = memberEdge || previewEdge;

  const isAdmin = memberEdge?.recipientRole === MemberRole.Admin;
  const isLocked = memberEdge?.node.recipientsAddable === Addable.None;

  const thread = dataEdge?.node;

  const appsAdded = nodeAs(thread, ["Thread"])?.apps.edges ?? [];

  const { addApp, appsAvailable, editApp, removeApp, workspaceID } =
    useThreadApps(thread);

  const tabs = [
    {
      tab: ThreadTab.Recipients,
    },
    {
      tab: ThreadTab.Apps,
    },
  ];

  if (!thread) return <div />;

  return (
    <StandardModal
      header={
        <Header variant="bordered">
          <h3 className="m-0">Thread Info</h3>
        </Header>
      }
      {...props}
    >
      <Main className="py-16 px-16 md:px-32">
        <SubjectForm
          canEdit={isAdmin || !isLocked}
          focusSubject={focusSubject}
          thread={thread}
        />
        {!isMobile() && (appsAdded?.length || appsAvailable?.length) ? (
          <Tabs<ThreadTab>
            className="border-b border-border-container"
            onClickTab={tab => setSelectedTab(tab)}
            selectedTab={selectedTab}
            tabs={tabs}
          />
        ) : null}
        {selectedTab === ThreadTab.Recipients && (
          <ThreadRecipients
            isAdmin={isAdmin}
            isLocked={isLocked}
            isMember={!!memberEdge}
            modalId={props.modalId}
            thread={thread}
            threadEdge={threadEdge}
          />
        )}
        {selectedTab === ThreadTab.Apps && (
          <ThreadsApps
            addApp={addApp}
            canChangeApps={!isLocked || isAdmin}
            configureApp={app => editApp(app, thread?.id, workspaceID)}
            appsAdded={appsAdded}
            appsAvailable={appsAvailable}
            name={thread.subject}
            removeApp={removeApp}
            workspaceID={workspaceID}
          />
        )}
      </Main>
    </StandardModal>
  );
};

export default ThreadInfoModal;
