import { ReactNode, RefObject, useCallback } from "react";

import { ObservableQuery } from "@apollo/client";

import { Workspace, nodeIs } from "@utility-types";

import ListHeaderWithAction from "components/ListHeaderWithAction";
import { Skeleton } from "components/Skeleton";
import { Button } from "components/design-system/Button";
import CreateGroupModal from "components/group/CreateGroup/CreateGroupModal";

import {
  FetchWorkspaceOrPreviewEdgeQuery,
  WorkspaceFieldsFragment,
} from "generated/graphql";
import useModalStore from "store/useModalStore";

import { useSnackbar } from "providers/SnackbarProvider";
import { Virtuoso } from "react-virtuoso";
import WorkspaceGroupItem from "./groups/WorkspaceGroupItem";

type Items = [
  WorkspaceFieldsFragment,
  ...WorkspaceFieldsFragment["groups"]["edges"],
];

type WorkspaceGroupsProps = {
  fetchMore: ObservableQuery<FetchWorkspaceOrPreviewEdgeQuery>["fetchMore"];
  hasError: boolean;
  loading: boolean;
  workspace: Workspace;
  scrollContainerRef: RefObject<HTMLDivElement>;
};

const WorkspaceGroups = ({
  fetchMore,
  hasError,
  loading,
  scrollContainerRef,
  workspace,
}: WorkspaceGroupsProps): JSX.Element => {
  const { openSnackbar } = useSnackbar();

  const { hasNextPage, endCursor } = workspace.groups.pageInfo;

  const { openModal } = useModalStore(({ openModal }) => ({
    openModal,
  }));

  const openCreateGroupModal = useCallback(() => {
    openModal(<CreateGroupModal workspaceID={workspace.id} />);
  }, [openModal, workspace]);

  const loadMore = useCallback(() => {
    if (hasError || loading) {
      return;
    }

    return fetchMore({
      variables: { groupsAfter: endCursor },
    }).catch(err => {
      openSnackbar("error", err.message);
    });
  }, [endCursor, fetchMore, hasError, loading, openSnackbar]);

  const totalGroups = workspace?.groups?.totalCount;

  const items: Items = [workspace, ...workspace.groups.edges];

  const Footer = () =>
    hasNextPage && <div className="pb-16 text-text-subtle">Loading…</div>;

  const Header = () => (
    <ListHeaderWithAction
      className="mt-16"
      button={
        workspace && (
          <Button
            buttonStyle="simplePrimary"
            buttonType="text"
            icon="Plus"
            iconClassName="mr-3"
            iconSize={15}
            iconStroke={3}
            onClick={openCreateGroupModal}
          >
            Create new group
          </Button>
        )
      }
    >
      <div className="text-base font-bold">
        {workspace ? (totalGroups || 0) + 1 : <Skeleton width="15px" />}
        &nbsp;groups
      </div>
    </ListHeaderWithAction>
  );

  return (
    <Virtuoso
      customScrollParent={scrollContainerRef.current ?? undefined}
      className="native:pb-safe-area h-full w-full"
      data={items}
      endReached={loadMore}
      increaseViewportBy={100}
      components={{
        Header,
        Footer,
      }}
      itemContent={(_index, data) => {
        let item: ReactNode;
        if (nodeIs(data, ["Workspace"])) {
          item = <WorkspaceGroupItem group={data} />;
        } else if (nodeIs(data, ["GroupOrPreviewEdge"])) {
          item = <WorkspaceGroupItem group={data.node} />;
        } else {
          console.error(
            "[WorkspaceModalGroupsTab] Unknown item type, skipping"
          );
          return;
        }

        return <div className="py-8">{item}</div>;
      }}
    />
  );
};

export default WorkspaceGroups;
