import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import GlobeImg from "assets/icons-jpg/globe.jpg";
import { Tabs } from "components/Tabs";
import ViewHeader from "components/design-system/ui/ViewHeader";
import { useFetchExternalGroupsQuery } from "generated/graphql";
import useAuthData from "hooks/useAuthData";

import { currentPathWithSearch } from "components/routing/utils";
import ExternalGroupsDirectory from "./ExternalGroupsDirectory";
import GroupDirectory from "./GroupDirectory";

const useExternalGroups = (searchTerm: string) => {
  const { authReady } = useAuthData();
  const pageSize = Math.ceil(window.innerHeight / 64); // 64px is the height of a row

  const {
    data: externalGroups,
    fetchMore,
    loading,
  } = useFetchExternalGroupsQuery({
    fetchPolicy: authReady ? "cache-and-network" : "cache-only",
    nextFetchPolicy: "cache-first",
    variables: {
      groupsFirst: pageSize,
      match: searchTerm,
    },
  });

  const { endCursor: afterCursor, hasNextPage } = externalGroups?.groups
    .pageInfo ?? {
    endCursor: null,
    hasNextPage: false,
  };

  return {
    afterCursor,
    externalGroups: externalGroups?.groups.edges ?? [],
    fetchMore,
    hasNextPage,
    loading,
  };
};

const GroupsDirectory = () => {
  const history = useHistory();
  const location = useLocation<{ workspaceID?: string }>();
  const { authData } = useAuthData();

  const searchState = useState("");
  const [searchTerm] = searchState;

  const externalGroupsData = useExternalGroups(searchTerm);

  const workspaces = authData?.workspaces.edges.map(
    workspace => workspace.node
  );

  const [selectedTab, setSelectedTab] = useState(
    location.state?.workspaceID ?? workspaces?.[0]?.id ?? ""
  );

  useEffect(() => {
    // if route state changes, update the selected tab
    const workspaceID = location.state?.workspaceID;
    if (!workspaceID) return;
    setSelectedTab(workspaceID);
  }, [location.state?.workspaceID]);

  const tabs =
    workspaces?.map(w => ({
      tab: w.id,
      tabTitle: w.name,
    })) ?? [];

  // if we have external groups to begin with, set a ref to keep the tab even when search yields zero external results
  const hasExternalGroups = useRef(
    externalGroupsData?.externalGroups.length > 0
  );
  hasExternalGroups.current ||= externalGroupsData.externalGroups.length > 0;

  if (hasExternalGroups.current) {
    tabs.push({
      tab: "external",
      tabTitle: "External Groups",
    });
  }

  return (
    <div className="bg-background-app-secondary flex flex-col h-full w-full">
      <ViewHeader
        onClose={() => history.push(currentPathWithSearch({ v: undefined }))}
        imgSrc={GlobeImg}
        name="Directory"
      >
        {tabs.length > 1 && (
          <div className="h-[40px] max-w-[832px] mt-8 mx-auto overflow-hidden px-16 w-full">
            {/* ^^^ facilitates hiding of horizontal scrollbar; `pb-16` below creates a seat for the scrollbar, overflowing this container */}
            <Tabs
              className="overflow-x-scroll pb-16 w-full"
              onClickTab={setSelectedTab}
              selectedTab={selectedTab}
              tabs={tabs}
            />
          </div>
        )}
      </ViewHeader>
      {selectedTab === "external" ? (
        <ExternalGroupsDirectory
          externalGroupsData={externalGroupsData}
          searchState={searchState}
        />
      ) : (
        <GroupDirectory
          searchState={searchState}
          workspaceID={selectedTab ?? ""}
        />
      )}
    </div>
  );
};

export default GroupsDirectory;
