import { Recipient, nodeAs } from "@utility-types";
import { Button } from "components/design-system/Button";
import { Label } from "components/design-system/Forms";
import {
  DeleteThreadDocument,
  FetchPendingJoinApprovalsDocument,
  FetchThreadEdgeDocument,
  FetchThreadEdgeQuery,
  LeaveThreadDocument,
  ThreadFieldsFragment,
  ThreadListDocument,
  ThreadPreviewFieldsFragment,
  useApproveJoinApprovalMutation,
  useCancelRequestJoinThreadMutation,
  useFetchMessageMetadataQuery,
  useFetchPendingJoinApprovalsQuery,
  useUpdateThreadMutation,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useForceUpdate from "hooks/useForceUpdate";
import useModalStore from "store/useModalStore";

import { ConfirmationModal } from "components/Modals";

import { useApolloClient } from "@apollo/client";
import { MembersList } from "components/MembersList";
import { PendingMembersListItem } from "components/MembersList/PendingMembersListItem";
import useMemberEdge from "hooks/useMemberEdge";
import AddRecipients from "./AddRecipients";
import RecipientsAddable from "./RecipientsAddable";
import ThreadRecipientItem from "./ThreadRecipientItem";

type Props = {
  isAdmin: boolean;
  isLocked: boolean;
  isMember: boolean;
  modalId?: string;
  thread?: ThreadFieldsFragment | ThreadPreviewFieldsFragment;
  threadEdge?: FetchThreadEdgeQuery["node"];
};

const ThreadRecipients = ({
  isAdmin,
  isLocked,
  isMember,
  modalId,
  thread,
  threadEdge: threadEdgeProp,
}: Props) => {
  const { authData } = useAuthData();
  const apolloClient = useApolloClient();

  const { closeModal, openModal } = useModalStore(
    ({ closeModal, openModal }) => ({
      closeModal,
      openModal,
    })
  );

  const threadEdge = nodeAs(threadEdgeProp, [
    "ThreadEdge",
    "ThreadPreviewEdge",
  ]);
  const threadID = thread?.id;

  const isRecipient = // is a direct recipient vs. being a member within a recipient group
    isMember &&
    thread?.recipients.edges
      .map(e => e.node.id)
      .includes(authData?.me.id || "");

  const { memberEdge } = useMemberEdge(threadEdge);

  const { data: messageMetadata, loading: metadataLoading } =
    useFetchMessageMetadataQuery({
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
      variables: { id: memberEdge?.node.firstMessage?.id ?? "" },
      skip: !memberEdge,
    });

  const isThreadResponseToUser =
    messageMetadata?.messageMetadata?.aiResponseInfo?.toUserID ===
    authData?.me.id;

  const [approveJoinApproval] = useApproveJoinApprovalMutation({
    refetchQueries: ["FetchThreadEdge", "FetchPendingJoinApprovals"],
  });

  const [cancelRequestJoinThread] = useCancelRequestJoinThreadMutation({
    refetchQueries: ["FetchThreadEdge", "FetchPendingJoinApprovals"],
  });

  const { data: joinApprovalsData } = useFetchPendingJoinApprovalsQuery({
    fetchPolicy: "cache-and-network",
    variables: { id: threadID },
  });

  const handleThreadAction = (action: "leave" | "delete") => {
    if (!threadEdge) return;
    apolloClient
      .mutate({
        mutation:
          action === "leave" ? LeaveThreadDocument : DeleteThreadDocument,
        refetchQueries: [ThreadListDocument],
        variables: { id: threadEdge?.node.id },
      })
      .then(() => closeModal(`${modalId}`));
  };

  const handleDeleteThread = () => {
    if (!threadEdge) return;
    openModal(
      <ConfirmationModal
        confirmLabel="Delete Thread"
        header={`Delete "${threadEdge.node.subject}"?`}
        message="This will delete the thread for all users."
        onConfirm={async () => handleThreadAction("delete")}
        isDestructive
      />
    );
  };

  const handleLeaveThread = () => {
    if (!threadEdge) return;
    openModal(
      <ConfirmationModal
        confirmLabel="Leave Thread"
        header={`Leave "${threadEdge.node.subject}"?`}
        message="To rejoin, you'll need to be added by an admin."
        onConfirm={async () => handleThreadAction("leave")}
      />
    );
  };

  const [updateThread] = useUpdateThreadMutation({
    errorPolicy: "all",
  });
  const forceUpdate = useForceUpdate();

  const handleApprovePendingApproval = async (approvalID: string) => {
    await approveJoinApproval({ variables: { joinApprovalID: approvalID } });
  };

  const handleCancelPendingApproval = async (approvalID: string) => {
    await cancelRequestJoinThread({ variables: { id: approvalID } });
  };

  const handleRemoveUser = (recipientNode: Recipient) => {
    if (!thread) return;

    openModal(
      <ConfirmationModal
        confirmLabel="Remove"
        header={`Remove ${recipientNode.name} from the thread?`}
        message={<span>They'll lose access to the thread's messages.</span>}
        onConfirm={() => {
          const recipients = thread?.recipients.edges
            .map(e => e.node.id)
            .filter(id => id !== recipientNode.id);

          return updateThread({
            refetchQueries: [
              FetchThreadEdgeDocument,
              FetchPendingJoinApprovalsDocument,
            ],
            variables: {
              id: thread.id,
              input: {
                recipients,
              },
            },
          }).catch(err => {
            console.warn("Error: [onChangeRecipients] -", err);
            forceUpdate();
          });
        }}
      />
    );
  };

  return (
    <div className="pt-16">
      <Label className="!font-semibold !text-text-strong">Recipients</Label>
      {isMember && (
        <>
          <AddRecipients canAdd={isAdmin || !isLocked} thread={thread} />
          <RecipientsAddable isAdmin={isAdmin} thread={thread} />
        </>
      )}

      {joinApprovalsData && (
        <div className="mb-24">
          <MembersList>
            {[
              ...joinApprovalsData.joinApprovals.edges,
              ...joinApprovalsData.joinRequests.edges,
            ].map(({ node: approval }) => {
              const joining = nodeAs(approval.joining, ["User"]);
              return (
                joining && (
                  <PendingMembersListItem
                    approvalID={approval.id}
                    isAdmin={isAdmin}
                    isByMe={approval.requester.id === authData?.me.id}
                    isForMe={approval.joining.id === authData?.me.id}
                    isInvite={approval.requester.id === approval.admin?.id}
                    joining={joining}
                    requestedAt={new Date(approval.requestedAt)}
                    onApprove={handleApprovePendingApproval}
                    onCancel={handleCancelPendingApproval}
                  />
                )
              );
            })}
          </MembersList>
        </div>
      )}

      {thread && thread.recipients.edges.length > 0 && (
        <div className="mb-24">
          {thread?.recipients.edges.map((recipient, i) => {
            const isThreadStarter = i === 0;

            return (
              <ThreadRecipientItem
                isAdmin={isAdmin}
                isThreadStarter={isThreadStarter}
                handleRemoveUser={handleRemoveUser}
                recipient={recipient.node}
              />
            );
          })}
        </div>
      )}

      {isAdmin || isThreadResponseToUser ? (
        <Button
          buttonStyle="simpleDestructive"
          className="!px-0"
          icon="Trash"
          onClick={handleDeleteThread}
          disabled={metadataLoading}
          type="button"
        >
          Delete Thread
        </Button>
      ) : (
        isRecipient && (
          <Button
            buttonStyle="simpleDestructive"
            className="!px-0"
            icon="Leave"
            onClick={handleLeaveThread}
            disabled={metadataLoading}
            type="button"
          >
            Leave Thread
          </Button>
        )
      )}
    </div>
  );
};

export default ThreadRecipients;
