import { differenceInDays } from "date-fns";
import useLocalSettingsStore from "store/useLocalSettingsStore";
import useModalStore from "store/useModalStore";

import NotificationsPermissionsModal from "../NotificationsPermissionsModal";
import { hasDefaultPermission } from "../permissions";

const useRequestPermission = () => {
  const { openModal } = useModalStore(({ openModal }) => ({ openModal }));
  const { notificationAskedAt: askedAt = 0 } = useLocalSettingsStore(
    ({ notificationAskedAt }) => ({ notificationAskedAt })
  );

  const requestPermission = async () => {
    if (!(await hasDefaultPermission())) return;

    const daysSince = differenceInDays(askedAt, Date.now());
    if (daysSince >= 7)
      openModal(<NotificationsPermissionsModal textKey="reAsk" isPersistent />);
  };

  return { requestPermission };
};

export default useRequestPermission;
