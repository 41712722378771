import "katex/dist/katex.min.css";
import { ButtonHTMLAttributes, Children } from "react";

import tw from "utils/tw";

import { Styles } from ".";

export const Blockquote = ({ children }: WithChildren): JSX.Element => (
  <blockquote className={Styles.blockquote}>{children}</blockquote>
);

export const Button = ({
  children,
  ...props
}: WithChildren &
  Pick<ButtonHTMLAttributes<HTMLButtonElement>, "id">): JSX.Element => (
  <button {...props} className={Styles.button}>
    {children}
  </button>
);

export const Code = ({ children, ...props }: WithChildren): JSX.Element => (
  <code className={Styles.code} {...props}>
    {children}
  </code>
);

export const CodeBlock = ({ children }: WithChildren): JSX.Element => (
  <pre className={Styles.codeBlock}>{children}</pre>
);

export const Emphasis = ({ children }: WithChildren): JSX.Element => (
  <em>{children}</em>
);

export const HeaderOne = ({ children }: WithChildren): JSX.Element => (
  <h1 className={tw(Styles.Header, Styles.HeaderOne)}>{children}</h1>
);

export const HeaderFour = ({ children }: WithChildren): JSX.Element => (
  <h3 className={tw(Styles.Header, Styles.HeaderFour)}>{children}</h3>
);

export const HeaderThree = ({ children }: WithChildren): JSX.Element => (
  <h3 className={tw(Styles.Header, Styles.HeaderThree)}>{children}</h3>
);

export const HeaderTwo = ({ children }: WithChildren): JSX.Element => (
  <h2 className={tw(Styles.Header, Styles.HeaderTwo)}>{children}</h2>
);

export const HorizontalRule = (): JSX.Element => (
  <hr className={tw(Styles.horizontalRule)} />
);

export const ListItem = ({ children }: WithChildren): JSX.Element => (
  <li className={Styles.listItem}>{children}</li>
);

export const OrderedList = ({ children }: WithChildren): JSX.Element => (
  <ol className={Styles.orderedList}>{children}</ol>
);

export const Paragraph = ({ children }: WithChildren): JSX.Element => (
  <p
    className={tw(Styles.paragraph, {
      // Add class to let us reduce margin if the only child is a <br />
      br: (() => {
        const nodes = Children.toArray(children);
        const firstNode = nodes[0];
        if (nodes.length !== 1 || typeof firstNode !== "object") return false;
        return "type" in firstNode && firstNode?.type === "br";
      })(),
    })}
  >
    {children}
  </p>
);

export const Strikethrough = ({ children }: WithChildren): JSX.Element => (
  <s>{children}</s>
);

export const Strong = ({ children }: WithChildren): JSX.Element => (
  <strong className={Styles.strong}>{children}</strong>
);

export const Table = ({ children }: WithChildren): JSX.Element => (
  <table className={Styles.table}>{children}</table>
);

export const TableBody = ({ children }: WithChildren): JSX.Element => (
  <tbody className={Styles.tableBody}>{children}</tbody>
);

export const TableCell = ({ children }: WithChildren): JSX.Element => (
  <td className={Styles.tableCell}>{children}</td>
);

export const TableHead = ({ children }: WithChildren): JSX.Element => (
  <thead className={Styles.tableHead}>{children}</thead>
);

export const TableHeader = ({ children }: WithChildren): JSX.Element => (
  <th className={Styles.tableHeader}>{children}</th>
);

export const TableRow = ({ children }: WithChildren): JSX.Element => (
  <tr className={Styles.tableRow}>{children}</tr>
);

export const UnorderedList = ({ children }: WithChildren): JSX.Element => (
  <ul className={Styles.unorderedList}>{children}</ul>
);
