import { User } from "@utility-types";
import Avatar, { AvatarProps } from "components/design-system/Avatar/Avatar";

type Props = Pick<User, "avatarURL" | "name"> & {
  subtitle: string;
  emojiProps?: AvatarProps["emojiProps"];
  isAdmin?: boolean;
  badge?: React.ReactNode;
  roleBadge?: React.ReactNode;
};

export function MembersListItemDetails({
  name,
  subtitle,
  avatarURL,
  emojiProps,
  isAdmin = false,
  badge,
  roleBadge,
}: Props) {
  return (
    <div className="flex items-center gap-8 min-w-0">
      <Avatar
        avatarURL={avatarURL}
        name={name}
        emojiProps={emojiProps}
        background={emojiProps?.emoji ? "transparent" : undefined}
        rounded="rounded-md"
        size="medium"
      />
      <div className="flex flex-col min-w-0">
        <div className="flex flex-row gap-4 items-center">
          <div className="text-subhead-bold text-text-primary">
            {name}
            {isAdmin && " (Admin)"}
          </div>
          {roleBadge}
        </div>
        <div className="flex gap-6 items-center">
          {subtitle && (
            <div className="flex min-w-0 text-footnote text-text-subtle">
              <span className="truncate">{subtitle}</span>
            </div>
          )}
          {badge}
        </div>
      </div>
    </div>
  );
}
