import { StrictMode } from "react";

import AuthProvider from "providers/AuthProvider";
import { LayerProvider } from "providers/LayerProvider";
import { SnackbarProvider } from "providers/SnackbarProvider";
import StreamClientProvider from "providers/StreamClientProvider";
import Compose from "utils/Compose";

import { ErrorBoundary } from "components/helper";

import AppLayoutRoot from "components/App/AppLayoutRoot";
import { Route, Switch } from "react-router-dom";
import FallbackApp from "./FallbackApp";
import { Router } from "./InstrumentedRouter";
import IntegrationCallbackHandler from "./IntegrationCallbackHandler";
import IntegrationRedirectHandler from "./IntegrationRedirectHandler";

export default function App() {
  return (
    <ErrorBoundary message={<FallbackApp />}>
      <Compose
        components={[
          StrictMode,
          Router,
          SnackbarProvider,
          AuthProvider,
          LayerProvider,
          StreamClientProvider,
        ]}
      >
        <Switch>
          <Route path="/:appID(app_\w+)/callback">
            <IntegrationCallbackHandler />
          </Route>
          <Route path="/:appID(app_\w+)/redirect">
            <IntegrationRedirectHandler />
          </Route>
          <Route>
            <AppLayoutRoot />
          </Route>
        </Switch>
      </Compose>
    </ErrorBoundary>
  );
}
