import { variantProps } from "components/helper/classNameVariants";
import { HTMLAttributes } from "react";
import tw from "utils/tw";

export type BadgeProps = HTMLAttributes<HTMLDivElement> & {
  children: string;
  variant: "perspective" | "subtle" | "warning";
};

const getBadgeClassNames = variantProps({
  defaultVariants: {
    badge: "subtle" as const,
    text: "subtle" as const,
  },
  variants: {
    badge: {
      perspective: "bg-background-body",
      subtle: "bg-background-subtle",
      warning: "bg-background-warning",
    },
    text: {
      perspective: "text-text-secondary",
      subtle: "text-text-secondary",
      warning: "text-text-warning",
    },
  },
});

const Badge = ({ children, variant, ...props }: BadgeProps) => {
  const { className } = getBadgeClassNames({ badge: variant, text: variant });

  return (
    <div
      className={tw(
        "flex items-center gap-4 py-2 px-4 rounded-sm overflow-hidden text-ellipsis text-caption-bold select-none",
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Badge;
