import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  JSON: { input: any; output: any };
  Time: { input: any; output: any };
};

export type AiSettings = {
  __typename: "AISettings";
  chatModel: Maybe<Scalars["String"]["output"]>;
};

export type Activity = {
  __typename: "Activity";
  actor: User;
  id: Scalars["ID"]["output"];
  meta: Maybe<ActivityMeta>;
  object: Maybe<ActivityObject>;
  target: Maybe<ActivityTarget>;
  time: Scalars["String"]["output"];
  verb: ActivityVerb;
};

export type ActivityMeta = ActivityReactedToMessageMeta;

export type ActivityObject =
  | GroupPreview
  | JoinApproval
  | Link
  | Message
  | ThreadPreview
  | User;

export type ActivityReactedToMessageMeta = {
  __typename: "ActivityReactedToMessageMeta";
  reaction: Scalars["String"]["output"];
};

export type ActivityTarget = GroupPreview | ThreadPreview | WorkspacePreview;

export enum ActivityVerb {
  AddedGroup = "addedGroup",
  AddedGroupWorkspace = "addedGroupWorkspace",
  AddedThread = "addedThread",
  AddedToGroup = "addedToGroup",
  AddedToThread = "addedToThread",
  CreatedMessageLink = "createdMessageLink",
  ReactedToMessage = "reactedToMessage",
  SentJoinApproval = "sentJoinApproval",
  SentJoinInvitation = "sentJoinInvitation",
  SentJoinRequest = "sentJoinRequest",
}

export enum Addable {
  Anyone = "anyone",
  None = "none",
  Workspace = "workspace",
}

export type Address = Node & {
  __typename: "Address";
  address: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
};

export type AddressConnection = Connection & {
  __typename: "AddressConnection";
  edges: Array<AddressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AddressEdge = Edge & {
  __typename: "AddressEdge";
  cursor: Scalars["String"]["output"];
  node: Address;
};

export type App = Node & {
  __typename: "App";
  avatarURL: Scalars["String"]["output"];
  configureGroup: Maybe<AppConfigureMethod>;
  configureGroupPath: Maybe<Scalars["String"]["output"]>;
  configureThread: Maybe<AppConfigureMethod>;
  configureThreadPath: Maybe<Scalars["String"]["output"]>;
  configureWorkspace: AppConfigureMethod;
  configureWorkspacePath: Maybe<Scalars["String"]["output"]>;
  description: Scalars["String"]["output"];
  docsURL: Scalars["String"]["output"];
  homeURL: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  instructions: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  publishedAt: Maybe<Scalars["String"]["output"]>;
  threadActions: Maybe<Array<ThreadAction>>;
  workspace: WorkspacePreview;
};

export enum AppConfigureMethod {
  Add = "add",
  Configure = "configure",
}

export type AppConnection = Connection & {
  __typename: "AppConnection";
  edges: Array<AppEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AppEdge = Edge & {
  __typename: "AppEdge";
  cursor: Scalars["String"]["output"];
  node: App;
};

export type AppInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  description: Scalars["String"]["input"];
  docsURL?: InputMaybe<Scalars["String"]["input"]>;
  homeURL?: InputMaybe<Scalars["String"]["input"]>;
  instructions?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type AppUnfurlSetup = {
  __typename: "AppUnfurlSetup";
  appUser: User;
  message: Scalars["String"]["output"];
  path: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  workspaceAppID: Scalars["ID"]["output"];
};

export type Attachment = ExternalObject | File | ThreadPreview;

export type AuthorizeWorkspaceAppInput = {
  appID: Scalars["ID"]["input"];
  scopes: Array<Scalars["String"]["input"]>;
  workspaceID: Scalars["ID"]["input"];
};

export type AuthorizeWorkspaceAppResult = {
  __typename: "AuthorizeWorkspaceAppResult";
  authorizationCode: Scalars["String"]["output"];
};

export enum ChatType {
  Group = "group",
  User = "user",
}

export type ChatsFilter = {
  chatType: ChatType;
};

export type CompleteMilestone = {
  milestone: UserMilestone;
};

export type Config = {
  __typename: "Config";
  shareFeedbackURL: Maybe<Scalars["String"]["output"]>;
  streamToken: Scalars["String"]["output"];
};

export type Connection = {
  edges: Array<
    | AddressEdge
    | AppEdge
    | CustomEmojiEdge
    | DeviceEdge
    | DomainEdge
    | DraftEdge
    | FileEdge
    | GroupEdge
    | GroupMemberEdge
    | GroupPreviewEdge
    | JoinApprovalEdge
    | LlmModelEdge
    | LambdaEdge
    | LambdaSecretEdge
    | LinkEdge
    | MessageEdge
    | MessagePinEdge
    | NotificationEdge
    | ReactionEdge
    | ThreadEdge
    | ThreadPreviewEdge
    | UserEdge
    | UserPreviewEdge
    | WorkspaceAppEdge
    | WorkspaceEdge
    | WorkspaceMemberEdge
    | WorkspacePreviewEdge
  >;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CreateAppUnfurlOptOutInput = {
  appID: Scalars["ID"]["input"];
};

export type CreateCustomEmojiInput = {
  fileID: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type CreateExternalObjectInput = {
  url: Scalars["String"]["input"];
};

export type CreateFileInput = {
  uploadTicketID: Scalars["String"]["input"];
};

export type CreateGroupInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  joinableBy?: InputMaybe<JoinableBy>;
  members?: InputMaybe<Array<MemberRoleInput>>;
  name: Scalars["String"]["input"];
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CreateLambdaInput = {
  appID: Scalars["ID"]["input"];
  description: Scalars["String"]["input"];
  event: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
};

export type CreatePersistentChatInput = {
  message: MessageInput;
  recipient: Scalars["String"]["input"];
};

export type CreateWorkspaceInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  domains: Array<Scalars["String"]["input"]>;
  members: Array<MemberRoleInput>;
  name: Scalars["String"]["input"];
};

export type CustomEmoji = Node & {
  __typename: "CustomEmoji";
  createdAt: Scalars["String"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  imageURL: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  workspaceID: Scalars["ID"]["output"];
};

export type CustomEmojiConnection = Connection & {
  __typename: "CustomEmojiConnection";
  edges: Array<CustomEmojiEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CustomEmojiEdge = Edge & {
  __typename: "CustomEmojiEdge";
  cursor: Scalars["String"]["output"];
  node: CustomEmoji;
};

export type CustomEmojiNameSuggestions = {
  __typename: "CustomEmojiNameSuggestions";
  names: Array<Scalars["String"]["output"]>;
};

export type DeleteMeInput = {
  deactivate: Scalars["Boolean"]["input"];
};

export enum DestinationMailbox {
  Inbox = "inbox",
}

export type Device = Node & {
  __typename: "Device";
  id: Scalars["ID"]["output"];
  platform: Scalars["String"]["output"];
};

export type DeviceConnection = Connection & {
  __typename: "DeviceConnection";
  edges: Array<DeviceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeviceEdge = Edge & {
  __typename: "DeviceEdge";
  cursor: Scalars["String"]["output"];
  node: Device;
};

export type Domain = Node & {
  __typename: "Domain";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  workspaces: WorkspaceOrPreviewConnection;
};

export type DomainConnection = Connection & {
  __typename: "DomainConnection";
  edges: Array<DomainEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DomainEdge = Edge &
  Node & {
    __typename: "DomainEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: Domain;
  };

export type Draft = Node & {
  __typename: "Draft";
  id: Scalars["ID"]["output"];
  message: DraftMessage;
  recipients: Array<GroupPreview | User | WorkspacePreview>;
  recipientsAddable: Addable;
  replyToMessage: Maybe<Message>;
  subject: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
};

export type DraftConnection = Connection & {
  __typename: "DraftConnection";
  edges: Array<DraftEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DraftEdge = Edge & {
  __typename: "DraftEdge";
  cursor: Scalars["String"]["output"];
  node: Draft;
};

export type DraftMessage = {
  __typename: "DraftMessage";
  attachments: Array<Attachment>;
  text: Scalars["String"]["output"];
  textPreview: Scalars["String"]["output"];
};

export type DraftSuggestion = {
  __typename: "DraftSuggestion";
  recipients: Array<Scalars["ID"]["output"]>;
  subject: Maybe<Scalars["String"]["output"]>;
};

export type Edge = {
  cursor: Scalars["String"]["output"];
  node:
    | Address
    | App
    | CustomEmoji
    | Device
    | Domain
    | DomainEdge
    | Draft
    | ExternalObject
    | File
    | Group
    | GroupEdge
    | GroupMemberEdge
    | GroupPreview
    | GroupPreviewEdge
    | JoinApproval
    | LlmModel
    | Lambda
    | LambdaSecret
    | Link
    | Message
    | MessageEdge
    | MessageMetadata
    | MessagePin
    | MessagePreview
    | Notification
    | Reaction
    | SearchResult
    | SlackImport
    | Thread
    | ThreadEdge
    | ThreadPreview
    | ThreadPreviewEdge
    | ThreadRecipientEdge
    | ThreadSummaryPoint
    | User
    | UserEdge
    | UserPreviewEdge
    | Workspace
    | WorkspaceAppEdge
    | WorkspaceAppWebhook
    | WorkspaceEdge
    | WorkspaceInviteLink
    | WorkspaceMemberEdge
    | WorkspacePreview
    | WorkspacePreviewEdge
    | WorkspaceSettings
    | WorkspaceSubscription;
};

export type EmojiNameSuggestionInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  workspaceID: Scalars["ID"]["input"];
};

export type EmojiSuggestion = {
  __typename: "EmojiSuggestion";
  emoji: Maybe<Scalars["String"]["output"]>;
};

export type ExternalObject = Node & {
  __typename: "ExternalObject";
  audio: Maybe<Array<ExternalObjectMedia>>;
  description: Maybe<Scalars["String"]["output"]>;
  icon: Maybe<ExternalObjectMedia>;
  id: Scalars["ID"]["output"];
  image: Maybe<Array<ExternalObjectMedia>>;
  layout: ExternalObjectLayout;
  objectType: ExternalObjectType;
  siteName: Scalars["String"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
  video: Maybe<Array<ExternalObjectMedia>>;
};

export enum ExternalObjectLayout {
  Default = "default",
  LargeImage = "largeImage",
}

export type ExternalObjectMedia = {
  __typename: "ExternalObjectMedia";
  alt: Maybe<Scalars["String"]["output"]>;
  blurHash: Maybe<Scalars["String"]["output"]>;
  contentType: Maybe<Scalars["String"]["output"]>;
  height: Maybe<Scalars["Int"]["output"]>;
  url: Scalars["String"]["output"];
  width: Maybe<Scalars["Int"]["output"]>;
};

export enum ExternalObjectType {
  Audio = "audio",
  Image = "image",
  Other = "other",
  Video = "video",
}

export enum FeedType {
  All = "all",
  Groups = "groups",
  Mentions = "mentions",
  Reactions = "reactions",
  Requests = "requests",
}

export type File = Node & {
  __typename: "File";
  contentLength: Scalars["String"]["output"];
  contentMD5: Scalars["String"]["output"];
  contentType: Scalars["String"]["output"];
  fileType: FileType;
  id: Scalars["ID"]["output"];
  metadata: Maybe<FileMetadata>;
  name: Scalars["String"]["output"];
  previewable: Maybe<Scalars["Boolean"]["output"]>;
  url: Scalars["String"]["output"];
};

export type FileConnection = Connection & {
  __typename: "FileConnection";
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FileEdge = Edge & {
  __typename: "FileEdge";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type FileMetadata = {
  __typename: "FileMetadata";
  blurHash: Maybe<Scalars["String"]["output"]>;
  height: Maybe<Scalars["Int"]["output"]>;
  width: Maybe<Scalars["Int"]["output"]>;
};

export type FilePreview = {
  __typename: "FilePreview";
  boxAccessToken: Scalars["String"]["output"];
  boxFileID: Scalars["String"]["output"];
  embedURL: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
};

export enum FileType {
  Audio = "audio",
  File = "file",
  Image = "image",
  Video = "video",
}

export type Filter = {
  match?: InputMaybe<Scalars["String"]["input"]>;
};

export type FormData = {
  __typename: "FormData";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type GenerateEmojiImageInput = {
  prompt: Scalars["String"]["input"];
};

export type Group = Node & {
  __typename: "Group";
  admin: Maybe<User>;
  apps: WorkspaceAppConnection;
  avatarURL: Maybe<Scalars["String"]["output"]>;
  commonWorkspaceIDs: Array<Scalars["ID"]["output"]>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  joinableBy: JoinableBy;
  members: GroupMemberConnection;
  name: Scalars["String"]["output"];
  pendingApprovals: JoinApprovalConnection;
  workspaceID: Maybe<Scalars["ID"]["output"]>;
};

export type GroupMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<GroupMembersOrder>;
};

export type GroupPendingApprovalsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroupConnection = Connection & {
  __typename: "GroupConnection";
  edges: Array<GroupEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GroupEdge = Edge &
  Node & {
    __typename: "GroupEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    lastInteractionAt: Scalars["String"]["output"];
    memberRole: MemberRole;
    node: Group;
    persistentChatEdge: ThreadEdge;
    /** @deprecated Use `memberRole` */
    role: Scalars["String"]["output"];
    threadSubscription: ThreadSubscription;
    unreadThreadCounts: UnreadCounts;
  };

export type GroupMemberConnection = Connection & {
  __typename: "GroupMemberConnection";
  edges: Array<GroupMemberEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GroupMemberEdge = Edge &
  Node & {
    __typename: "GroupMemberEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    memberRole: MemberRole;
    node: User;
    /** @deprecated Use `memberRole` */
    role: Scalars["String"]["output"];
  };

export enum GroupMembersOrder {
  Added = "added",
  Name = "name",
}

export type GroupOrPreview = Group | GroupPreview;

export type GroupOrPreviewConnection = {
  __typename: "GroupOrPreviewConnection";
  edges: Array<GroupOrPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GroupOrPreviewEdge = {
  __typename: "GroupOrPreviewEdge";
  cursor: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  node: GroupOrPreview;
};

export type GroupPreview = Joinable &
  Node &
  Recipient & {
    __typename: "GroupPreview";
    admin: Maybe<User>;
    avatarURL: Maybe<Scalars["String"]["output"]>;
    commonWorkspaceIDs: Array<Scalars["ID"]["output"]>;
    description: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    joinableBy: JoinableBy;
    members: UserPreviewConnection;
    name: Scalars["String"]["output"];
    workspaceID: Maybe<Scalars["ID"]["output"]>;
  };

export type GroupPreviewMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GroupPreviewEdge = Edge &
  Node & {
    __typename: "GroupPreviewEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: GroupPreview;
  };

export type GroupsByWorkspace = {
  __typename: "GroupsByWorkspace";
  groups: GroupConnection;
  id: Scalars["ID"]["output"];
  workspaceEdge: WorkspaceOrPreviewEdge;
};

export type GroupsByWorkspaceGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<GroupsOrder>;
};

export type GroupsByWorkspaceConnection = {
  __typename: "GroupsByWorkspaceConnection";
  edges: Array<GroupsByWorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GroupsByWorkspaceEdge = {
  __typename: "GroupsByWorkspaceEdge";
  cursor: Scalars["String"]["output"];
  node: GroupsByWorkspace;
};

export type GroupsFilter = {
  excludeIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  member?: InputMaybe<Scalars["Boolean"]["input"]>;
  mutualWorkspaceIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  mutualWorkspaces?: InputMaybe<Scalars["Boolean"]["input"]>;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
  unreadThreads?: InputMaybe<Scalars["Boolean"]["input"]>;
  unseenThreads?: InputMaybe<Scalars["Boolean"]["input"]>;
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum GroupsOrder {
  Added = "added",
  Interaction = "interaction",
  Name = "name",
}

export type IWorkspace = {
  avatarURL: Maybe<Scalars["String"]["output"]>;
  domains: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type Invoice = {
  __typename: "Invoice";
  currency: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  periodEnd: Scalars["String"]["output"];
  periodStart: Scalars["String"]["output"];
  status: InvoiceStatus;
  total: Scalars["Int"]["output"];
};

export type InvoiceLineItem = {
  __typename: "InvoiceLineItem";
  amount: Scalars["Int"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  quantity: Scalars["Int"]["output"];
};

export enum InvoiceStatus {
  Draft = "draft",
  Open = "open",
  Paid = "paid",
  Void = "void",
}

export type JoinApproval = Node & {
  __typename: "JoinApproval";
  admin: Maybe<User>;
  adminApprovedAt: Maybe<Scalars["String"]["output"]>;
  approvalStatus: JoinApprovalStatus;
  id: Scalars["ID"]["output"];
  joinable: GroupPreview | ThreadPreview | WorkspacePreview;
  joining: GroupPreview | User | WorkspacePreview;
  joiningApprovedAt: Maybe<Scalars["String"]["output"]>;
  joiningRole: MemberRole;
  requestedAt: Scalars["String"]["output"];
  requester: User;
};

export type JoinApprovalConnection = Connection & {
  __typename: "JoinApprovalConnection";
  edges: Array<JoinApprovalEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JoinApprovalEdge = Edge & {
  __typename: "JoinApprovalEdge";
  cursor: Scalars["String"]["output"];
  node: JoinApproval;
};

export enum JoinApprovalStatus {
  Approved = "approved",
  Invited = "invited",
  Requested = "requested",
  RequestedOther = "requestedOther",
}

export type JoinApprovalsFilter = {
  joinableID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type JoinRequestInput = {
  joinableID: Scalars["ID"]["input"];
  recipient?: InputMaybe<Scalars["String"]["input"]>;
};

export type Joinable = {
  admin: Maybe<User>;
  id: Scalars["ID"]["output"];
  joinableBy: JoinableBy;
  name: Scalars["String"]["output"];
};

export enum JoinableBy {
  Approval = "approval",
  Domain = "domain",
  None = "none",
  Workspace = "workspace",
}

export type LlmModel = Node & {
  __typename: "LLMModel";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  provider: LlmProvider;
  providerLogoURL: Scalars["String"]["output"];
  providerName: Scalars["String"]["output"];
};

export type LlmModelConnection = Connection & {
  __typename: "LLMModelConnection";
  edges: Array<LlmModelEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LlmModelEdge = Edge & {
  __typename: "LLMModelEdge";
  cursor: Scalars["String"]["output"];
  node: LlmModel;
};

export enum LlmProvider {
  Anthropic = "anthropic",
  Google = "google",
  Groq = "groq",
  Openai = "openai",
}

export type Lambda = Node & {
  __typename: "Lambda";
  activeVersion: Scalars["Int"]["output"];
  appId: Scalars["ID"]["output"];
  description: Scalars["String"]["output"];
  enabled: Scalars["Boolean"]["output"];
  event: LambdaEvent;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  source: Scalars["String"]["output"];
  sourceDraft: Scalars["String"]["output"];
  testThread: Maybe<Thread>;
};

export type LambdaConnection = Connection & {
  __typename: "LambdaConnection";
  edges: Array<LambdaEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LambdaEdge = Edge & {
  __typename: "LambdaEdge";
  cursor: Scalars["String"]["output"];
  node: Lambda;
};

export enum LambdaEvent {
  MemberAdded = "member_added",
  Message = "message",
  RecipientAdded = "recipient_added",
}

export type LambdaSecret = Node & {
  __typename: "LambdaSecret";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type LambdaSecretConnection = Connection & {
  __typename: "LambdaSecretConnection";
  edges: Array<LambdaSecretEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LambdaSecretEdge = Edge & {
  __typename: "LambdaSecretEdge";
  cursor: Scalars["String"]["output"];
  node: LambdaSecret;
};

export type Link = Node & {
  __typename: "Link";
  disposition: LinkDisposition;
  id: Scalars["ID"]["output"];
  linked: Linked;
  linkedCategory: LinkedCategory;
  message: MessagePreview;
  thread: ThreadPreview;
};

export type LinkConnection = Connection & {
  __typename: "LinkConnection";
  edges: Array<LinkEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export enum LinkDisposition {
  Attachment = "attachment",
  Inline = "inline",
}

export type LinkEdge = Edge & {
  __typename: "LinkEdge";
  cursor: Scalars["String"]["output"];
  node: Link;
};

export type Linked =
  | ExternalObject
  | File
  | GroupPreview
  | MessagePreview
  | ThreadPreview
  | User;

export enum LinkedCategory {
  File = "file",
  Link = "link",
  Media = "media",
  Other = "other",
}

export type LinksFilter = {
  linkedCategories?: InputMaybe<Array<LinkedCategory>>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  recipientIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type MarkThreadEdgeArchivedInput = {
  isArchived: Scalars["Boolean"]["input"];
  remindAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarkThreadEdgeReadInput = {
  isRead: Scalars["Boolean"]["input"];
  lastReadID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MarkThreadEdgeReminderInput = {
  remindAt?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarkThreadEdgeSeenInput = {
  isSeen: Scalars["Boolean"]["input"];
};

export type MarkThreadEdgeStarredInput = {
  isStarred: Scalars["Boolean"]["input"];
};

export type MarkThreadEdgeSubscribedInput = {
  subscription: ThreadSubscription;
};

export type MasonryRequestInput = {
  data?: InputMaybe<Scalars["JSON"]["input"]>;
  path: Scalars["String"]["input"];
  requestType: Scalars["String"]["input"];
  session?: InputMaybe<Scalars["JSON"]["input"]>;
  surface?: InputMaybe<Scalars["JSON"]["input"]>;
  timestamp: Scalars["String"]["input"];
};

export type MasonryResponse = {
  __typename: "MasonryResponse";
  actions: Maybe<Array<Maybe<Scalars["JSON"]["output"]>>>;
  context: Maybe<Scalars["JSON"]["output"]>;
  data: Maybe<Scalars["JSON"]["output"]>;
  responseType: Scalars["String"]["output"];
};

export type MasonrySessionOriginationInput = {
  appID?: InputMaybe<Scalars["ID"]["input"]>;
  groupID?: InputMaybe<Scalars["ID"]["input"]>;
  messageID?: InputMaybe<Scalars["ID"]["input"]>;
  messageSenderID?: InputMaybe<Scalars["ID"]["input"]>;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
  type: Scalars["String"]["input"];
  unfurlMessageID?: InputMaybe<Scalars["String"]["input"]>;
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasonrySessionResponse = {
  __typename: "MasonrySessionResponse";
  context: Scalars["JSON"]["output"];
  origination: Scalars["JSON"]["output"];
};

export enum MemberRole {
  Admin = "admin",
  Default = "default",
}

export type MemberRoleInput = {
  member: Scalars["String"]["input"];
  memberRole?: InputMaybe<MemberRole>;
  role: Scalars["String"]["input"];
};

export type Message = Node & {
  __typename: "Message";
  attachments: Array<Attachment>;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  ownReactions: Array<Reaction>;
  quotedMessage: Maybe<QuotedMessage>;
  reactionCounts: Array<ReactionCount>;
  reactions: ReactionConnection;
  replyThreadID: Maybe<Scalars["ID"]["output"]>;
  streamID: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  textPreview: Scalars["String"]["output"];
  threadID: Scalars["ID"]["output"];
  updatedAt: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type MessageReactionsArgs = {
  before?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageAiResponseInfo = {
  __typename: "MessageAIResponseInfo";
  model: LlmModel;
  sourcesDescription: Scalars["String"]["output"];
  toUserID: Scalars["ID"]["output"];
};

export type MessageConnection = Connection & {
  __typename: "MessageConnection";
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  replyCount: Scalars["Int"]["output"];
  totalCount: Scalars["Int"]["output"];
  unreadCounts: UnreadCounts;
};

export type MessageEdge = Edge &
  Node & {
    __typename: "MessageEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: Message;
  };

export type MessageInput = {
  attachments: Array<Scalars["ID"]["input"]>;
  externalID?: InputMaybe<Scalars["String"]["input"]>;
  quotedMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  text: Scalars["String"]["input"];
};

export type MessageMetadata = Node & {
  __typename: "MessageMetadata";
  aiResponseInfo: Maybe<MessageAiResponseInfo>;
  id: Scalars["ID"]["output"];
  messageID: Scalars["ID"]["output"];
};

export type MessagePin = Node & {
  __typename: "MessagePin";
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  message: Message;
  updatedAt: Scalars["String"]["output"];
  user: User;
};

export type MessagePinConnection = Connection & {
  __typename: "MessagePinConnection";
  edges: Array<MessagePinEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessagePinEdge = Edge & {
  __typename: "MessagePinEdge";
  cursor: Scalars["String"]["output"];
  node: MessagePin;
};

export type MessagePinInput = {
  messageID: Scalars["ID"]["input"];
};

export type MessagePinsFilter = {
  threadID: Scalars["ID"]["input"];
};

export type MessagePreview = Node & {
  __typename: "MessagePreview";
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  threadID: Scalars["ID"]["output"];
  user: User;
};

export type Mutation = {
  __typename: "Mutation";
  addGroupMembers: Group;
  addThreadRecipients: Thread;
  addWorkspaceApp: WorkspaceAppEdge;
  addWorkspaceMembers: Workspace;
  approveJoinApproval: JoinApproval;
  authorizeWorkspaceApp: Maybe<AuthorizeWorkspaceAppResult>;
  cancelJoinApproval: Scalars["Boolean"]["output"];
  cancelWorkspaceSubscription: WorkspaceSubscription;
  completeMilestone: Scalars["Boolean"]["output"];
  connectWithUsers: UserConnection;
  createApp: App;
  createAppUnfurlOptOut: Maybe<Scalars["Boolean"]["output"]>;
  createCustomEmoji: CustomEmoji;
  createExternalObject: ExternalObject;
  createFile: File;
  createGroup: Group;
  createLLMAPIKey: WorkspaceLlmapiKey;
  createLambda: Lambda;
  createLambdaSecret: LambdaSecret;
  createPersistentChat: Thread;
  createSlackImport: SlackImport;
  createWorkspace: Workspace;
  createWorkspaceApp: WorkspaceAppEdge;
  deleteApp: Scalars["Boolean"]["output"];
  deleteCustomEmoji: Scalars["Boolean"]["output"];
  deleteDraft: Scalars["Boolean"]["output"];
  deleteFile: Scalars["Boolean"]["output"];
  deleteGroup: Scalars["Boolean"]["output"];
  deleteLLMAPIKey: Scalars["Boolean"]["output"];
  deleteLambda: Scalars["Boolean"]["output"];
  deleteLambdaSecret: Scalars["Boolean"]["output"];
  deleteMe: Scalars["Boolean"]["output"];
  deleteMessage: Scalars["Boolean"]["output"];
  deleteSlackImport: Scalars["Boolean"]["output"];
  deleteThread: Scalars["Boolean"]["output"];
  deleteWorkspace: Scalars["Boolean"]["output"];
  enableLambda: Lambda;
  generateEmojiImage: File;
  ignoreJoinApproval: Scalars["Boolean"]["output"];
  joinGroup: Group;
  joinThreadEdgeChannel: Scalars["Boolean"]["output"];
  joinWorkspace: Workspace;
  leaveGroup: Scalars["Boolean"]["output"];
  leaveThread: Scalars["Boolean"]["output"];
  leaveWorkspace: Scalars["Boolean"]["output"];
  loadSlackImport: SlackImport;
  markAllThreadEdges: Scalars["Int"]["output"];
  markNotificationEdgeRead: NotificationEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeArchived: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeRead: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeStarred: ThreadEdge;
  /** @deprecated Use `markThreadEdges` */
  markThreadEdgeSubscribed: ThreadEdge;
  markThreadEdges: Array<ThreadEdge>;
  masonryRequest: MasonryResponse;
  masonrySession: MasonrySessionResponse;
  performThreadAction: ThreadActionResult;
  pinMessage: MessagePin;
  registerDevice: Device;
  remindWorkspaceMembers: Scalars["Boolean"]["output"];
  removeGroupMembers: Group;
  removeThreadRecipients: Thread;
  removeWorkspaceApp: Scalars["Boolean"]["output"];
  removeWorkspaceMembers: Workspace;
  resetWorkspaceInviteLink: Scalars["Boolean"]["output"];
  saveDraft: Draft;
  sendDraft: Thread;
  sendJoinRequest: JoinApproval;
  sendMessage: SendMessageOutput;
  startSlackImport: SlackImport;
  syncAppObjects: Scalars["Boolean"]["output"];
  unfurlURL: UnfurlUrlResult;
  unpinMessage: Scalars["Boolean"]["output"];
  updateApp: App;
  updateGroup: Group;
  updateGroupEdge: GroupEdge;
  updateJoinApproval: JoinApproval;
  updateLambda: Lambda;
  updateLambdaSecret: Scalars["Boolean"]["output"];
  updateMe: User;
  updateMessage: Message;
  updateSettings: UserSettings;
  updateSlackImport: SlackImport;
  updateThread: Thread;
  updateThreadMetadata: ThreadMetadata;
  updateUserEdge: UserEdge;
  updateWorkspace: Workspace;
  updateWorkspaceInviteLink: WorkspaceInviteLink;
  updateWorkspaceSettings: WorkspaceSettings;
  updateWorkspaceSubscription: WorkspaceSubscription;
  uploadTicket: UploadTicketResponse;
};

export type MutationAddGroupMembersArgs = {
  id: Scalars["ID"]["input"];
  members: Array<MemberRoleInput>;
};

export type MutationAddThreadRecipientsArgs = {
  id: Scalars["ID"]["input"];
  recipients: Array<RecipientRoleInput>;
};

export type MutationAddWorkspaceAppArgs = {
  input: WorkspaceAppInput;
};

export type MutationAddWorkspaceMembersArgs = {
  id: Scalars["ID"]["input"];
  members: Array<MemberRoleInput>;
};

export type MutationApproveJoinApprovalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationAuthorizeWorkspaceAppArgs = {
  input: AuthorizeWorkspaceAppInput;
};

export type MutationCancelJoinApprovalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationCancelWorkspaceSubscriptionArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type MutationCompleteMilestoneArgs = {
  input: CompleteMilestone;
};

export type MutationConnectWithUsersArgs = {
  users: Array<Scalars["String"]["input"]>;
};

export type MutationCreateAppArgs = {
  input: AppInput;
};

export type MutationCreateAppUnfurlOptOutArgs = {
  input: CreateAppUnfurlOptOutInput;
};

export type MutationCreateCustomEmojiArgs = {
  input: CreateCustomEmojiInput;
};

export type MutationCreateExternalObjectArgs = {
  input?: InputMaybe<CreateExternalObjectInput>;
};

export type MutationCreateFileArgs = {
  input?: InputMaybe<CreateFileInput>;
  uploadTicketID?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};

export type MutationCreateLlmapiKeyArgs = {
  input: WorkspaceLlmapiKeyInput;
};

export type MutationCreateLambdaArgs = {
  input: CreateLambdaInput;
};

export type MutationCreateLambdaSecretArgs = {
  lambdaID: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationCreatePersistentChatArgs = {
  input: CreatePersistentChatInput;
};

export type MutationCreateSlackImportArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type MutationCreateWorkspaceArgs = {
  input: CreateWorkspaceInput;
};

export type MutationCreateWorkspaceAppArgs = {
  input: AppInput;
};

export type MutationDeleteAppArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCustomEmojiArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDraftArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteFileArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLlmapiKeyArgs = {
  id: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type MutationDeleteLambdaArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLambdaSecretArgs = {
  secretID: Scalars["ID"]["input"];
};

export type MutationDeleteMeArgs = {
  input: DeleteMeInput;
};

export type MutationDeleteMessageArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteSlackImportArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type MutationDeleteThreadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteWorkspaceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationEnableLambdaArgs = {
  enabled: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationGenerateEmojiImageArgs = {
  input: GenerateEmojiImageInput;
};

export type MutationIgnoreJoinApprovalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationJoinGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationJoinThreadEdgeChannelArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationJoinWorkspaceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLeaveGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLeaveThreadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLeaveWorkspaceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLoadSlackImportArgs = {
  fileID: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type MutationMarkAllThreadEdgesArgs = {
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  filter?: InputMaybe<ThreadsFilter>;
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
};

export type MutationMarkNotificationEdgeReadArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationMarkThreadEdgeArchivedArgs = {
  id: Scalars["ID"]["input"];
  input: MarkThreadEdgeArchivedInput;
};

export type MutationMarkThreadEdgeReadArgs = {
  id: Scalars["ID"]["input"];
  input: MarkThreadEdgeReadInput;
};

export type MutationMarkThreadEdgeStarredArgs = {
  id: Scalars["ID"]["input"];
  input: MarkThreadEdgeStarredInput;
};

export type MutationMarkThreadEdgeSubscribedArgs = {
  id: Scalars["ID"]["input"];
  input: MarkThreadEdgeSubscribedInput;
};

export type MutationMarkThreadEdgesArgs = {
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  ids: Array<Scalars["ID"]["input"]>;
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
};

export type MutationMasonryRequestArgs = {
  input: MasonryRequestInput;
};

export type MutationMasonrySessionArgs = {
  input: MasonrySessionOriginationInput;
};

export type MutationPerformThreadActionArgs = {
  input: ThreadActionInput;
};

export type MutationPinMessageArgs = {
  input: MessagePinInput;
};

export type MutationRegisterDeviceArgs = {
  input: RegisterDeviceInput;
};

export type MutationRemindWorkspaceMembersArgs = {
  id: Scalars["ID"]["input"];
  input: RemindWorkspaceMembersInput;
};

export type MutationRemoveGroupMembersArgs = {
  id: Scalars["ID"]["input"];
  memberIDs: Array<Scalars["ID"]["input"]>;
};

export type MutationRemoveThreadRecipientsArgs = {
  id: Scalars["ID"]["input"];
  recipientIDs: Array<Scalars["ID"]["input"]>;
};

export type MutationRemoveWorkspaceAppArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationRemoveWorkspaceMembersArgs = {
  id: Scalars["ID"]["input"];
  memberIDs: Array<Scalars["ID"]["input"]>;
};

export type MutationResetWorkspaceInviteLinkArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type MutationSaveDraftArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input: SaveDraftInput;
};

export type MutationSendDraftArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input: SaveDraftInput;
};

export type MutationSendJoinRequestArgs = {
  input: JoinRequestInput;
};

export type MutationSendMessageArgs = {
  input: SendMessageInput;
};

export type MutationStartSlackImportArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type MutationSyncAppObjectsArgs = {
  input: SyncAppObjectsInput;
};

export type MutationUnfurlUrlArgs = {
  input?: InputMaybe<UnfurlUrlInput>;
};

export type MutationUnpinMessageArgs = {
  input: MessagePinInput;
};

export type MutationUpdateAppArgs = {
  id: Scalars["ID"]["input"];
  input: AppInput;
};

export type MutationUpdateGroupArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateGroupInput;
};

export type MutationUpdateGroupEdgeArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateGroupEdgeInput;
};

export type MutationUpdateJoinApprovalArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateJoinApprovalInput;
};

export type MutationUpdateLambdaArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateLambdaInput;
};

export type MutationUpdateLambdaSecretArgs = {
  secretID: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
};

export type MutationUpdateMeArgs = {
  input: UpdateMeInput;
};

export type MutationUpdateMessageArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateMessageInput;
};

export type MutationUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

export type MutationUpdateSlackImportArgs = {
  config?: InputMaybe<SlackImportConfigInput>;
  workspaceID: Scalars["ID"]["input"];
};

export type MutationUpdateThreadArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateThreadInput;
};

export type MutationUpdateThreadMetadataArgs = {
  input: UpdateThreadMetadataInput;
};

export type MutationUpdateUserEdgeArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateUserEdgeInput;
};

export type MutationUpdateWorkspaceArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateWorkspaceInput;
};

export type MutationUpdateWorkspaceInviteLinkArgs = {
  input: UpdateWorkspaceInviteLinkInput;
};

export type MutationUpdateWorkspaceSettingsArgs = {
  input: UpdateWorkspaceSettingsInput;
};

export type MutationUpdateWorkspaceSubscriptionArgs = {
  input: WorkspaceSubscriptionInput;
  workspaceID: Scalars["ID"]["input"];
};

export type MutationUploadTicketArgs = {
  input: UploadTicketInput;
};

export type Node = {
  id: Scalars["ID"]["output"];
};

export type Notification = Node & {
  __typename: "Notification";
  activities: Array<Activity>;
  activityCount: Scalars["Int"]["output"];
  actorCount: Scalars["Int"]["output"];
  id: Scalars["ID"]["output"];
  text: Scalars["String"]["output"];
  verb: ActivityVerb;
};

export type NotificationConnection = Connection & {
  __typename: "NotificationConnection";
  edges: Array<NotificationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
  unreadCount: Scalars["Int"]["output"];
  unseenCount: Scalars["Int"]["output"];
};

export type NotificationEdge = Edge & {
  __typename: "NotificationEdge";
  cursor: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isRead: Scalars["Boolean"]["output"];
  isSeen: Scalars["Boolean"]["output"];
  node: Notification;
};

export enum NotificationPermissionStatus {
  Denied = "denied",
  Granted = "granted",
  Prompt = "prompt",
}

export type NotificationsFilter = {
  feedType: FeedType;
};

export enum NotifyMessagesSetting {
  Direct = "direct",
  Inbox = "inbox",
  None = "none",
}

export type PageInfo = {
  __typename: "PageInfo";
  endCursor: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor: Maybe<Scalars["String"]["output"]>;
};

export type Query = {
  __typename: "Query";
  addresses: AddressConnection;
  apps: AppConnection;
  config: Config;
  customEmojis: CustomEmojiConnection;
  devices: DeviceConnection;
  domains: DomainConnection;
  draftSuggestion: DraftSuggestion;
  drafts: DraftConnection;
  emojiNameSuggestion: CustomEmojiNameSuggestions;
  emojiSuggestion: EmojiSuggestion;
  filePreview: FilePreview;
  groups: GroupConnection;
  groupsByWorkspace: GroupsByWorkspaceConnection;
  joinApprovals: JoinApprovalConnection;
  joinRequest: Maybe<JoinApproval>;
  joinRequests: JoinApprovalConnection;
  lambdaSecrets: LambdaSecretConnection;
  lambdas: LambdaConnection;
  links: LinkConnection;
  llmModels: LlmModelConnection;
  localGroups: GroupOrPreviewConnection;
  localThreads: ThreadConnection;
  localUsers: UserConnection;
  localWorkspaces: WorkspaceConnection;
  me: User;
  messageMetadata: Maybe<MessageMetadata>;
  messagePins: MessagePinConnection;
  node: Maybe<
    | Address
    | App
    | CustomEmoji
    | Device
    | Domain
    | DomainEdge
    | Draft
    | ExternalObject
    | File
    | Group
    | GroupEdge
    | GroupMemberEdge
    | GroupPreview
    | GroupPreviewEdge
    | JoinApproval
    | LlmModel
    | Lambda
    | LambdaSecret
    | Link
    | Message
    | MessageEdge
    | MessageMetadata
    | MessagePin
    | MessagePreview
    | Notification
    | Reaction
    | SearchResult
    | SlackImport
    | Thread
    | ThreadEdge
    | ThreadPreview
    | ThreadPreviewEdge
    | ThreadRecipientEdge
    | ThreadSummaryPoint
    | User
    | UserEdge
    | UserPreviewEdge
    | Workspace
    | WorkspaceAppEdge
    | WorkspaceAppWebhook
    | WorkspaceEdge
    | WorkspaceInviteLink
    | WorkspaceMemberEdge
    | WorkspacePreview
    | WorkspacePreviewEdge
    | WorkspaceSettings
    | WorkspaceSubscription
  >;
  notifications: NotificationConnection;
  persistentChats: ThreadConnection;
  searchMessages: SearchResultConnection;
  settings: UserSettings;
  slackImport: Maybe<SlackImport>;
  threadMetadata: ThreadMetadata;
  threadSummaryPoints: ThreadSummaryPointConnection;
  threads: ThreadConnection;
  users: UserConnection;
  workspaceAppConfig: WorkspaceAppConfig;
  workspaceInviteLink: WorkspaceInviteLink;
  workspaceInviteLinkSigned: Scalars["String"]["output"];
  workspaceSettings: WorkspaceSettings;
  workspaceSubscription: WorkspaceSubscription;
  workspaceSubscriptionBilling: SubscriptionBillingSession;
  workspaceSubscriptionCheckout: SubscriptionCheckoutSession;
  workspaceSubscriptionPlans: Array<SubscriptionPlan>;
  workspaces: WorkspaceConnection;
};

export type QueryAppsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryCustomEmojisArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryDraftSuggestionArgs = {
  input?: InputMaybe<SendMessageInput>;
  replyToMessageID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type QueryDraftsArgs = {
  before?: InputMaybe<Scalars["String"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryEmojiNameSuggestionArgs = {
  input: EmojiNameSuggestionInput;
};

export type QueryEmojiSuggestionArgs = {
  name: Scalars["String"]["input"];
};

export type QueryFilePreviewArgs = {
  fileID: Scalars["ID"]["input"];
};

export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<GroupsOrder>;
};

export type QueryJoinApprovalsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<JoinApprovalsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryJoinRequestArgs = {
  joinableID: Scalars["ID"]["input"];
};

export type QueryJoinRequestsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<JoinApprovalsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLambdaSecretsArgs = {
  lambdaId: Scalars["ID"]["input"];
};

export type QueryLambdasArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  appId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLinksArgs = {
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<LinksFilter>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLlmModelsArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryLocalGroupsArgs = {
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLocalThreadsArgs = {
  filter?: InputMaybe<ThreadsFilter>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLocalUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryLocalWorkspacesArgs = {
  filter?: InputMaybe<WorkspacesFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryMessageMetadataArgs = {
  messageID: Scalars["ID"]["input"];
};

export type QueryMessagePinsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter: MessagePinsFilter;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationsFilter>;
  markEdgesSeen?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type QueryPersistentChatsArgs = {
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter: ChatsFilter;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<ThreadsOrder>;
};

export type QuerySearchMessagesArgs = {
  input: SearchInput;
};

export type QuerySlackImportArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryThreadMetadataArgs = {
  threadID: Scalars["ID"]["input"];
};

export type QueryThreadSummaryPointsArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  filter: ThreadSummaryFilter;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryThreadsArgs = {
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<ThreadsFilter>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<ThreadsOrder>;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<UsersOrder>;
};

export type QueryWorkspaceAppConfigArgs = {
  appID: Scalars["ID"]["input"];
  configurableID: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceInviteLinkArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceInviteLinkSignedArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceSettingsArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceSubscriptionArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceSubscriptionBillingArgs = {
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspaceSubscriptionCheckoutArgs = {
  input: WorkspaceSubscriptionInput;
  workspaceID: Scalars["ID"]["input"];
};

export type QueryWorkspacesArgs = {
  filter?: InputMaybe<WorkspacesFilter>;
};

export type QuotedMessage = {
  __typename: "QuotedMessage";
  attachments: Array<Attachment>;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  streamID: Maybe<Scalars["String"]["output"]>;
  text: Scalars["String"]["output"];
  textPreview: Scalars["String"]["output"];
  updatedAt: Maybe<Scalars["String"]["output"]>;
  user: User;
};

export type Reaction = Node & {
  __typename: "Reaction";
  id: Scalars["ID"]["output"];
  type: Scalars["String"]["output"];
  user: User;
};

export type ReactionConnection = Connection & {
  __typename: "ReactionConnection";
  edges: Array<ReactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ReactionCount = {
  __typename: "ReactionCount";
  c: Scalars["Int"]["output"];
  r: Scalars["String"]["output"];
};

export type ReactionEdge = Edge & {
  __typename: "ReactionEdge";
  cursor: Scalars["String"]["output"];
  node: Reaction;
};

export type Recipient = {
  avatarURL: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type RecipientConnection = {
  __typename: "RecipientConnection";
  edges: Array<RecipientEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RecipientEdge = {
  __typename: "RecipientEdge";
  cursor: Scalars["String"]["output"];
  node: GroupPreview | User | WorkspacePreview;
};

export type RecipientRoleInput = {
  recipient: Scalars["String"]["input"];
  recipientRole?: InputMaybe<MemberRole>;
  role: Scalars["String"]["input"];
};

export type RegisterDeviceInput = {
  appBuild: Scalars["String"]["input"];
  appVersion: Scalars["String"]["input"];
  fcmToken?: InputMaybe<Scalars["String"]["input"]>;
  manufacturer: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  permissionStatus?: InputMaybe<NotificationPermissionStatus>;
  platform: Scalars["String"]["input"];
  pushToken?: InputMaybe<Scalars["String"]["input"]>;
  uuid: Scalars["String"]["input"];
};

export type RemindWorkspaceMembersInput = {
  allInvited?: InputMaybe<Scalars["Boolean"]["input"]>;
  allPending?: InputMaybe<Scalars["Boolean"]["input"]>;
  memberIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export type SaveDraftInput = {
  mailboxes?: InputMaybe<Array<DestinationMailbox>>;
  message: MessageInput;
  recipients: Array<Scalars["String"]["input"]>;
  recipientsAddable?: InputMaybe<Addable>;
  replyToMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type SearchInput = {
  boostExponentialDecay?: InputMaybe<Scalars["Float"]["input"]>;
  boostWeight?: InputMaybe<Scalars["Float"]["input"]>;
  knnBoost?: InputMaybe<Scalars["Float"]["input"]>;
  knnCandidatesMultiplier?: InputMaybe<Scalars["Float"]["input"]>;
  knnNeighborsMultiplier?: InputMaybe<Scalars["Float"]["input"]>;
  knnSimilarity?: InputMaybe<Scalars["Float"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  match: Scalars["String"]["input"];
  minKWResults?: InputMaybe<Scalars["Int"]["input"]>;
  searchFuzziness?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SearchItem = Message | ThreadPreview;

export type SearchResult = Node & {
  __typename: "SearchResult";
  id: Scalars["ID"]["output"];
  item: SearchItem;
  score: Scalars["Float"]["output"];
  type: SearchResultType;
};

export type SearchResultConnection = {
  __typename: "SearchResultConnection";
  edges: Array<SearchResultEdge>;
};

export type SearchResultEdge = {
  __typename: "SearchResultEdge";
  node: SearchResult;
};

export enum SearchResultType {
  Keyword = "keyword",
  Semantic = "semantic",
}

export type SendMessageInput = {
  message: MessageInput;
  thread?: InputMaybe<ThreadInput>;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SendMessageOutput = {
  __typename: "SendMessageOutput";
  message: Maybe<Message>;
  thread: Thread;
};

export type SlackImport = Node & {
  __typename: "SlackImport";
  config: Maybe<SlackImportConfig>;
  id: Scalars["ID"]["output"];
  notificationsThreadID: Maybe<Scalars["ID"]["output"]>;
  progress: Maybe<SlackImportProgress>;
  status: SlackImportStatus;
};

export type SlackImportConfig = {
  __typename: "SlackImportConfig";
  groups: Array<Scalars["String"]["output"]>;
  includeChannels: Array<Scalars["String"]["output"]>;
  skipChannels: Array<Scalars["String"]["output"]>;
};

export type SlackImportConfigInput = {
  groups: Array<Scalars["String"]["input"]>;
  includeChannels: Array<Scalars["String"]["input"]>;
  skipChannels: Array<Scalars["String"]["input"]>;
};

export type SlackImportProgress = {
  __typename: "SlackImportProgress";
  channels: SlackImportProgressItem;
  messages: SlackImportProgressItem;
  users: SlackImportProgressItem;
};

export type SlackImportProgressItem = {
  __typename: "SlackImportProgressItem";
  imported: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
};

export enum SlackImportStatus {
  Completed = "completed",
  Empty = "empty",
  Error = "error",
  Invalid = "invalid",
  Loading = "loading",
  Ready = "ready",
  Running = "running",
}

export type SubscriptionBillingSession = {
  __typename: "SubscriptionBillingSession";
  stripePortalURL: Scalars["String"]["output"];
};

export type SubscriptionCheckoutSession = {
  __typename: "SubscriptionCheckoutSession";
  stripeClientSecret: Scalars["String"]["output"];
};

export enum SubscriptionInterval {
  Month = "month",
  Year = "year",
}

export type SubscriptionPlan = {
  __typename: "SubscriptionPlan";
  amount: Scalars["Int"]["output"];
  currency: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  interval: SubscriptionInterval;
  name: Scalars["String"]["output"];
};

export enum SubscriptionStatus {
  Active = "active",
  Canceled = "canceled",
  PastDue = "past_due",
  Trialing = "trialing",
  Unpaid = "unpaid",
}

export type SyncAppObjectInput = {
  externalID: Scalars["String"]["input"];
  path: Scalars["String"]["input"];
  remove?: InputMaybe<Scalars["Boolean"]["input"]>;
  url: Scalars["String"]["input"];
  userID: Scalars["ID"]["input"];
};

export type SyncAppObjectsInput = {
  appObjects: Array<SyncAppObjectInput>;
};

export type Thread = Node & {
  __typename: "Thread";
  apps: WorkspaceAppConnection;
  createdAt: Scalars["String"]["output"];
  firstMessage: Maybe<Message>;
  id: Scalars["ID"]["output"];
  isPersistentChat: Scalars["Boolean"]["output"];
  lastMessage: Maybe<Message>;
  messages: MessageConnection;
  recentMessagesUsers: Array<User>;
  recipients: ThreadRecipientConnection;
  recipientsAddable: Addable;
  replyToMessage: Maybe<Message>;
  subject: Scalars["String"]["output"];
  users: UserConnection;
  workspaceID: Maybe<Scalars["ID"]["output"]>;
};

export type ThreadMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  aroundLastRead?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreadRecipientsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreadUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreadAction = {
  __typename: "ThreadAction";
  description: Scalars["String"]["output"];
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  path: Maybe<Scalars["String"]["output"]>;
};

export type ThreadActionInput = {
  actionName: Scalars["String"]["input"];
  appID: Scalars["ID"]["input"];
  threadID: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type ThreadActionResult = {
  __typename: "ThreadActionResult";
  success: Scalars["Boolean"]["output"];
};

export type ThreadConnection = Connection & {
  __typename: "ThreadConnection";
  edges: Array<ThreadEdge>;
  matchedMessages: MessageConnection;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
  unreadCounts: UnreadCounts;
};

export type ThreadEdge = Edge &
  Node & {
    __typename: "ThreadEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    isArchived: Scalars["Boolean"]["output"];
    isMentioned: Scalars["Boolean"]["output"];
    isRead: Scalars["Boolean"]["output"];
    isSeen: Scalars["Boolean"]["output"];
    isStarred: Scalars["Boolean"]["output"];
    lastReadID: Maybe<Scalars["ID"]["output"]>;
    lastSeenID: Maybe<Scalars["ID"]["output"]>;
    node: Thread;
    recipientRole: MemberRole;
    remindAt: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use `recipientRole` */
    role: Scalars["String"]["output"];
    subscription: ThreadSubscription;
    unreadMessageCounts: UnreadCounts;
  };

export type ThreadInput = {
  externalID?: InputMaybe<Scalars["String"]["input"]>;
  mailboxes?: InputMaybe<Array<DestinationMailbox>>;
  recipients: Array<Scalars["String"]["input"]>;
  recipientsAddable?: InputMaybe<Addable>;
  replyToMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type ThreadMetadata = {
  __typename: "ThreadMetadata";
  aiSettings: Maybe<AiSettings>;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  threadID: Scalars["ID"]["output"];
  updatedAt: Scalars["String"]["output"];
};

export type ThreadMetadataAiSettingsInput = {
  chatModel?: InputMaybe<Scalars["String"]["input"]>;
};

export type ThreadPreview = Joinable &
  Node & {
    __typename: "ThreadPreview";
    admin: Maybe<User>;
    id: Scalars["ID"]["output"];
    joinableBy: JoinableBy;
    name: Scalars["String"]["output"];
    recipients: RecipientConnection;
    subject: Scalars["String"]["output"];
  };

export type ThreadPreviewRecipientsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreadPreviewEdge = Edge &
  Node & {
    __typename: "ThreadPreviewEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: ThreadPreview;
  };

export type ThreadRecipientConnection = {
  __typename: "ThreadRecipientConnection";
  edges: Array<ThreadRecipientEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ThreadRecipientEdge = Node & {
  __typename: "ThreadRecipientEdge";
  cursor: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  node: GroupPreview | User | WorkspacePreview;
  recipientRole: MemberRole;
  /** @deprecated Use `recipientRole` */
  role: Scalars["String"]["output"];
};

export enum ThreadSubscription {
  Activity = "activity",
  Archive = "archive",
  Inbox = "inbox",
}

export type ThreadSummaryFilter = {
  afterMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  level: Scalars["Int"]["input"];
  threadID: Scalars["ID"]["input"];
};

export type ThreadSummaryPoint = Node & {
  __typename: "ThreadSummaryPoint";
  id: Scalars["ID"]["output"];
  level: Scalars["Int"]["output"];
  messages: MessageConnection;
  point: Scalars["String"]["output"];
  threadID: Scalars["ID"]["output"];
};

export type ThreadSummaryPointMessagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ThreadSummaryPointConnection = {
  __typename: "ThreadSummaryPointConnection";
  edges: Array<ThreadSummaryPointEdge>;
  pageInfo: PageInfo;
};

export type ThreadSummaryPointEdge = {
  __typename: "ThreadSummaryPointEdge";
  cursor: Scalars["String"]["output"];
  node: ThreadSummaryPoint;
};

export type ThreadsFilter = {
  excludeIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  excludePersistentChats?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeStarred?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastMessageSinceID?: InputMaybe<Scalars["ID"]["input"]>;
  mailbox?: InputMaybe<ThreadsMailbox>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  matchMessages?: InputMaybe<Scalars["String"]["input"]>;
  recipientID?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ThreadsMailbox {
  Ai = "ai",
  All = "all",
  Archived = "archived",
  Inbox = "inbox",
  Mentioned = "mentioned",
  Sent = "sent",
  Starred = "starred",
  Unread = "unread",
  Unseen = "unseen",
}

export enum ThreadsOrder {
  Created = "created",
  LastMessage = "lastMessage",
  Unread = "unread",
  Unseen = "unseen",
}

export type UnfurlUrlInput = {
  url: Scalars["String"]["input"];
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UnfurlUrlResult = {
  __typename: "UnfurlURLResult";
  appUnfurlSetup: Maybe<AppUnfurlSetup>;
  externalObject: ExternalObject;
};

export type UnreadCounts = {
  __typename: "UnreadCounts";
  mentioned: Scalars["Int"]["output"];
  total: Scalars["Int"]["output"];
  unseen: Scalars["Int"]["output"];
};

export type UnsyncAppObjectsInput = {
  appObjects: Array<Scalars["String"]["input"]>;
};

export type UpdateGroupEdgeInput = {
  threadSubscription: ThreadSubscription;
};

export type UpdateGroupInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  joinableBy?: InputMaybe<JoinableBy>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  workspaceID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateJoinApprovalInput = {
  role: MemberRole;
};

export type UpdateLambdaInput = {
  description: Scalars["String"]["input"];
  event: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  source?: InputMaybe<Scalars["String"]["input"]>;
  sourceDraft?: InputMaybe<Scalars["String"]["input"]>;
  testThreadID?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateMeInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  bio?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateMessageInput = {
  attachments: Array<Scalars["ID"]["input"]>;
  text: Scalars["String"]["input"];
};

export type UpdateSettingsInput = {
  autoArchive?: InputMaybe<Scalars["Boolean"]["input"]>;
  llmSettings?: InputMaybe<UserLlmSettingsInput>;
  notifyMessages?: InputMaybe<NotifyMessagesSetting>;
};

export type UpdateThreadInput = {
  recipients?: InputMaybe<Array<Scalars["String"]["input"]>>;
  recipientsAddable?: InputMaybe<Addable>;
  subject?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateThreadMetadataInput = {
  aiSettings?: InputMaybe<ThreadMetadataAiSettingsInput>;
  threadID: Scalars["ID"]["input"];
};

export type UpdateUserEdgeInput = {
  status: UserEdgeStatus;
};

export type UpdateWorkspaceInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  domains?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateWorkspaceInviteLinkInput = {
  enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  expiresInDays?: InputMaybe<Scalars["Int"]["input"]>;
  maxCount?: InputMaybe<Scalars["Int"]["input"]>;
  workspaceID: Scalars["ID"]["input"];
};

export type UpdateWorkspaceSettingsInput = {
  llmSettings: WorkspaceLlmSettingsInput;
  workspaceID: Scalars["ID"]["input"];
};

export type UploadHeader = {
  __typename: "UploadHeader";
  name: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type UploadMetadata = {
  height?: InputMaybe<Scalars["Int"]["input"]>;
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UploadTicket = {
  __typename: "UploadTicket";
  formData: Maybe<Array<FormData>>;
  headers: Array<UploadHeader>;
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type UploadTicketInput = {
  contentLength: Scalars["String"]["input"];
  contentMD5: Scalars["String"]["input"];
  contentType: Scalars["String"]["input"];
  metadata?: InputMaybe<UploadMetadata>;
  name: Scalars["String"]["input"];
};

export type UploadTicketResponse = File | UploadTicket;

export type User = Node &
  Recipient & {
    __typename: "User";
    addressDomains: Array<Scalars["String"]["output"]>;
    avatarURL: Maybe<Scalars["String"]["output"]>;
    bio: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    name: Scalars["String"]["output"];
    workspaceIDs: Array<Scalars["ID"]["output"]>;
  };

export type UserConnection = Connection & {
  __typename: "UserConnection";
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserEdge = Edge &
  Node & {
    __typename: "UserEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    lastInteractionAt: Maybe<Scalars["String"]["output"]>;
    node: User;
    persistentChatEdge: Maybe<ThreadEdge>;
    status: UserEdgeStatus;
    unreadThreadCounts: UnreadCounts;
  };

export enum UserEdgeStatus {
  Blocked = "blocked",
  Connected = "connected",
  Hidden = "hidden",
  None = "none",
  Starred = "starred",
}

export type UserLlmSettings = {
  __typename: "UserLLMSettings";
  chatModel: Scalars["String"]["output"];
};

export type UserLlmSettingsInput = {
  chatModel: Scalars["String"]["input"];
};

export enum UserMilestone {
  NoWorkspaceWall = "noWorkspaceWall",
  Onboarding = "onboarding",
}

export type UserPreviewConnection = Connection & {
  __typename: "UserPreviewConnection";
  edges: Array<UserPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserPreviewEdge = Edge &
  Node & {
    __typename: "UserPreviewEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: User;
  };

export type UserSettings = {
  __typename: "UserSettings";
  /** @deprecated no longer supported */
  autoArchive: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  llmSettings: UserLlmSettings;
  notifyMessages: NotifyMessagesSetting;
};

export type UsersFilter = {
  addressIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  edgeStatus?: InputMaybe<Array<UserEdgeStatus>>;
  excludeIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  mutualGroups?: InputMaybe<Scalars["Boolean"]["input"]>;
  mutualThreads?: InputMaybe<Scalars["Boolean"]["input"]>;
  mutualWorkspaceIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  mutualWorkspaces?: InputMaybe<Scalars["Boolean"]["input"]>;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
  workspaceIDs?: InputMaybe<Array<Scalars["ID"]["input"]>>;
};

export enum UsersOrder {
  Interaction = "interaction",
  Name = "name",
}

export type Workspace = IWorkspace &
  Node & {
    __typename: "Workspace";
    admin: Maybe<User>;
    apps: WorkspaceAppConnection;
    avatarURL: Maybe<Scalars["String"]["output"]>;
    domains: Array<Scalars["String"]["output"]>;
    groups: GroupOrPreviewConnection;
    id: Scalars["ID"]["output"];
    joinableBy: JoinableBy;
    members: WorkspaceMemberConnection;
    name: Scalars["String"]["output"];
    pendingApprovals: JoinApprovalConnection;
  };

export type WorkspaceAppsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkspaceGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<GroupsFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<GroupsOrder>;
};

export type WorkspaceMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<WorkspaceMemberOrder>;
};

export type WorkspacePendingApprovalsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkspaceAppConfig = {
  __typename: "WorkspaceAppConfig";
  token: Maybe<Scalars["String"]["output"]>;
  url: Scalars["String"]["output"];
};

export type WorkspaceAppConnection = Connection & {
  __typename: "WorkspaceAppConnection";
  edges: Array<WorkspaceAppEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WorkspaceAppEdge = Edge &
  Node & {
    __typename: "WorkspaceAppEdge";
    appUser: User;
    creator: User;
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: App;
    webhooks: Array<WorkspaceAppWebhook>;
    workspaceID: Scalars["ID"]["output"];
  };

export type WorkspaceAppInput = {
  appID: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
};

export type WorkspaceAppWebhook = Node & {
  __typename: "WorkspaceAppWebhook";
  id: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type WorkspaceConnection = Connection & {
  __typename: "WorkspaceConnection";
  edges: Array<WorkspaceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WorkspaceEdge = Edge &
  Node & {
    __typename: "WorkspaceEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    memberRole: MemberRole;
    node: Workspace;
    persistentChatEdge: ThreadEdge;
    /** @deprecated Use `memberRole` */
    role: Scalars["String"]["output"];
    unreadGroupThreadCounts: UnreadCounts;
    unreadThreadCounts: UnreadCounts;
  };

export type WorkspaceInviteLink = Node & {
  __typename: "WorkspaceInviteLink";
  count: Scalars["Int"]["output"];
  enabled: Scalars["Boolean"]["output"];
  expiresAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  maxCount: Scalars["Int"]["output"];
  workspaceID: Scalars["ID"]["output"];
};

export type WorkspaceLlmapiKey = {
  __typename: "WorkspaceLLMAPIKey";
  id: Scalars["ID"]["output"];
  keyPreview: Scalars["String"]["output"];
  llmProvider: LlmProvider;
  userID: Scalars["ID"]["output"];
  workspaceID: Scalars["ID"]["output"];
};

export type WorkspaceLlmapiKeyInput = {
  key: Scalars["String"]["input"];
  llmProvider: LlmProvider;
  workspaceID: Scalars["ID"]["input"];
};

export type WorkspaceLlmSettings = {
  __typename: "WorkspaceLLMSettings";
  llmAPIKeys: Array<WorkspaceLlmapiKey>;
};

export type WorkspaceLlmSettingsInput = {
  allowedChatModels: Array<Scalars["String"]["input"]>;
};

export type WorkspaceMemberConnection = Connection & {
  __typename: "WorkspaceMemberConnection";
  edges: Array<WorkspaceMemberEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WorkspaceMemberEdge = Edge &
  Node & {
    __typename: "WorkspaceMemberEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    memberRole: MemberRole;
    node: User;
    pending: Scalars["Boolean"]["output"];
    /** @deprecated Use `memberRole` */
    role: Scalars["String"]["output"];
  };

export enum WorkspaceMemberOrder {
  Added = "added",
  Name = "name",
}

export type WorkspaceOrPreviewConnection = {
  __typename: "WorkspaceOrPreviewConnection";
  edges: Array<WorkspaceOrPreviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WorkspaceOrPreviewEdge = WorkspaceEdge | WorkspacePreviewEdge;

export type WorkspacePreview = IWorkspace &
  Joinable &
  Node &
  Recipient & {
    __typename: "WorkspacePreview";
    admin: Maybe<User>;
    avatarURL: Maybe<Scalars["String"]["output"]>;
    domains: Array<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    joinableBy: JoinableBy;
    members: UserPreviewConnection;
    name: Scalars["String"]["output"];
  };

export type WorkspacePreviewMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WorkspacePreviewEdge = Edge &
  Node & {
    __typename: "WorkspacePreviewEdge";
    cursor: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    node: WorkspacePreview;
  };

export type WorkspaceSettings = Node & {
  __typename: "WorkspaceSettings";
  id: Scalars["ID"]["output"];
  llmSettings: WorkspaceLlmSettings;
};

export type WorkspaceSubscription = Node & {
  __typename: "WorkspaceSubscription";
  cancelAtPeriodEnd: Scalars["Boolean"]["output"];
  currentPeriodEnd: Maybe<Scalars["String"]["output"]>;
  currentPeriodStart: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  latestInvoice: Maybe<Invoice>;
  nextInvoice: Maybe<Invoice>;
  plan: Maybe<SubscriptionPlan>;
  quantity: Scalars["Int"]["output"];
  status: SubscriptionStatus;
};

export type WorkspaceSubscriptionInput = {
  subscriptionPlanID: Scalars["ID"]["input"];
};

export type WorkspacesFilter = {
  match?: InputMaybe<Scalars["String"]["input"]>;
};

export type _Service = {
  __typename: "_Service";
  sdl: Scalars["String"]["output"];
};

export type AppFieldsFragment = {
  __typename: "App";
  id: string;
  name: string;
  avatarURL: string;
  configureGroup: AppConfigureMethod | null;
  configureGroupPath: string | null;
  configureThread: AppConfigureMethod | null;
  configureThreadPath: string | null;
  configureWorkspace: AppConfigureMethod;
  configureWorkspacePath: string | null;
  description: string;
  docsURL: string;
  homeURL: string;
  instructions: string;
  publishedAt: string | null;
  threadActions: Array<{
    __typename: "ThreadAction";
    description: string;
    label: string;
    name: string;
    path: string | null;
  }> | null;
  workspace: { __typename: "WorkspacePreview"; id: string; name: string };
};

export type WebhookFieldsFragment = {
  __typename: "WorkspaceAppWebhook";
  id: string;
  url: string;
};

export type WorkspaceAppEdgeFieldsFragment = {
  __typename: "WorkspaceAppEdge";
  id: string;
  workspaceID: string;
  node: { __typename: "App" } & AppFieldsFragment;
  webhooks: Array<
    { __typename: "WorkspaceAppWebhook" } & WebhookFieldsFragment
  >;
  creator: { __typename: "User" } & UserFieldsFragment;
  appUser: { __typename: "User" } & UserFieldsFragment;
};

export type DraftFieldsFragment = {
  __typename: "Draft";
  id: string;
  recipientsAddable: Addable;
  subject: string;
  updatedAt: string;
  recipients: Array<
    | ({ __typename: "GroupPreview" } & RecipientFields_GroupPreview_Fragment)
    | ({ __typename: "User" } & RecipientFields_User_Fragment)
    | ({
        __typename: "WorkspacePreview";
      } & RecipientFields_WorkspacePreview_Fragment)
  >;
  message: {
    __typename: "DraftMessage";
    text: string;
    textPreview: string;
    attachments: Array<
      | ({ __typename: "ExternalObject" } & ExternalObjectFieldsFragment)
      | ({ __typename: "File" } & FileFieldsFragment)
      | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
    >;
  };
  replyToMessage: ({ __typename: "Message" } & MessageFieldsFragment) | null;
};

export type CustomEmojiFieldsFragment = {
  __typename: "CustomEmoji";
  id: string;
  createdAt: string;
  imageURL: string;
  name: string;
  workspaceID: string;
  createdBy: { __typename: "User" } & UserFieldsFragment;
};

export type ExternalObjectFieldsFragment = {
  __typename: "ExternalObject";
  id: string;
  url: string;
  objectType: ExternalObjectType;
  title: string;
  description: string | null;
  siteName: string;
  layout: ExternalObjectLayout;
  icon: { __typename: "ExternalObjectMedia"; url: string } | null;
  image: Array<{
    __typename: "ExternalObjectMedia";
    url: string;
    blurHash: string | null;
  }> | null;
  video: Array<{
    __typename: "ExternalObjectMedia";
    url: string;
    contentType: string | null;
  }> | null;
  audio: Array<{
    __typename: "ExternalObjectMedia";
    url: string;
    contentType: string | null;
  }> | null;
};

export type FileFieldsFragment = {
  __typename: "File";
  id: string;
  url: string;
  name: string;
  fileType: FileType;
  contentType: string;
  contentLength: string;
  previewable: boolean | null;
  metadata: {
    __typename: "FileMetadata";
    width: number | null;
    height: number | null;
    blurHash: string | null;
  } | null;
};

export type UploadTicketFieldsFragment = {
  __typename: "UploadTicket";
  id: string;
  url: string;
  headers: Array<{ __typename: "UploadHeader"; name: string; value: string }>;
  formData: Array<{
    __typename: "FormData";
    name: string;
    value: string;
  }> | null;
};

export type GroupCommonFieldsFragment = {
  __typename: "Group";
  id: string;
  name: string;
  description: string | null;
  workspaceID: string | null;
  joinableBy: JoinableBy;
  commonWorkspaceIDs: Array<string>;
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
};

export type GroupSimpleFieldsFragment = {
  __typename: "Group";
} & GroupCommonFieldsFragment;

export type GroupFieldsFragment = {
  __typename: "Group";
  members: {
    __typename: "GroupMemberConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: "GroupMemberEdge";
      cursor: string;
      memberRole: MemberRole;
      node: { __typename: "User" } & UserFieldsFragment;
    }>;
  };
  pendingApprovals: {
    __typename: "JoinApprovalConnection";
    totalCount: number;
    edges: Array<{
      __typename: "JoinApprovalEdge";
      node: { __typename: "JoinApproval" } & JoinApprovalSimpleFieldsFragment;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
  };
  apps: {
    __typename: "WorkspaceAppConnection";
    edges: Array<
      { __typename: "WorkspaceAppEdge" } & WorkspaceAppEdgeFieldsFragment
    >;
  };
} & GroupCommonFieldsFragment;

export type GroupEdgeCommonFieldsFragment = {
  __typename: "GroupEdge";
  id: string;
  cursor: string;
  memberRole: MemberRole;
  lastInteractionAt: string;
  threadSubscription: ThreadSubscription;
  unreadThreadCounts: {
    __typename: "UnreadCounts";
  } & UnreadCountFieldsFragment;
};

export type GroupEdgeSimpleFieldsFragment = {
  __typename: "GroupEdge";
} & GroupEdgeCommonFieldsFragment;

export type GroupEdgeFieldsFragment = {
  __typename: "GroupEdge";
  persistentChatEdge: {
    __typename: "ThreadEdge";
    node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
  } & ThreadEdgeFieldsFragment;
} & GroupEdgeCommonFieldsFragment;

export type GroupPreviewCommonFieldsFragment = {
  __typename: "GroupPreview";
  id: string;
  name: string;
  description: string | null;
  workspaceID: string | null;
  joinableBy: JoinableBy;
  commonWorkspaceIDs: Array<string>;
};

export type GroupPreviewSimpleFieldsFragment = {
  __typename: "GroupPreview";
} & GroupPreviewCommonFieldsFragment;

export type GroupPreviewFieldsFragment = {
  __typename: "GroupPreview";
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
  members: {
    __typename: "UserPreviewConnection";
    totalCount: number;
    edges: Array<{
      __typename: "UserPreviewEdge";
      node: { __typename: "User" } & UserFieldsFragment;
    }>;
  };
} & GroupPreviewCommonFieldsFragment;

export type InviteLinkFragment = {
  __typename: "WorkspaceInviteLink";
  id: string;
  count: number;
  enabled: boolean;
  expiresAt: string;
  maxCount: number;
  workspaceID: string;
};

export type JoinApprovalCommonFieldsFragment = {
  __typename: "JoinApproval";
  id: string;
  adminApprovedAt: string | null;
  joiningApprovedAt: string | null;
  requestedAt: string;
};

export type JoinApprovalSimpleFieldsFragment = {
  __typename: "JoinApproval";
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
  joining:
    | ({ __typename: "GroupPreview" } & RecipientFields_GroupPreview_Fragment)
    | ({ __typename: "User" } & RecipientFields_User_Fragment)
    | ({
        __typename: "WorkspacePreview";
      } & RecipientFields_WorkspacePreview_Fragment);
  requester: { __typename: "User" } & UserFieldsFragment;
} & JoinApprovalCommonFieldsFragment;

export type JoinApprovalFieldsFragment = {
  __typename: "JoinApproval";
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
  joining:
    | ({ __typename: "GroupPreview" } & RecipientFields_GroupPreview_Fragment)
    | ({ __typename: "User" } & RecipientFields_User_Fragment)
    | ({
        __typename: "WorkspacePreview";
      } & RecipientFields_WorkspacePreview_Fragment);
  joinable:
    | ({ __typename: "GroupPreview" } & GroupPreviewFieldsFragment)
    | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
    | ({ __typename: "WorkspacePreview" } & WorkspacePreviewFieldsFragment);
  requester: { __typename: "User" } & UserFieldsFragment;
} & JoinApprovalCommonFieldsFragment;

export type LambdaFieldsFragment = {
  __typename: "Lambda";
  id: string;
  name: string;
  description: string;
  event: LambdaEvent;
  source: string;
  appId: string;
  enabled: boolean;
  activeVersion: number;
};

export type LambdaSecretFieldsFragment = {
  __typename: "LambdaSecret";
  id: string;
  name: string;
};

export type LinkFieldsFragment = {
  __typename: "Link";
  id: string;
  linked:
    | ({ __typename: "ExternalObject" } & ExternalObjectFieldsFragment)
    | ({ __typename: "File" } & FileFieldsFragment)
    | { __typename: "GroupPreview" }
    | { __typename: "MessagePreview" }
    | { __typename: "ThreadPreview" }
    | ({ __typename: "User" } & UserFieldsFragment);
  message: { __typename: "MessagePreview" } & MessagePreviewFieldsFragment;
};

export type LinkConnectionFieldsFragment = {
  __typename: "LinkConnection";
  totalCount: number;
  edges: Array<{
    __typename: "LinkEdge";
    node: { __typename: "Link" } & LinkFieldsFragment;
  }>;
  pageInfo: {
    __typename: "PageInfo";
    hasPreviousPage: boolean;
    startCursor: string | null;
  };
};

export type MessageFieldsFragment = {
  __typename: "Message";
  id: string;
  text: string;
  textPreview: string;
  threadID: string;
  streamID: string | null;
  replyThreadID: string | null;
  createdAt: string;
  updatedAt: string | null;
  user: { __typename: "User" } & UserFieldsFragment;
  quotedMessage:
    | ({ __typename: "QuotedMessage" } & QuotedMessageFieldsFragment)
    | null;
  ownReactions: Array<{ __typename: "Reaction" } & ReactionsFieldsFragment>;
  reactions: {
    __typename: "ReactionConnection";
    edges: Array<{
      __typename: "ReactionEdge";
      node: { __typename: "Reaction" } & ReactionsFieldsFragment;
    }>;
  };
  reactionCounts: Array<{ __typename: "ReactionCount"; c: number; r: string }>;
  attachments: Array<
    | ({ __typename: "ExternalObject" } & ExternalObjectFieldsFragment)
    | ({ __typename: "File" } & FileFieldsFragment)
    | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
  >;
};

export type QuotedMessageFieldsFragment = {
  __typename: "QuotedMessage";
  id: string;
  streamID: string | null;
  text: string;
  textPreview: string;
  createdAt: string;
  updatedAt: string | null;
  user: { __typename: "User" } & UserFieldsFragment;
  attachments: Array<
    | ({ __typename: "ExternalObject" } & ExternalObjectFieldsFragment)
    | ({ __typename: "File" } & FileFieldsFragment)
    | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
  >;
};

export type MessageEdgeFieldsFragment = {
  __typename: "MessageEdge";
  id: string;
  cursor: string;
};

export type ReactionsFieldsFragment = {
  __typename: "Reaction";
  id: string;
  type: string;
  user: { __typename: "User" } & UserFieldsFragment;
};

export type LlmModelFieldsFragment = {
  __typename: "LLMModel";
  id: string;
  name: string;
  provider: LlmProvider;
  providerLogoURL: string;
  providerName: string;
};

export type MessageAiResponseInfoFieldsFragment = {
  __typename: "MessageAIResponseInfo";
  sourcesDescription: string;
  toUserID: string;
  model: { __typename: "LLMModel" } & LlmModelFieldsFragment;
};

export type MessageMetadataFieldsFragment = {
  __typename: "MessageMetadata";
  id: string;
  messageID: string;
  aiResponseInfo:
    | ({
        __typename: "MessageAIResponseInfo";
      } & MessageAiResponseInfoFieldsFragment)
    | null;
};

export type MessagePreviewFieldsFragment = {
  __typename: "MessagePreview";
  id: string;
  createdAt: string;
  threadID: string;
  user: { __typename: "User" } & UserFieldsFragment;
};

export type NotificationEdgeFieldsFragment = {
  __typename: "NotificationEdge";
  id: string;
  isRead: boolean;
  isSeen: boolean;
};

export type NotificationFieldsFragment = {
  __typename: "Notification";
  id: string;
  text: string;
  activityCount: number;
  actorCount: number;
  verb: ActivityVerb;
  activities: Array<{
    __typename: "Activity";
    id: string;
    time: string;
    verb: ActivityVerb;
    actor: { __typename: "User" } & UserFieldsFragment;
    object:
      | ({ __typename: "GroupPreview" } & GroupPreviewFieldsFragment)
      | ({ __typename: "JoinApproval" } & JoinApprovalFieldsFragment)
      | ({ __typename: "Link" } & LinkFieldsFragment)
      | ({ __typename: "Message" } & MessageFieldsFragment)
      | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
      | ({ __typename: "User" } & UserFieldsFragment)
      | null;
    target:
      | ({ __typename: "GroupPreview" } & GroupPreviewFieldsFragment)
      | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
      | ({
          __typename: "WorkspacePreview";
        } & WorkspacePreviewSimpleFieldsFragment)
      | null;
    meta: {
      __typename: "ActivityReactedToMessageMeta";
      reaction: string;
    } | null;
  }>;
};

export type RecipientFields_GroupPreview_Fragment = {
  __typename: "GroupPreview";
} & GroupPreviewSimpleFieldsFragment;

export type RecipientFields_User_Fragment = {
  __typename: "User";
} & UserFieldsFragment;

export type RecipientFields_WorkspacePreview_Fragment = {
  __typename: "WorkspacePreview";
} & WorkspacePreviewSimpleFieldsFragment;

export type RecipientFieldsFragment =
  | RecipientFields_GroupPreview_Fragment
  | RecipientFields_User_Fragment
  | RecipientFields_WorkspacePreview_Fragment;

export type SlackImportConfigFragment = {
  __typename: "SlackImportConfig";
  groups: Array<string>;
  includeChannels: Array<string>;
  skipChannels: Array<string>;
};

export type SlackImportProgressItemFragment = {
  __typename: "SlackImportProgressItem";
  imported: number;
  total: number;
};

export type SlackImportProgressFragment = {
  __typename: "SlackImportProgress";
  channels: {
    __typename: "SlackImportProgressItem";
  } & SlackImportProgressItemFragment;
  messages: {
    __typename: "SlackImportProgressItem";
  } & SlackImportProgressItemFragment;
  users: {
    __typename: "SlackImportProgressItem";
  } & SlackImportProgressItemFragment;
};

export type SlackImportFragment = {
  __typename: "SlackImport";
  id: string;
  notificationsThreadID: string | null;
  status: SlackImportStatus;
  config:
    | ({ __typename: "SlackImportConfig" } & SlackImportConfigFragment)
    | null;
  progress:
    | ({ __typename: "SlackImportProgress" } & SlackImportProgressFragment)
    | null;
};

export type ThreadCommonFieldsFragment = {
  __typename: "Thread";
  id: string;
  subject: string;
  isPersistentChat: boolean;
  recipientsAddable: Addable;
  createdAt: string;
  lastMessage: ({ __typename: "Message" } & MessageFieldsFragment) | null;
  recipients: {
    __typename: "ThreadRecipientConnection";
    edges: Array<{
      __typename: "ThreadRecipientEdge";
      node:
        | ({
            __typename: "GroupPreview";
          } & RecipientFields_GroupPreview_Fragment)
        | ({ __typename: "User" } & RecipientFields_User_Fragment)
        | ({
            __typename: "WorkspacePreview";
          } & RecipientFields_WorkspacePreview_Fragment);
    }>;
  };
};

export type ThreadSimpleFieldsFragment = {
  __typename: "Thread";
} & ThreadCommonFieldsFragment;

export type ThreadFieldsFragment = {
  __typename: "Thread";
  workspaceID: string | null;
  replyToMessage: ({ __typename: "Message" } & MessageFieldsFragment) | null;
  firstMessage: ({ __typename: "Message" } & MessageFieldsFragment) | null;
  recentMessages: { __typename: "MessageConnection"; replyCount: number };
  recentMessagesUsers: Array<{ __typename: "User" } & UserFieldsFragment>;
  users: {
    __typename: "UserConnection";
    totalCount: number;
    edges: Array<{
      __typename: "UserEdge";
      node: { __typename: "User" } & UserFieldsFragment;
    }>;
  };
  apps: {
    __typename: "WorkspaceAppConnection";
    edges: Array<
      { __typename: "WorkspaceAppEdge" } & WorkspaceAppEdgeFieldsFragment
    >;
  };
} & ThreadCommonFieldsFragment;

export type ThreadInFeedFieldsFragment = {
  __typename: "Thread";
  recentMessages: {
    __typename: "MessageConnection";
    replyCount: number;
    edges: Array<
      {
        __typename: "MessageEdge";
        node: { __typename: "Message" } & MessageFieldsFragment;
      } & MessageEdgeFieldsFragment
    >;
  };
  lastRead: {
    __typename: "MessageConnection";
    edges: Array<
      {
        __typename: "MessageEdge";
        node: { __typename: "Message" } & MessageFieldsFragment;
      } & MessageEdgeFieldsFragment
    >;
  };
  aroundLastRead: {
    __typename: "MessageConnection";
    edges: Array<
      {
        __typename: "MessageEdge";
        node: { __typename: "Message" } & MessageFieldsFragment;
      } & MessageEdgeFieldsFragment
    >;
  };
  lastUnread: {
    __typename: "MessageConnection";
    edges: Array<
      {
        __typename: "MessageEdge";
        node: { __typename: "Message" } & MessageFieldsFragment;
      } & MessageEdgeFieldsFragment
    >;
  };
} & ThreadFieldsFragment;

export type ThreadEdgeFieldsFragment = {
  __typename: "ThreadEdge";
  id: string;
  cursor: string;
  recipientRole: MemberRole;
  isArchived: boolean;
  remindAt: string | null;
  isMentioned: boolean;
  isStarred: boolean;
  isRead: boolean;
  isSeen: boolean;
  lastReadID: string | null;
  lastSeenID: string | null;
  subscription: ThreadSubscription;
  unreadMessageCounts: {
    __typename: "UnreadCounts";
  } & UnreadCountFieldsFragment;
};

export type ThreadEdgeSimpleAndNodeFieldsFragment = {
  __typename: "ThreadEdge";
  node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
} & ThreadEdgeFieldsFragment;

export type ThreadPreviewFieldsFragment = {
  __typename: "ThreadPreview";
  id: string;
  name: string;
  subject: string;
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
  recipients: {
    __typename: "RecipientConnection";
    edges: Array<{
      __typename: "RecipientEdge";
      node:
        | ({
            __typename: "GroupPreview";
          } & RecipientFields_GroupPreview_Fragment)
        | ({ __typename: "User" } & RecipientFields_User_Fragment)
        | ({
            __typename: "WorkspacePreview";
          } & RecipientFields_WorkspacePreview_Fragment);
    }>;
  };
};

export type ThreadMetadataFieldsFragment = {
  __typename: "ThreadMetadata";
  id: string;
  threadID: string;
  aiSettings: { __typename: "AISettings"; chatModel: string | null } | null;
};

export type ThreadSummaryPointFieldsFragment = {
  __typename: "ThreadSummaryPoint";
  id: string;
  point: string;
  threadID: string;
};

export type UnreadCountFieldsFragment = {
  __typename: "UnreadCounts";
  mentioned: number;
  total: number;
  unseen: number;
};

export type UserFieldsFragment = {
  __typename: "User";
  id: string;
  name: string;
  addressDomains: Array<string>;
  avatarURL: string | null;
  bio: string | null;
  workspaceIDs: Array<string>;
};

export type UserEdgeCommonFieldsFragment = {
  __typename: "UserEdge";
  id: string;
  status: UserEdgeStatus;
  lastInteractionAt: string | null;
  unreadThreadCounts: {
    __typename: "UnreadCounts";
  } & UnreadCountFieldsFragment;
};

export type UserEdgeFieldsFragment = {
  __typename: "UserEdge";
  persistentChatEdge:
    | ({
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | null;
} & UserEdgeCommonFieldsFragment;

export type SettingsFieldsFragment = {
  __typename: "UserSettings";
  notifyMessages: NotifyMessagesSetting;
  llmSettings: { __typename: "UserLLMSettings"; chatModel: string };
};

export type WorkspaceCommonFieldsFragment = {
  __typename: "Workspace";
  id: string;
  name: string;
  domains: Array<string>;
  avatarURL: string | null;
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
};

export type WorkspaceSimpleFieldsFragment = {
  __typename: "Workspace";
} & WorkspaceCommonFieldsFragment;

export type WorkspaceFieldsFragment = {
  __typename: "Workspace";
  apps: {
    __typename: "WorkspaceAppConnection";
    edges: Array<
      { __typename: "WorkspaceAppEdge" } & WorkspaceAppEdgeFieldsFragment
    >;
  };
  groups: {
    __typename: "GroupOrPreviewConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: "GroupOrPreviewEdge";
      cursor: string;
      node:
        | ({ __typename: "Group" } & GroupFieldsFragment)
        | ({ __typename: "GroupPreview" } & GroupPreviewFieldsFragment);
    }>;
  };
  members: {
    __typename: "WorkspaceMemberConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: "WorkspaceMemberEdge";
      cursor: string;
      memberRole: MemberRole;
      pending: boolean;
      node: { __typename: "User" } & UserFieldsFragment;
    }>;
  };
  pendingApprovals: {
    __typename: "JoinApprovalConnection";
    totalCount: number;
    edges: Array<{
      __typename: "JoinApprovalEdge";
      cursor: string;
      node: { __typename: "JoinApproval" } & JoinApprovalSimpleFieldsFragment;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
  };
} & WorkspaceCommonFieldsFragment;

export type WorkspaceEdgeCommonFieldsFragment = {
  __typename: "WorkspaceEdge";
  id: string;
  cursor: string;
  memberRole: MemberRole;
  unreadThreadCounts: {
    __typename: "UnreadCounts";
  } & UnreadCountFieldsFragment;
  unreadGroupThreadCounts: {
    __typename: "UnreadCounts";
  } & UnreadCountFieldsFragment;
};

export type WorkspaceEdgeSimpleFieldsFragment = {
  __typename: "WorkspaceEdge";
} & WorkspaceEdgeCommonFieldsFragment;

export type WorkspaceEdgeFieldsFragment = {
  __typename: "WorkspaceEdge";
  persistentChatEdge: {
    __typename: "ThreadEdge";
    node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
  } & ThreadEdgeFieldsFragment;
} & WorkspaceEdgeCommonFieldsFragment;

export type WorkspacePreviewCommonFieldsFragment = {
  __typename: "WorkspacePreview";
  id: string;
  name: string;
  domains: Array<string>;
  avatarURL: string | null;
  joinableBy: JoinableBy;
};

export type WorkspacePreviewSimpleFieldsFragment = {
  __typename: "WorkspacePreview";
} & WorkspacePreviewCommonFieldsFragment;

export type WorkspacePreviewFieldsFragment = {
  __typename: "WorkspacePreview";
  admin: ({ __typename: "User" } & UserFieldsFragment) | null;
  members: {
    __typename: "UserPreviewConnection";
    totalCount: number;
    edges: Array<{
      __typename: "UserPreviewEdge";
      node: { __typename: "User" } & UserFieldsFragment;
    }>;
  };
} & WorkspacePreviewCommonFieldsFragment;

export type WorkspaceSettingsFieldsFragment = {
  __typename: "WorkspaceSettings";
  id: string;
  llmSettings: {
    __typename: "WorkspaceLLMSettings";
    llmAPIKeys: Array<
      { __typename: "WorkspaceLLMAPIKey" } & WorkspaceLlmapiKeyFieldsFragment
    >;
  };
};

export type WorkspaceLlmapiKeyFieldsFragment = {
  __typename: "WorkspaceLLMAPIKey";
  id: string;
  keyPreview: string;
  llmProvider: LlmProvider;
  userID: string;
};

export type RegisterDeviceMutationVariables = Exact<{
  input: RegisterDeviceInput;
}>;

export type RegisterDeviceMutation = {
  registerDevice: { __typename: "Device"; id: string };
};

export type ThreadSummaryQueryVariables = Exact<{
  threadID: Scalars["ID"]["input"];
  level: Scalars["Int"]["input"];
  afterMessageID?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type ThreadSummaryQuery = {
  threadSummaryPoints: {
    __typename: "ThreadSummaryPointConnection";
    edges: Array<{
      __typename: "ThreadSummaryPointEdge";
      node: {
        __typename: "ThreadSummaryPoint";
      } & ThreadSummaryPointFieldsFragment;
    }>;
  };
};

export type ConnectWithUsersMutationVariables = Exact<{
  users: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type ConnectWithUsersMutation = {
  connectWithUsers: {
    __typename: "UserConnection";
    edges: Array<
      {
        __typename: "UserEdge";
        node: { __typename: "User" } & UserFieldsFragment;
      } & UserEdgeFieldsFragment
    >;
  };
};

export type UpdateWorkspaceInviteLinkMutationVariables = Exact<{
  input: UpdateWorkspaceInviteLinkInput;
}>;

export type UpdateWorkspaceInviteLinkMutation = {
  updateWorkspaceInviteLink: {
    __typename: "WorkspaceInviteLink";
  } & InviteLinkFragment;
};

export type ResetWorkspaceInviteLinkMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type ResetWorkspaceInviteLinkMutation = {
  resetWorkspaceInviteLink: boolean;
};

export type WorkspaceInviteLinkQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type WorkspaceInviteLinkQuery = {
  workspaceInviteLink: {
    __typename: "WorkspaceInviteLink";
  } & InviteLinkFragment;
};

export type WorkspaceInviteLinkSignedQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type WorkspaceInviteLinkSignedQuery = {
  workspaceInviteLinkSigned: string;
};

export type MasonryRequestMutationVariables = Exact<{
  input: MasonryRequestInput;
}>;

export type MasonryRequestMutation = {
  masonryRequest: {
    __typename: "MasonryResponse";
    responseType: string;
    context: any | null;
    data: any | null;
    actions: Array<any | null> | null;
  };
};

export type MasonrySessionMutationVariables = Exact<{
  input: MasonrySessionOriginationInput;
}>;

export type MasonrySessionMutation = {
  masonrySession: {
    __typename: "MasonrySessionResponse";
    origination: any;
    context: any;
  };
};

export type AuthorizeWorkspaceAppMutationVariables = Exact<{
  input: AuthorizeWorkspaceAppInput;
}>;

export type AuthorizeWorkspaceAppMutation = {
  authorizeWorkspaceApp: {
    __typename: "AuthorizeWorkspaceAppResult";
    authorizationCode: string;
  } | null;
};

export type FetchAppQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchAppQuery = {
  node:
    | { __typename: "Address"; id: string }
    | ({ __typename: "App"; id: string } & AppFieldsFragment)
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type UploadTicketMutationVariables = Exact<{
  input: UploadTicketInput;
}>;

export type UploadTicketMutation = {
  uploadTicket:
    | ({ __typename: "File" } & FileFieldsFragment)
    | ({ __typename: "UploadTicket" } & UploadTicketFieldsFragment);
};

export type CreateFileMutationVariables = Exact<{
  uploadTicketID: Scalars["String"]["input"];
}>;

export type CreateFileMutation = {
  createFile: { __typename: "File" } & FileFieldsFragment;
};

export type UnfurlUrlMutationVariables = Exact<{
  input: UnfurlUrlInput;
}>;

export type UnfurlUrlMutation = {
  unfurlURL: {
    __typename: "UnfurlURLResult";
    externalObject: {
      __typename: "ExternalObject";
    } & ExternalObjectFieldsFragment;
    appUnfurlSetup: {
      __typename: "AppUnfurlSetup";
      path: string;
      message: string;
      workspaceAppID: string;
      url: string;
      appUser: { __typename: "User" } & UserFieldsFragment;
    } | null;
  };
};

export type PerformThreadActionMutationVariables = Exact<{
  input: ThreadActionInput;
}>;

export type PerformThreadActionMutation = {
  performThreadAction: { __typename: "ThreadActionResult"; success: boolean };
};

export type FetchRecipientQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchRecipientQuery = {
  node:
    | { __typename: "Address" }
    | { __typename: "App" }
    | { __typename: "CustomEmoji" }
    | { __typename: "Device" }
    | { __typename: "Domain" }
    | { __typename: "DomainEdge" }
    | { __typename: "Draft" }
    | { __typename: "ExternalObject" }
    | { __typename: "File" }
    | ({ __typename: "Group" } & GroupSimpleFieldsFragment)
    | { __typename: "GroupEdge" }
    | { __typename: "GroupMemberEdge" }
    | ({ __typename: "GroupPreview" } & GroupPreviewSimpleFieldsFragment)
    | { __typename: "GroupPreviewEdge" }
    | { __typename: "JoinApproval" }
    | { __typename: "LLMModel" }
    | { __typename: "Lambda" }
    | { __typename: "LambdaSecret" }
    | { __typename: "Link" }
    | { __typename: "Message" }
    | { __typename: "MessageEdge" }
    | { __typename: "MessageMetadata" }
    | { __typename: "MessagePin" }
    | { __typename: "MessagePreview" }
    | { __typename: "Notification" }
    | { __typename: "Reaction" }
    | { __typename: "SearchResult" }
    | { __typename: "SlackImport" }
    | { __typename: "Thread" }
    | ({
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | { __typename: "ThreadPreview" }
    | {
        __typename: "ThreadPreviewEdge";
        node: { __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment;
      }
    | { __typename: "ThreadRecipientEdge" }
    | { __typename: "ThreadSummaryPoint" }
    | ({ __typename: "User" } & UserFieldsFragment)
    | { __typename: "UserEdge" }
    | { __typename: "UserPreviewEdge" }
    | ({ __typename: "Workspace" } & WorkspaceSimpleFieldsFragment)
    | { __typename: "WorkspaceAppEdge" }
    | { __typename: "WorkspaceAppWebhook" }
    | { __typename: "WorkspaceEdge" }
    | { __typename: "WorkspaceInviteLink" }
    | { __typename: "WorkspaceMemberEdge" }
    | ({
        __typename: "WorkspacePreview";
      } & WorkspacePreviewSimpleFieldsFragment)
    | { __typename: "WorkspacePreviewEdge" }
    | { __typename: "WorkspaceSettings" }
    | { __typename: "WorkspaceSubscription" }
    | null;
};

export type NotificationCountsQueryVariables = Exact<{ [key: string]: never }>;

export type NotificationCountsQuery = {
  notifications: {
    __typename: "NotificationConnection";
    totalCount: number;
    unreadCount: number;
    unseenCount: number;
  };
  joinApprovals: { __typename: "JoinApprovalConnection"; totalCount: number };
};

export type MailboxCountsQueryVariables = Exact<{ [key: string]: never }>;

export type MailboxCountsQuery = {
  mentionedThreads: { __typename: "ThreadConnection"; totalCount: number };
  unreadInbox: { __typename: "ThreadConnection"; totalCount: number };
  unseenFeed: { __typename: "ThreadConnection"; totalCount: number };
};

export type OldWorkspacesAndGroupsListQueryVariables = Exact<{
  recentGroupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type OldWorkspacesAndGroupsListQuery = {
  groupsByWorkspace: {
    __typename: "GroupsByWorkspaceConnection";
    edges: Array<{
      __typename: "GroupsByWorkspaceEdge";
      node: {
        __typename: "GroupsByWorkspace";
        id: string;
        workspaceEdge:
          | ({
              __typename: "WorkspaceEdge";
              node: { __typename: "Workspace" } & WorkspaceSimpleFieldsFragment;
            } & WorkspaceEdgeSimpleFieldsFragment)
          | {
              __typename: "WorkspacePreviewEdge";
              node: {
                __typename: "WorkspacePreview";
              } & WorkspacePreviewSimpleFieldsFragment;
            };
        unreadGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
        recentGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
        otherGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
      };
    }>;
  };
};

export type WorkspacesAndGroupsListQueryVariables = Exact<{
  recentGroupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type WorkspacesAndGroupsListQuery = {
  groupsByWorkspace: {
    __typename: "GroupsByWorkspaceConnection";
    edges: Array<{
      __typename: "GroupsByWorkspaceEdge";
      node: {
        __typename: "GroupsByWorkspace";
        id: string;
        workspaceEdge:
          | ({
              __typename: "WorkspaceEdge";
              node: { __typename: "Workspace" } & WorkspaceSimpleFieldsFragment;
            } & WorkspaceEdgeSimpleFieldsFragment)
          | {
              __typename: "WorkspacePreviewEdge";
              node: {
                __typename: "WorkspacePreview";
              } & WorkspacePreviewSimpleFieldsFragment;
            };
        unseenGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
        recentGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
        otherGroups: {
          __typename: "GroupConnection";
          edges: Array<
            {
              __typename: "GroupEdge";
              node: { __typename: "Group" } & GroupSimpleFieldsFragment;
            } & GroupEdgeSimpleFieldsFragment
          >;
        };
      };
    }>;
  };
};

export type CreateSlackImportMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type CreateSlackImportMutation = {
  createSlackImport: { __typename: "SlackImport" } & SlackImportFragment;
};

export type LoadSlackImportMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
  fileID: Scalars["ID"]["input"];
}>;

export type LoadSlackImportMutation = {
  loadSlackImport: { __typename: "SlackImport" } & SlackImportFragment;
};

export type UpdateSlackImportMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
  config: SlackImportConfigInput;
}>;

export type UpdateSlackImportMutation = {
  updateSlackImport: { __typename: "SlackImport" } & SlackImportFragment;
};

export type StartSlackImportMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type StartSlackImportMutation = {
  startSlackImport: { __typename: "SlackImport" } & SlackImportFragment;
};

export type DeleteSlackImportMutationVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type DeleteSlackImportMutation = { deleteSlackImport: boolean };

export type SlackImportQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type SlackImportQuery = {
  slackImport: ({ __typename: "SlackImport" } & SlackImportFragment) | null;
};

export type CreateLambdaMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  event: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
  appID: Scalars["ID"]["input"];
}>;

export type CreateLambdaMutation = {
  createLambda: { __typename: "Lambda" } & LambdaFieldsFragment;
};

export type DeleteLambdaMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteLambdaMutation = { deleteLambda: boolean };

export type EnableLambdaMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  enabled: Scalars["Boolean"]["input"];
}>;

export type EnableLambdaMutation = {
  enableLambda: { __typename: "Lambda" } & LambdaFieldsFragment;
};

export type UpdateLambdaMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  event: Scalars["String"]["input"];
  source: Scalars["String"]["input"];
}>;

export type UpdateLambdaMutation = {
  updateLambda: { __typename: "Lambda" } & LambdaFieldsFragment;
};

export type CreateSecretMutationVariables = Exact<{
  lambdaID: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
}>;

export type CreateSecretMutation = {
  createLambdaSecret: {
    __typename: "LambdaSecret";
  } & LambdaSecretFieldsFragment;
};

export type UpdateSecretMutationVariables = Exact<{
  secretID: Scalars["ID"]["input"];
  value: Scalars["String"]["input"];
}>;

export type UpdateSecretMutation = { updateLambdaSecret: boolean };

export type DeleteSecretMutationVariables = Exact<{
  secretID: Scalars["ID"]["input"];
}>;

export type DeleteSecretMutation = { deleteLambdaSecret: boolean };

export type LambdasQueryVariables = Exact<{
  appId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LambdasQuery = {
  lambdas: {
    __typename: "LambdaConnection";
    edges: Array<{
      __typename: "LambdaEdge";
      node: { __typename: "Lambda" } & LambdaFieldsFragment;
    }>;
  };
};

export type LambdaSecretsQueryVariables = Exact<{
  lambdaId: Scalars["ID"]["input"];
}>;

export type LambdaSecretsQuery = {
  lambdaSecrets: {
    __typename: "LambdaSecretConnection";
    edges: Array<{
      __typename: "LambdaSecretEdge";
      node: { __typename: "LambdaSecret"; id: string; name: string };
    }>;
  };
};

export type AddWorkspaceAppMutationVariables = Exact<{
  input: WorkspaceAppInput;
}>;

export type AddWorkspaceAppMutation = {
  addWorkspaceApp: {
    __typename: "WorkspaceAppEdge";
  } & WorkspaceAppEdgeFieldsFragment;
};

export type AppsQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AppsQuery = {
  apps: {
    __typename: "AppConnection";
    edges: Array<{
      __typename: "AppEdge";
      node: { __typename: "App" } & AppFieldsFragment;
    }>;
  };
};

export type AppQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type AppQuery = {
  node:
    | { __typename: "Address"; id: string }
    | ({ __typename: "App"; id: string } & AppFieldsFragment)
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type WorkspaceAppsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspaceAppsQuery = {
  workspaces: {
    __typename: "WorkspaceConnection";
    edges: Array<{
      __typename: "WorkspaceEdge";
      id: string;
      node: {
        __typename: "Workspace";
        id: string;
        avatarURL: string | null;
        name: string;
        apps: {
          __typename: "WorkspaceAppConnection";
          edges: Array<{
            __typename: "WorkspaceAppEdge";
            id: string;
            node: { __typename: "App" } & AppFieldsFragment;
          }>;
        };
      };
    }>;
  };
};

export type WorkspaceAppConfigQueryVariables = Exact<{
  appID: Scalars["ID"]["input"];
  configurableID: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
}>;

export type WorkspaceAppConfigQuery = {
  workspaceAppConfig: {
    __typename: "WorkspaceAppConfig";
    url: string;
    token: string | null;
  };
};

export type DevicesQueryVariables = Exact<{ [key: string]: never }>;

export type DevicesQuery = {
  devices: {
    __typename: "DeviceConnection";
    edges: Array<{
      __typename: "DeviceEdge";
      node: { __typename: "Device"; id: string; platform: string };
    }>;
  };
};

export type CreateCustomEmojiMutationVariables = Exact<{
  input: CreateCustomEmojiInput;
}>;

export type CreateCustomEmojiMutation = {
  createCustomEmoji: { __typename: "CustomEmoji" } & CustomEmojiFieldsFragment;
};

export type DeleteCustomEmojiMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCustomEmojiMutation = { deleteCustomEmoji: boolean };

export type GenerateEmojiImageMutationVariables = Exact<{
  input: GenerateEmojiImageInput;
}>;

export type GenerateEmojiImageMutation = {
  generateEmojiImage: { __typename: "File" } & FileFieldsFragment;
};

export type CustomEmojisQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CustomEmojisQuery = {
  customEmojis: {
    __typename: "CustomEmojiConnection";
    edges: Array<{
      __typename: "CustomEmojiEdge";
      node: { __typename: "CustomEmoji" } & CustomEmojiFieldsFragment;
    }>;
  };
};

export type CustomEmojiQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CustomEmojiQuery = {
  node:
    | { __typename: "Address" }
    | { __typename: "App" }
    | ({ __typename: "CustomEmoji" } & CustomEmojiFieldsFragment)
    | { __typename: "Device" }
    | { __typename: "Domain" }
    | { __typename: "DomainEdge" }
    | { __typename: "Draft" }
    | { __typename: "ExternalObject" }
    | { __typename: "File" }
    | { __typename: "Group" }
    | { __typename: "GroupEdge" }
    | { __typename: "GroupMemberEdge" }
    | { __typename: "GroupPreview" }
    | { __typename: "GroupPreviewEdge" }
    | { __typename: "JoinApproval" }
    | { __typename: "LLMModel" }
    | { __typename: "Lambda" }
    | { __typename: "LambdaSecret" }
    | { __typename: "Link" }
    | { __typename: "Message" }
    | { __typename: "MessageEdge" }
    | { __typename: "MessageMetadata" }
    | { __typename: "MessagePin" }
    | { __typename: "MessagePreview" }
    | { __typename: "Notification" }
    | { __typename: "Reaction" }
    | { __typename: "SearchResult" }
    | { __typename: "SlackImport" }
    | { __typename: "Thread" }
    | { __typename: "ThreadEdge" }
    | { __typename: "ThreadPreview" }
    | { __typename: "ThreadPreviewEdge" }
    | { __typename: "ThreadRecipientEdge" }
    | { __typename: "ThreadSummaryPoint" }
    | { __typename: "User" }
    | { __typename: "UserEdge" }
    | { __typename: "UserPreviewEdge" }
    | { __typename: "Workspace" }
    | { __typename: "WorkspaceAppEdge" }
    | { __typename: "WorkspaceAppWebhook" }
    | { __typename: "WorkspaceEdge" }
    | { __typename: "WorkspaceInviteLink" }
    | { __typename: "WorkspaceMemberEdge" }
    | { __typename: "WorkspacePreview" }
    | { __typename: "WorkspacePreviewEdge" }
    | { __typename: "WorkspaceSettings" }
    | { __typename: "WorkspaceSubscription" }
    | null;
};

export type EmojiNameSuggestionQueryVariables = Exact<{
  input: EmojiNameSuggestionInput;
}>;

export type EmojiNameSuggestionQuery = {
  emojiNameSuggestion: {
    __typename: "CustomEmojiNameSuggestions";
    names: Array<string>;
  };
};

export type CreateGroupMutationVariables = Exact<{
  input: CreateGroupInput;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateGroupMutation = {
  createGroup: { __typename: "Group" } & GroupFieldsFragment;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdateGroupInput;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateGroupMutation = {
  updateGroup: { __typename: "Group" } & GroupFieldsFragment;
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteGroupMutation = { deleteGroup: boolean };

export type RemoveGroupMembersMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  memberIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RemoveGroupMembersMutation = {
  removeGroupMembers: { __typename: "Group" } & GroupFieldsFragment;
};

export type FetchGroupQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchGroupQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | ({ __typename: "Group"; id: string } & GroupFieldsFragment)
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type EmojiSuggestionQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type EmojiSuggestionQuery = {
  emojiSuggestion: { __typename: "EmojiSuggestion"; emoji: string | null };
};

export type UpdateGroupEdgeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  threadSubscription: ThreadSubscription;
}>;

export type UpdateGroupEdgeMutation = {
  updateGroupEdge: { __typename: "GroupEdge" } & GroupEdgeFieldsFragment;
};

export type LeaveGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type LeaveGroupMutation = { leaveGroup: boolean };

export type FetchGroupOrPreviewEdgeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchGroupOrPreviewEdgeQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | ({
        __typename: "GroupEdge";
        id: string;
        node: { __typename: "Group" } & GroupFieldsFragment;
      } & GroupEdgeFieldsFragment)
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | {
        __typename: "GroupPreviewEdge";
        id: string;
        node: { __typename: "GroupPreview" } & GroupPreviewFieldsFragment;
      }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | ({
        __typename: "WorkspaceEdge";
        id: string;
        node: { __typename: "Workspace" } & WorkspaceFieldsFragment;
      } & WorkspaceEdgeFieldsFragment)
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | {
        __typename: "WorkspacePreviewEdge";
        id: string;
        node: {
          __typename: "WorkspacePreview";
        } & WorkspacePreviewFieldsFragment;
      }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchGroupsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FetchGroupsQuery = {
  groups: {
    __typename: "GroupConnection";
    edges: Array<
      {
        __typename: "GroupEdge";
        node: { __typename: "Group" } & GroupSimpleFieldsFragment;
      } & GroupEdgeSimpleFieldsFragment
    >;
  };
};

export type AddThreadRecipientsMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  recipients: Array<RecipientRoleInput> | RecipientRoleInput;
}>;

export type AddThreadRecipientsMutation = {
  addThreadRecipients: { __typename: "Thread" } & ThreadFieldsFragment;
};

export type RemoveThreadRecipientsMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  recipientIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type RemoveThreadRecipientsMutation = {
  removeThreadRecipients: { __typename: "Thread" } & ThreadFieldsFragment;
};

export type RequestJoinThreadMutationVariables = Exact<{
  input: JoinRequestInput;
}>;

export type RequestJoinThreadMutation = {
  sendJoinRequest: { __typename: "JoinApproval"; id: string };
};

export type CancelRequestJoinThreadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CancelRequestJoinThreadMutation = { cancelJoinApproval: boolean };

export type NotInThreadQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  joinableID: Scalars["ID"]["input"];
}>;

export type NotInThreadQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | ({
        __typename: "ThreadEdge";
        id: string;
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | { __typename: "ThreadPreview"; id: string }
    | {
        __typename: "ThreadPreviewEdge";
        id: string;
        node: { __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment;
      }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
  joinRequest: { __typename: "JoinApproval"; id: string } | null;
};

export type SaveDraftMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input: SaveDraftInput;
}>;

export type SaveDraftMutation = {
  saveDraft: { __typename: "Draft" } & DraftFieldsFragment;
};

export type SendDraftMutationVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
  input: SaveDraftInput;
}>;

export type SendDraftMutation = {
  sendDraft: { __typename: "Thread" } & ThreadFieldsFragment;
};

export type DeleteDraftMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDraftMutation = { deleteDraft: boolean };

export type SendMessageMutationVariables = Exact<{
  input: SendMessageInput;
}>;

export type SendMessageMutation = {
  sendMessage: {
    __typename: "SendMessageOutput";
    message: ({ __typename: "Message" } & MessageFieldsFragment) | null;
    thread: { __typename: "Thread" } & ThreadFieldsFragment;
  };
};

export type FetchDraftQueryVariables = Exact<{
  draftID: Scalars["ID"]["input"];
}>;

export type FetchDraftQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | ({ __typename: "Draft"; id: string } & DraftFieldsFragment)
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchJoinApprovalsQueryVariables = Exact<{
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchJoinApprovalsQuery = {
  joinApprovals: {
    __typename: "JoinApprovalConnection";
    totalCount: number;
    edges: Array<{
      __typename: "JoinApprovalEdge";
      node: { __typename: "JoinApproval" } & JoinApprovalFieldsFragment;
    }>;
  };
};

export type ThreadListQueryVariables = Exact<{
  mailbox?: InputMaybe<ThreadsMailbox>;
  recipientID?: InputMaybe<Scalars["ID"]["input"]>;
  excludeChats?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeStarred?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<ThreadsOrder>;
}>;

export type ThreadListQuery = {
  threads: {
    __typename: "ThreadConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string | null;
      endCursor: string | null;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
    edges: Array<
      {
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment
    >;
  };
};

export type ThreadFeedQueryVariables = Exact<{
  mailbox?: InputMaybe<ThreadsMailbox>;
  recipientID?: InputMaybe<Scalars["ID"]["input"]>;
  excludeChats?: InputMaybe<Scalars["Boolean"]["input"]>;
  excludeStarred?: InputMaybe<Scalars["Boolean"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<ThreadsOrder>;
  includeLastRead?: InputMaybe<Scalars["Boolean"]["input"]>;
  beforeLastRead?: InputMaybe<Scalars["String"]["input"]>;
  beforeLastUnread?: InputMaybe<Scalars["String"]["input"]>;
  lastReadLimit?: InputMaybe<Scalars["Int"]["input"]>;
  lastUnreadLimit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type ThreadFeedQuery = {
  threads: {
    __typename: "ThreadConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string | null;
      endCursor: string | null;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
    edges: Array<
      {
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadInFeedFieldsFragment;
      } & ThreadEdgeFieldsFragment
    >;
  };
};

export type DraftListQueryVariables = Exact<{
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DraftListQuery = {
  drafts: {
    __typename: "DraftConnection";
    totalCount: number;
    edges: Array<{
      __typename: "DraftEdge";
      node: { __typename: "Draft" } & DraftFieldsFragment;
    }>;
    pageInfo: {
      __typename: "PageInfo";
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
  };
};

export type UpdateThreadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdateThreadInput;
}>;

export type UpdateThreadMutation = {
  updateThread: { __typename: "Thread" } & ThreadFieldsFragment;
};

export type LeaveThreadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type LeaveThreadMutation = { leaveThread: boolean };

export type DeleteThreadMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteThreadMutation = { deleteThread: boolean };

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteMessageMutation = { deleteMessage: boolean };

export type FetchThreadEdgeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchThreadEdgeQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | ({
        __typename: "ThreadEdge";
        id: string;
        node: { __typename: "Thread" } & ThreadFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | { __typename: "ThreadPreview"; id: string }
    | {
        __typename: "ThreadPreviewEdge";
        id: string;
        node: { __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment;
      }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type ThreadMessagesQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ThreadMessagesQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | {
        __typename: "Thread";
        id: string;
        messages: {
          __typename: "MessageConnection";
          pageInfo: {
            __typename: "PageInfo";
            startCursor: string | null;
            endCursor: string | null;
            hasNextPage: boolean;
            hasPreviousPage: boolean;
          };
          edges: Array<{
            __typename: "MessageEdge";
            id: string;
            node: { __typename: "Message" } & MessageFieldsFragment;
          }>;
        };
      }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchThreadEdgePaginatedQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  includeLastRead?: InputMaybe<Scalars["Boolean"]["input"]>;
  beforeLastRead?: InputMaybe<Scalars["String"]["input"]>;
  beforeLastUnread?: InputMaybe<Scalars["String"]["input"]>;
  lastReadLimit?: InputMaybe<Scalars["Int"]["input"]>;
  lastUnreadLimit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type FetchThreadEdgePaginatedQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | ({
        __typename: "ThreadEdge";
        id: string;
        node: { __typename: "Thread" } & ThreadInFeedFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | { __typename: "ThreadPreview"; id: string }
    | {
        __typename: "ThreadPreviewEdge";
        id: string;
        node: { __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment;
      }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchPendingJoinApprovalsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchPendingJoinApprovalsQuery = {
  joinApprovals: {
    __typename: "JoinApprovalConnection";
    totalCount: number;
    edges: Array<{
      __typename: "JoinApprovalEdge";
      node: { __typename: "JoinApproval" } & JoinApprovalFieldsFragment;
    }>;
  };
  joinRequests: {
    __typename: "JoinApprovalConnection";
    totalCount: number;
    edges: Array<{
      __typename: "JoinApprovalEdge";
      node: { __typename: "JoinApproval" } & JoinApprovalFieldsFragment;
    }>;
  };
};

export type FetchBoxFilePreviewQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchBoxFilePreviewQuery = {
  filePreview: {
    __typename: "FilePreview";
    boxFileID: string;
    boxAccessToken: string;
  };
};

export type FetchMessageMetadataQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchMessageMetadataQuery = {
  messageMetadata:
    | ({ __typename: "MessageMetadata" } & MessageMetadataFieldsFragment)
    | null;
};

export type MessagePinsQueryVariables = Exact<{
  threadID: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MessagePinsQuery = {
  messagePins: {
    __typename: "MessagePinConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      hasNextPage: boolean;
      endCursor: string | null;
    };
    edges: Array<{
      __typename: "MessagePinEdge";
      node: {
        __typename: "MessagePin";
        id: string;
        createdAt: string;
        user: { __typename: "User" } & UserFieldsFragment;
        message: { __typename: "Message" } & MessageFieldsFragment;
      };
    }>;
  };
};

export type FetchReplyToMessageQueryVariables = Exact<{
  messageID: Scalars["ID"]["input"];
  threadID: Scalars["ID"]["input"];
}>;

export type FetchReplyToMessageQuery = {
  message:
    | { __typename: "Address" }
    | { __typename: "App" }
    | { __typename: "CustomEmoji" }
    | { __typename: "Device" }
    | { __typename: "Domain" }
    | { __typename: "DomainEdge" }
    | { __typename: "Draft" }
    | { __typename: "ExternalObject" }
    | { __typename: "File" }
    | { __typename: "Group" }
    | { __typename: "GroupEdge" }
    | { __typename: "GroupMemberEdge" }
    | { __typename: "GroupPreview" }
    | { __typename: "GroupPreviewEdge" }
    | { __typename: "JoinApproval" }
    | { __typename: "LLMModel" }
    | { __typename: "Lambda" }
    | { __typename: "LambdaSecret" }
    | { __typename: "Link" }
    | ({ __typename: "Message" } & MessageFieldsFragment)
    | { __typename: "MessageEdge" }
    | { __typename: "MessageMetadata" }
    | { __typename: "MessagePin" }
    | { __typename: "MessagePreview" }
    | { __typename: "Notification" }
    | { __typename: "Reaction" }
    | { __typename: "SearchResult" }
    | { __typename: "SlackImport" }
    | { __typename: "Thread" }
    | { __typename: "ThreadEdge" }
    | { __typename: "ThreadPreview" }
    | { __typename: "ThreadPreviewEdge" }
    | { __typename: "ThreadRecipientEdge" }
    | { __typename: "ThreadSummaryPoint" }
    | { __typename: "User" }
    | { __typename: "UserEdge" }
    | { __typename: "UserPreviewEdge" }
    | { __typename: "Workspace" }
    | { __typename: "WorkspaceAppEdge" }
    | { __typename: "WorkspaceAppWebhook" }
    | { __typename: "WorkspaceEdge" }
    | { __typename: "WorkspaceInviteLink" }
    | { __typename: "WorkspaceMemberEdge" }
    | { __typename: "WorkspacePreview" }
    | { __typename: "WorkspacePreviewEdge" }
    | { __typename: "WorkspaceSettings" }
    | { __typename: "WorkspaceSubscription" }
    | null;
  thread:
    | { __typename: "Address" }
    | { __typename: "App" }
    | { __typename: "CustomEmoji" }
    | { __typename: "Device" }
    | { __typename: "Domain" }
    | { __typename: "DomainEdge" }
    | { __typename: "Draft" }
    | { __typename: "ExternalObject" }
    | { __typename: "File" }
    | { __typename: "Group" }
    | { __typename: "GroupEdge" }
    | { __typename: "GroupMemberEdge" }
    | { __typename: "GroupPreview" }
    | { __typename: "GroupPreviewEdge" }
    | { __typename: "JoinApproval" }
    | { __typename: "LLMModel" }
    | { __typename: "Lambda" }
    | { __typename: "LambdaSecret" }
    | { __typename: "Link" }
    | { __typename: "Message" }
    | { __typename: "MessageEdge" }
    | { __typename: "MessageMetadata" }
    | { __typename: "MessagePin" }
    | { __typename: "MessagePreview" }
    | { __typename: "Notification" }
    | { __typename: "Reaction" }
    | { __typename: "SearchResult" }
    | { __typename: "SlackImport" }
    | ({ __typename: "Thread" } & ThreadSimpleFieldsFragment)
    | { __typename: "ThreadEdge" }
    | ({ __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment)
    | { __typename: "ThreadPreviewEdge" }
    | { __typename: "ThreadRecipientEdge" }
    | { __typename: "ThreadSummaryPoint" }
    | { __typename: "User" }
    | { __typename: "UserEdge" }
    | { __typename: "UserPreviewEdge" }
    | { __typename: "Workspace" }
    | { __typename: "WorkspaceAppEdge" }
    | { __typename: "WorkspaceAppWebhook" }
    | { __typename: "WorkspaceEdge" }
    | { __typename: "WorkspaceInviteLink" }
    | { __typename: "WorkspaceMemberEdge" }
    | { __typename: "WorkspacePreview" }
    | { __typename: "WorkspacePreviewEdge" }
    | { __typename: "WorkspaceSettings" }
    | { __typename: "WorkspaceSubscription" }
    | null;
};

export type SubjectSuggestionQueryVariables = Exact<{
  replyToMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  input?: InputMaybe<SendMessageInput>;
}>;

export type SubjectSuggestionQuery = {
  draftSuggestion: { __typename: "DraftSuggestion"; subject: string | null };
};

export type RecipientsSuggestionQueryVariables = Exact<{
  replyToMessageID?: InputMaybe<Scalars["ID"]["input"]>;
  input?: InputMaybe<SendMessageInput>;
}>;

export type RecipientsSuggestionQuery = {
  draftSuggestion: { __typename: "DraftSuggestion"; recipients: Array<string> };
};

export type UpdateProfileMutationVariables = Exact<{
  input: UpdateMeInput;
}>;

export type UpdateProfileMutation = {
  updateMe: { __typename: "User" } & UserFieldsFragment;
};

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteMeInput;
}>;

export type DeleteAccountMutation = { deleteMe: boolean };

export type UpdateSettingsMutationVariables = Exact<{
  settings: UpdateSettingsInput;
}>;

export type UpdateSettingsMutation = {
  updateSettings: { __typename: "UserSettings" } & SettingsFieldsFragment;
};

export type UserSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type UserSettingsQuery = {
  settings: { __typename: "UserSettings" } & SettingsFieldsFragment;
};

export type LlmModelFragment = {
  __typename: "LLMModel";
  id: string;
  name: string;
  providerLogoURL: string;
  provider: LlmProvider;
  providerName: string;
};

export type FetchLlmModelsQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type FetchLlmModelsQuery = {
  llmModels: {
    __typename: "LLMModelConnection";
    edges: Array<{
      __typename: "LLMModelEdge";
      node: { __typename: "LLMModel" } & LlmModelFragment;
    }>;
  };
};

export type CreatePersistentChatMutationVariables = Exact<{
  input: CreatePersistentChatInput;
}>;

export type CreatePersistentChatMutation = {
  createPersistentChat: { __typename: "Thread" } & ThreadFieldsFragment;
};

export type UpdateUserEdgeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdateUserEdgeInput;
}>;

export type UpdateUserEdgeMutation = {
  updateUserEdge: {
    __typename: "UserEdge";
    id: string;
    node: { __typename: "User" } & UserFieldsFragment;
  } & UserEdgeFieldsFragment;
};

export type FetchUserEdgeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchUserEdgeQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | ({
        __typename: "UserEdge";
        id: string;
        node: { __typename: "User" } & UserFieldsFragment;
      } & UserEdgeFieldsFragment)
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchUsersQueryVariables = Exact<{
  after?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<UsersFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  order?: InputMaybe<UsersOrder>;
}>;

export type FetchUsersQuery = {
  users: {
    __typename: "UserConnection";
    edges: Array<
      {
        __typename: "UserEdge";
        node: { __typename: "User" } & UserFieldsFragment;
      } & UserEdgeFieldsFragment
    >;
    pageInfo: {
      __typename: "PageInfo";
      endCursor: string | null;
      hasNextPage: boolean;
    };
  };
};

export type ApproveJoinApprovalMutationVariables = Exact<{
  joinApprovalID: Scalars["ID"]["input"];
}>;

export type ApproveJoinApprovalMutation = {
  approveJoinApproval: { __typename: "JoinApproval"; id: string };
};

export type IgnoreJoinApprovalMutationVariables = Exact<{
  joinApprovalID: Scalars["ID"]["input"];
}>;

export type IgnoreJoinApprovalMutation = { ignoreJoinApproval: boolean };

export type NotificationsQueryVariables = Exact<{
  markEdgesSeen?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<NotificationsFilter>;
}>;

export type NotificationsQuery = {
  notifications: {
    __typename: "NotificationConnection";
    totalCount: number;
    unreadCount: number;
    unseenCount: number;
    edges: Array<
      {
        __typename: "NotificationEdge";
        node: { __typename: "Notification" } & NotificationFieldsFragment;
      } & NotificationEdgeFieldsFragment
    >;
  };
};

export type FetchMessageQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchMessageQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | ({ __typename: "Message"; id: string } & MessageFieldsFragment)
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type UpdateThreadMetadataMutationVariables = Exact<{
  input: UpdateThreadMetadataInput;
}>;

export type UpdateThreadMetadataMutation = {
  updateThreadMetadata: {
    __typename: "ThreadMetadata";
  } & ThreadMetadataFieldsFragment;
};

export type FetchThreadMetadataQueryVariables = Exact<{
  threadID: Scalars["ID"]["input"];
}>;

export type FetchThreadMetadataQuery = {
  threadMetadata: {
    __typename: "ThreadMetadata";
  } & ThreadMetadataFieldsFragment;
};

export type FetchExternalGroupsQueryVariables = Exact<{
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  groupsFirst?: InputMaybe<Scalars["Int"]["input"]>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchExternalGroupsQuery = {
  groups: {
    __typename: "GroupConnection";
    totalCount: number;
    pageInfo: {
      __typename: "PageInfo";
      endCursor: string | null;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor: string | null;
    };
    edges: Array<
      {
        __typename: "GroupEdge";
        node: { __typename: "Group" } & GroupFieldsFragment;
      } & GroupEdgeFieldsFragment
    >;
  };
};

export type FetchWorkspaceGroupsDirectoryQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  groupsBefore?: InputMaybe<Scalars["String"]["input"]>;
  groupsFirst?: InputMaybe<Scalars["Int"]["input"]>;
  groupsLast?: InputMaybe<Scalars["Int"]["input"]>;
  groupsOrder?: InputMaybe<GroupsOrder>;
  match?: InputMaybe<Scalars["String"]["input"]>;
  member?: InputMaybe<Scalars["Boolean"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchWorkspaceGroupsDirectoryQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | ({
        __typename: "WorkspaceEdge";
        id: string;
        node: {
          __typename: "Workspace";
          apps: {
            __typename: "WorkspaceAppConnection";
            edges: Array<
              {
                __typename: "WorkspaceAppEdge";
              } & WorkspaceAppEdgeFieldsFragment
            >;
          };
          groups: {
            __typename: "GroupOrPreviewConnection";
            totalCount: number;
            pageInfo: {
              __typename: "PageInfo";
              endCursor: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string | null;
            };
            edges: Array<{
              __typename: "GroupOrPreviewEdge";
              cursor: string;
              node:
                | ({ __typename: "Group" } & GroupFieldsFragment)
                | ({ __typename: "GroupPreview" } & GroupPreviewFieldsFragment);
            }>;
          };
          members: {
            __typename: "WorkspaceMemberConnection";
            totalCount: number;
            pageInfo: {
              __typename: "PageInfo";
              hasNextPage: boolean;
              endCursor: string | null;
            };
            edges: Array<{
              __typename: "WorkspaceMemberEdge";
              cursor: string;
              memberRole: MemberRole;
              pending: boolean;
              node: { __typename: "User" } & UserFieldsFragment;
            }>;
          };
          pendingApprovals: {
            __typename: "JoinApprovalConnection";
            totalCount: number;
            edges: Array<{
              __typename: "JoinApprovalEdge";
              cursor: string;
              node: {
                __typename: "JoinApproval";
              } & JoinApprovalSimpleFieldsFragment;
            }>;
            pageInfo: {
              __typename: "PageInfo";
              hasNextPage: boolean;
              endCursor: string | null;
            };
          };
        } & WorkspaceCommonFieldsFragment;
      } & WorkspaceEdgeFieldsFragment)
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | {
        __typename: "WorkspacePreviewEdge";
        id: string;
        node: {
          __typename: "WorkspacePreview";
        } & WorkspacePreviewFieldsFragment;
      }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type LinksQueryVariables = Exact<{
  filter?: InputMaybe<LinksFilter>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LinksQuery = {
  links: { __typename: "LinkConnection" } & LinkConnectionFieldsFragment;
};

export type LinksCountsQueryVariables = Exact<{
  recipientIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
}>;

export type LinksCountsQuery = {
  files: { __typename: "LinkConnection"; totalCount: number };
  media: { __typename: "LinkConnection"; totalCount: number };
  links: { __typename: "LinkConnection"; totalCount: number };
};

export type FileQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FileQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | ({ __typename: "File"; id: string } & FileFieldsFragment)
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchThreadEdgeSimpleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchThreadEdgeSimpleQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | ({
        __typename: "ThreadEdge";
        id: string;
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment)
    | { __typename: "ThreadPreview"; id: string }
    | {
        __typename: "ThreadPreviewEdge";
        id: string;
        node: { __typename: "ThreadPreview" } & ThreadPreviewFieldsFragment;
      }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type PersistentChatsQueryVariables = Exact<{
  chatType?: InputMaybe<ChatType>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<ThreadsOrder>;
}>;

export type PersistentChatsQuery = {
  persistentChats: {
    __typename: "ThreadConnection";
    totalCount: number;
    unreadCounts: { __typename: "UnreadCounts" } & UnreadCountFieldsFragment;
    pageInfo: {
      __typename: "PageInfo";
      startCursor: string | null;
      endCursor: string | null;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
    edges: Array<
      {
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment
    >;
  };
};

export type GlobalSearchQueryVariables = Exact<{
  contactAddressIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeGroupsIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeThreadsIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeUsersIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  mailbox?: InputMaybe<ThreadsMailbox>;
  match: Scalars["String"]["input"];
  matchMessages?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  threads?: InputMaybe<Scalars["Boolean"]["input"]>;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
  cacheKey?: InputMaybe<Scalars["String"]["input"]>;
  groups?: InputMaybe<Scalars["Boolean"]["input"]>;
  users?: InputMaybe<Scalars["Boolean"]["input"]>;
  userEdgeStatus?: InputMaybe<Array<UserEdgeStatus> | UserEdgeStatus>;
}>;

export type GlobalSearchQuery = {
  threads?: {
    __typename: "ThreadConnection";
    totalCount: number;
    matchedMessages: {
      __typename: "MessageConnection";
      edges: Array<
        {
          __typename: "MessageEdge";
          node: { __typename: "Message" } & MessageFieldsFragment;
        } & MessageEdgeFieldsFragment
      >;
    };
    edges: Array<
      {
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment
    >;
  };
  groups?: {
    __typename: "GroupConnection";
    totalCount: number;
    edges: Array<{
      __typename: "GroupEdge";
      id: string;
      node: { __typename: "Group" } & GroupSimpleFieldsFragment;
    }>;
  };
  workspaces?: {
    __typename: "WorkspaceConnection";
    totalCount: number;
    edges: Array<
      {
        __typename: "WorkspaceEdge";
        node: { __typename: "Workspace" } & WorkspaceSimpleFieldsFragment;
      } & WorkspaceEdgeSimpleFieldsFragment
    >;
  };
  users?: {
    __typename: "UserConnection";
    totalCount: number;
    edges: Array<
      {
        __typename: "UserEdge";
        node: { __typename: "User" } & UserFieldsFragment;
      } & UserEdgeFieldsFragment
    >;
  };
};

export type LocalSearchQueryVariables = Exact<{
  contactAddressIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeGroupsIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeThreadsIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  excludeUsersIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  mailbox?: InputMaybe<ThreadsMailbox>;
  match: Scalars["String"]["input"];
  matchMessages?: InputMaybe<Scalars["String"]["input"]>;
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  threads?: InputMaybe<Scalars["Boolean"]["input"]>;
  groups?: InputMaybe<Scalars["Boolean"]["input"]>;
  users?: InputMaybe<Scalars["Boolean"]["input"]>;
  userEdgeStatus?: InputMaybe<Array<UserEdgeStatus> | UserEdgeStatus>;
  mutualWorkspaceIDs?: InputMaybe<
    Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"]
  >;
  threadID?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type LocalSearchQuery = {
  threads: {
    __typename: "ThreadConnection";
    totalCount: number;
    matchedMessages: {
      __typename: "MessageConnection";
      edges: Array<
        {
          __typename: "MessageEdge";
          node: { __typename: "Message" } & MessageFieldsFragment;
        } & MessageEdgeFieldsFragment
      >;
    };
    edges: Array<
      {
        __typename: "ThreadEdge";
        node: { __typename: "Thread" } & ThreadSimpleFieldsFragment;
      } & ThreadEdgeFieldsFragment
    >;
  };
  groups: {
    __typename: "GroupOrPreviewConnection";
    totalCount: number;
    edges: Array<{
      __typename: "GroupOrPreviewEdge";
      id: string;
      node:
        | ({ __typename: "Group" } & GroupSimpleFieldsFragment)
        | ({ __typename: "GroupPreview" } & GroupPreviewSimpleFieldsFragment);
    }>;
  };
  workspaces: {
    __typename: "WorkspaceConnection";
    totalCount: number;
    edges: Array<
      {
        __typename: "WorkspaceEdge";
        node: { __typename: "Workspace" } & WorkspaceSimpleFieldsFragment;
      } & WorkspaceEdgeSimpleFieldsFragment
    >;
  };
  users: {
    __typename: "UserConnection";
    totalCount: number;
    edges: Array<
      {
        __typename: "UserEdge";
        node: { __typename: "User" } & UserFieldsFragment;
      } & UserEdgeFieldsFragment
    >;
  };
};

export type JoinGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type JoinGroupMutation = {
  joinGroup: { __typename: "Group" } & GroupFieldsFragment;
};

export type RequestJoinGroupMutationVariables = Exact<{
  joinableID: Scalars["ID"]["input"];
}>;

export type RequestJoinGroupMutation = {
  sendJoinRequest: { __typename: "JoinApproval"; id: string };
};

export type CancelRequestJoinGroupMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CancelRequestJoinGroupMutation = { cancelJoinApproval: boolean };

export type AddGroupMembersMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  members: Array<MemberRoleInput> | MemberRoleInput;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AddGroupMembersMutation = {
  addGroupMembers: { __typename: "Group" } & GroupFieldsFragment;
};

export type AddWorkspaceMembersMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  members: Array<MemberRoleInput> | MemberRoleInput;
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AddWorkspaceMembersMutation = {
  addWorkspaceMembers: { __typename: "Workspace" } & WorkspaceFieldsFragment;
};

export type NotInGroupQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type NotInGroupQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | ({ __typename: "Group"; id: string } & GroupSimpleFieldsFragment)
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | ({ __typename: "GroupPreview"; id: string } & GroupPreviewFieldsFragment)
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | ({ __typename: "Workspace"; id: string } & WorkspaceSimpleFieldsFragment)
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | ({
        __typename: "WorkspacePreview";
        id: string;
      } & WorkspacePreviewFieldsFragment)
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
  joinRequest: { __typename: "JoinApproval"; id: string } | null;
};

export type FetchDomainsQueryVariables = Exact<{
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchDomainsQuery = {
  domains: {
    __typename: "DomainConnection";
    edges: Array<{
      __typename: "DomainEdge";
      id: string;
      node: {
        __typename: "Domain";
        id: string;
        name: string;
        workspaces: {
          __typename: "WorkspaceOrPreviewConnection";
          edges: Array<
            | ({
                __typename: "WorkspaceEdge";
                node: { __typename: "Workspace" } & WorkspaceFieldsFragment;
              } & WorkspaceEdgeFieldsFragment)
            | {
                __typename: "WorkspacePreviewEdge";
                node: {
                  __typename: "WorkspacePreview";
                } & WorkspacePreviewFieldsFragment;
              }
          >;
        };
      };
    }>;
  };
};

export type JoinWorkspaceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type JoinWorkspaceMutation = {
  joinWorkspace: { __typename: "Workspace" } & WorkspaceFieldsFragment;
};

export type InvoiceFieldsFragment = {
  __typename: "Invoice";
  id: string;
  periodStart: string;
  periodEnd: string;
  description: string;
  total: number;
  currency: string;
  status: InvoiceStatus;
};

export type SubscriptionPlanFieldsFragment = {
  __typename: "SubscriptionPlan";
  id: string;
  name: string;
  description: string;
  interval: SubscriptionInterval;
  amount: number;
  currency: string;
};

export type WorkspaceSubscriptionFieldsFragment = {
  __typename: "WorkspaceSubscription";
  id: string;
  status: SubscriptionStatus;
  quantity: number;
  cancelAtPeriodEnd: boolean;
  currentPeriodStart: string | null;
  currentPeriodEnd: string | null;
  plan:
    | ({ __typename: "SubscriptionPlan" } & SubscriptionPlanFieldsFragment)
    | null;
  latestInvoice: ({ __typename: "Invoice" } & InvoiceFieldsFragment) | null;
  nextInvoice: ({ __typename: "Invoice" } & InvoiceFieldsFragment) | null;
};

export type UpdateWorkspaceSubscriptionMutationVariables = Exact<{
  input: WorkspaceSubscriptionInput;
  workspaceID: Scalars["ID"]["input"];
}>;

export type UpdateWorkspaceSubscriptionMutation = {
  updateWorkspaceSubscription: {
    __typename: "WorkspaceSubscription";
  } & WorkspaceSubscriptionFieldsFragment;
};

export type WorkspaceSubscriptionBillingQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type WorkspaceSubscriptionBillingQuery = {
  workspaceSubscriptionBilling: {
    __typename: "SubscriptionBillingSession";
    stripePortalURL: string;
  };
};

export type WorkspaceSubscriptionCheckoutQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
  input: WorkspaceSubscriptionInput;
}>;

export type WorkspaceSubscriptionCheckoutQuery = {
  workspaceSubscriptionCheckout: {
    __typename: "SubscriptionCheckoutSession";
    stripeClientSecret: string;
  };
};

export type WorkspaceSubscriptionPlansQueryVariables = Exact<{
  [key: string]: never;
}>;

export type WorkspaceSubscriptionPlansQuery = {
  workspaceSubscriptionPlans: Array<
    { __typename: "SubscriptionPlan" } & SubscriptionPlanFieldsFragment
  >;
};

export type WorkspaceSubscriptionQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type WorkspaceSubscriptionQuery = {
  workspaceSubscription: {
    __typename: "WorkspaceSubscription";
  } & WorkspaceSubscriptionFieldsFragment;
};

export type CreateWorkspaceMutationVariables = Exact<{
  input: CreateWorkspaceInput;
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateWorkspaceMutation = {
  createWorkspace: { __typename: "Workspace" } & WorkspaceFieldsFragment;
};

export type UpdateWorkspaceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdateWorkspaceInput;
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateWorkspaceMutation = {
  updateWorkspace: { __typename: "Workspace" } & WorkspaceFieldsFragment;
};

export type DeleteWorkspaceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteWorkspaceMutation = { deleteWorkspace: boolean };

export type CreateLlmapiKeyMutationVariables = Exact<{
  input: WorkspaceLlmapiKeyInput;
}>;

export type CreateLlmapiKeyMutation = {
  createLLMAPIKey: {
    __typename: "WorkspaceLLMAPIKey";
  } & WorkspaceLlmapiKeyFieldsFragment;
};

export type DeleteLlmapiKeyMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  workspaceID: Scalars["ID"]["input"];
}>;

export type DeleteLlmapiKeyMutation = { deleteLLMAPIKey: boolean };

export type CreateWorkspaceAppMutationVariables = Exact<{
  input: AppInput;
}>;

export type CreateWorkspaceAppMutation = {
  createWorkspaceApp: {
    __typename: "WorkspaceAppEdge";
  } & WorkspaceAppEdgeFieldsFragment;
};

export type RemoveWorkspaceAppMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RemoveWorkspaceAppMutation = { removeWorkspaceApp: boolean };

export type UpdateAppMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: AppInput;
}>;

export type UpdateAppMutation = {
  updateApp: { __typename: "App" } & AppFieldsFragment;
};

export type DeleteAppMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAppMutation = { deleteApp: boolean };

export type LeaveWorkspaceMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type LeaveWorkspaceMutation = { leaveWorkspace: boolean };

export type RemoveWorkspaceMembersMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  memberIDs: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RemoveWorkspaceMembersMutation = {
  removeWorkspaceMembers: { __typename: "Workspace" } & WorkspaceFieldsFragment;
};

export type RemindWorkspaceMembersMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: RemindWorkspaceMembersInput;
}>;

export type RemindWorkspaceMembersMutation = {
  remindWorkspaceMembers: boolean;
};

export type FetchWorkspaceQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchWorkspaceQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | ({ __typename: "Workspace"; id: string } & WorkspaceFieldsFragment)
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | ({
        __typename: "WorkspacePreview";
        id: string;
      } & WorkspacePreviewFieldsFragment)
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchWorkspaceOrPreviewEdgeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  groupsOrder?: InputMaybe<GroupsOrder>;
  groupsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  groupsAfter?: InputMaybe<Scalars["String"]["input"]>;
  membersLimit?: InputMaybe<Scalars["Int"]["input"]>;
  membersAfter?: InputMaybe<Scalars["String"]["input"]>;
  pendingApprovalsLimit?: InputMaybe<Scalars["Int"]["input"]>;
  pendingApprovalsAfter?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FetchWorkspaceOrPreviewEdgeQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | { __typename: "Workspace"; id: string }
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | ({
        __typename: "WorkspaceEdge";
        id: string;
        node: { __typename: "Workspace" } & WorkspaceFieldsFragment;
      } & WorkspaceEdgeFieldsFragment)
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | { __typename: "WorkspacePreview"; id: string }
    | {
        __typename: "WorkspacePreviewEdge";
        id: string;
        node: {
          __typename: "WorkspacePreview";
        } & WorkspacePreviewFieldsFragment;
      }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type FetchWorkspaceSettingsQueryVariables = Exact<{
  workspaceID: Scalars["ID"]["input"];
}>;

export type FetchWorkspaceSettingsQuery = {
  workspaceSettings: {
    __typename: "WorkspaceSettings";
  } & WorkspaceSettingsFieldsFragment;
};

export type WorkspacesAppsQueryVariables = Exact<{ [key: string]: never }>;

export type WorkspacesAppsQuery = {
  workspaces: {
    __typename: "WorkspaceConnection";
    edges: Array<
      {
        __typename: "WorkspaceEdge";
        node: {
          __typename: "Workspace";
          apps: {
            __typename: "WorkspaceAppConnection";
            edges: Array<
              {
                __typename: "WorkspaceAppEdge";
              } & WorkspaceAppEdgeFieldsFragment
            >;
          };
        } & WorkspaceSimpleFieldsFragment;
      } & WorkspaceEdgeSimpleFieldsFragment
    >;
  };
};

export type PinMessageMutationVariables = Exact<{
  messageID: Scalars["ID"]["input"];
}>;

export type PinMessageMutation = {
  pinMessage: { __typename: "MessagePin"; id: string };
};

export type UnpinMessageMutationVariables = Exact<{
  messageID: Scalars["ID"]["input"];
}>;

export type UnpinMessageMutation = { unpinMessage: boolean };

export type MarkThreadEdgesMutationVariables = Exact<{
  ids: Array<Scalars["ID"]["input"]> | Scalars["ID"]["input"];
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
}>;

export type MarkThreadEdgesMutation = {
  markThreadEdges: Array<
    { __typename: "ThreadEdge" } & ThreadEdgeFieldsFragment
  >;
};

export type MarkAllThreadEdgesMutationVariables = Exact<{
  filter?: InputMaybe<ThreadsFilter>;
  read?: InputMaybe<MarkThreadEdgeReadInput>;
  seen?: InputMaybe<MarkThreadEdgeSeenInput>;
  subscribed?: InputMaybe<MarkThreadEdgeSubscribedInput>;
  archived?: InputMaybe<MarkThreadEdgeArchivedInput>;
  reminder?: InputMaybe<MarkThreadEdgeReminderInput>;
  starred?: InputMaybe<MarkThreadEdgeStarredInput>;
}>;

export type MarkAllThreadEdgesMutation = { markAllThreadEdges: number };

export type FetchWorkspaceProfileQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchWorkspaceProfileQuery = {
  node:
    | { __typename: "Address"; id: string }
    | { __typename: "App"; id: string }
    | { __typename: "CustomEmoji"; id: string }
    | { __typename: "Device"; id: string }
    | { __typename: "Domain"; id: string }
    | { __typename: "DomainEdge"; id: string }
    | { __typename: "Draft"; id: string }
    | { __typename: "ExternalObject"; id: string }
    | { __typename: "File"; id: string }
    | { __typename: "Group"; id: string }
    | { __typename: "GroupEdge"; id: string }
    | { __typename: "GroupMemberEdge"; id: string }
    | { __typename: "GroupPreview"; id: string }
    | { __typename: "GroupPreviewEdge"; id: string }
    | { __typename: "JoinApproval"; id: string }
    | { __typename: "LLMModel"; id: string }
    | { __typename: "Lambda"; id: string }
    | { __typename: "LambdaSecret"; id: string }
    | { __typename: "Link"; id: string }
    | { __typename: "Message"; id: string }
    | { __typename: "MessageEdge"; id: string }
    | { __typename: "MessageMetadata"; id: string }
    | { __typename: "MessagePin"; id: string }
    | { __typename: "MessagePreview"; id: string }
    | { __typename: "Notification"; id: string }
    | { __typename: "Reaction"; id: string }
    | { __typename: "SearchResult"; id: string }
    | { __typename: "SlackImport"; id: string }
    | { __typename: "Thread"; id: string }
    | { __typename: "ThreadEdge"; id: string }
    | { __typename: "ThreadPreview"; id: string }
    | { __typename: "ThreadPreviewEdge"; id: string }
    | { __typename: "ThreadRecipientEdge"; id: string }
    | { __typename: "ThreadSummaryPoint"; id: string }
    | { __typename: "User"; id: string }
    | { __typename: "UserEdge"; id: string }
    | { __typename: "UserPreviewEdge"; id: string }
    | ({ __typename: "Workspace"; id: string } & WorkspaceSimpleFieldsFragment)
    | { __typename: "WorkspaceAppEdge"; id: string }
    | { __typename: "WorkspaceAppWebhook"; id: string }
    | { __typename: "WorkspaceEdge"; id: string }
    | { __typename: "WorkspaceInviteLink"; id: string }
    | { __typename: "WorkspaceMemberEdge"; id: string }
    | ({
        __typename: "WorkspacePreview";
        id: string;
      } & WorkspacePreviewSimpleFieldsFragment)
    | { __typename: "WorkspacePreviewEdge"; id: string }
    | { __typename: "WorkspaceSettings"; id: string }
    | { __typename: "WorkspaceSubscription"; id: string }
    | null;
};

export type CompleteMilestoneMutationVariables = Exact<{
  input: CompleteMilestone;
}>;

export type CompleteMilestoneMutation = { completeMilestone: boolean };

export type AuthConfigQueryVariables = Exact<{ [key: string]: never }>;

export type AuthConfigQuery = {
  me: { __typename: "User" } & UserFieldsFragment;
  addresses: {
    __typename: "AddressConnection";
    edges: Array<{
      __typename: "AddressEdge";
      node: { __typename: "Address"; id: string; address: string };
    }>;
  };
  config: { __typename: "Config"; streamToken: string };
  settings: { __typename: "UserSettings" } & SettingsFieldsFragment;
  workspaces: {
    __typename: "WorkspaceConnection";
    edges: Array<
      {
        __typename: "WorkspaceEdge";
        node: {
          __typename: "Workspace";
          members: {
            __typename: "WorkspaceMemberConnection";
            totalCount: number;
          };
          groups: {
            __typename: "GroupOrPreviewConnection";
            totalCount: number;
          };
        } & WorkspaceSimpleFieldsFragment;
      } & WorkspaceEdgeSimpleFieldsFragment
    >;
  };
};

export const UserFieldsFragmentDoc = gql`
  fragment UserFields on User {
    id
    name
    addressDomains
    avatarURL
    bio
    workspaceIDs
  }
`;
export const GroupPreviewCommonFieldsFragmentDoc = gql`
  fragment GroupPreviewCommonFields on GroupPreview {
    id
    name
    description
    workspaceID
    joinableBy
    commonWorkspaceIDs
  }
`;
export const GroupPreviewSimpleFieldsFragmentDoc = gql`
  fragment GroupPreviewSimpleFields on GroupPreview {
    ...GroupPreviewCommonFields
  }
  ${GroupPreviewCommonFieldsFragmentDoc}
`;
export const WorkspacePreviewCommonFieldsFragmentDoc = gql`
  fragment WorkspacePreviewCommonFields on WorkspacePreview {
    id
    name
    domains
    avatarURL
    joinableBy
  }
`;
export const WorkspacePreviewSimpleFieldsFragmentDoc = gql`
  fragment WorkspacePreviewSimpleFields on WorkspacePreview {
    ...WorkspacePreviewCommonFields
  }
  ${WorkspacePreviewCommonFieldsFragmentDoc}
`;
export const RecipientFieldsFragmentDoc = gql`
  fragment RecipientFields on Recipient {
    ... on User {
      ...UserFields
    }
    ... on GroupPreview {
      ...GroupPreviewSimpleFields
    }
    ... on WorkspacePreview {
      ...WorkspacePreviewSimpleFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupPreviewSimpleFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
`;
export const FileFieldsFragmentDoc = gql`
  fragment FileFields on File {
    id
    url
    name
    fileType
    contentType
    contentLength
    metadata {
      width
      height
      blurHash
    }
    previewable
  }
`;
export const ExternalObjectFieldsFragmentDoc = gql`
  fragment ExternalObjectFields on ExternalObject {
    id
    url
    objectType
    title
    description
    siteName
    layout
    icon {
      url
    }
    image {
      url
      blurHash
    }
    video {
      url
      contentType
    }
    audio {
      url
      contentType
    }
  }
`;
export const ThreadPreviewFieldsFragmentDoc = gql`
  fragment ThreadPreviewFields on ThreadPreview {
    id
    name
    subject
    admin {
      ...UserFields
    }
    recipients {
      edges {
        node {
          ...RecipientFields
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${RecipientFieldsFragmentDoc}
`;
export const QuotedMessageFieldsFragmentDoc = gql`
  fragment QuotedMessageFields on QuotedMessage {
    id
    streamID
    user {
      ...UserFields
    }
    text
    textPreview
    attachments {
      ... on File {
        ...FileFields
      }
      ... on ExternalObject {
        ...ExternalObjectFields
      }
      ... on ThreadPreview {
        ...ThreadPreviewFields
      }
    }
    createdAt
    updatedAt
  }
  ${UserFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${ExternalObjectFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;
export const ReactionsFieldsFragmentDoc = gql`
  fragment ReactionsFields on Reaction {
    id
    type
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const MessageFieldsFragmentDoc = gql`
  fragment MessageFields on Message {
    id
    user {
      ...UserFields
    }
    text
    textPreview
    threadID
    streamID
    quotedMessage {
      ...QuotedMessageFields
    }
    replyThreadID
    ownReactions {
      ...ReactionsFields
    }
    reactions {
      edges {
        node {
          ...ReactionsFields
        }
      }
    }
    reactionCounts {
      c
      r
    }
    attachments {
      ... on File {
        ...FileFields
      }
      ... on ExternalObject {
        ...ExternalObjectFields
      }
      ... on ThreadPreview {
        ...ThreadPreviewFields
      }
    }
    createdAt
    updatedAt
  }
  ${UserFieldsFragmentDoc}
  ${QuotedMessageFieldsFragmentDoc}
  ${ReactionsFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${ExternalObjectFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;
export const DraftFieldsFragmentDoc = gql`
  fragment DraftFields on Draft {
    id
    recipients {
      ...RecipientFields
    }
    recipientsAddable
    subject
    message {
      text
      textPreview
      attachments {
        ... on File {
          ...FileFields
        }
        ... on ExternalObject {
          ...ExternalObjectFields
        }
        ... on ThreadPreview {
          ...ThreadPreviewFields
        }
      }
    }
    replyToMessage {
      ...MessageFields
    }
    updatedAt
  }
  ${RecipientFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${ExternalObjectFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
`;
export const CustomEmojiFieldsFragmentDoc = gql`
  fragment CustomEmojiFields on CustomEmoji {
    id
    createdAt
    imageURL
    name
    workspaceID
    createdBy {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const UploadTicketFieldsFragmentDoc = gql`
  fragment UploadTicketFields on UploadTicket {
    id
    url
    headers {
      name
      value
    }
    formData {
      name
      value
    }
  }
`;
export const GroupCommonFieldsFragmentDoc = gql`
  fragment GroupCommonFields on Group {
    id
    admin {
      ...UserFields
    }
    name
    description
    workspaceID
    joinableBy
    commonWorkspaceIDs
  }
  ${UserFieldsFragmentDoc}
`;
export const GroupSimpleFieldsFragmentDoc = gql`
  fragment GroupSimpleFields on Group {
    ...GroupCommonFields
  }
  ${GroupCommonFieldsFragmentDoc}
`;
export const UnreadCountFieldsFragmentDoc = gql`
  fragment UnreadCountFields on UnreadCounts {
    mentioned
    total
    unseen
  }
`;
export const GroupEdgeCommonFieldsFragmentDoc = gql`
  fragment GroupEdgeCommonFields on GroupEdge {
    id
    cursor
    memberRole
    lastInteractionAt
    threadSubscription
    unreadThreadCounts {
      ...UnreadCountFields
    }
  }
  ${UnreadCountFieldsFragmentDoc}
`;
export const GroupEdgeSimpleFieldsFragmentDoc = gql`
  fragment GroupEdgeSimpleFields on GroupEdge {
    ...GroupEdgeCommonFields
  }
  ${GroupEdgeCommonFieldsFragmentDoc}
`;
export const ThreadCommonFieldsFragmentDoc = gql`
  fragment ThreadCommonFields on Thread {
    id
    subject
    isPersistentChat
    lastMessage {
      ...MessageFields
    }
    recipients {
      edges {
        node {
          ...RecipientFields
        }
      }
    }
    recipientsAddable
    createdAt
  }
  ${MessageFieldsFragmentDoc}
  ${RecipientFieldsFragmentDoc}
`;
export const ThreadSimpleFieldsFragmentDoc = gql`
  fragment ThreadSimpleFields on Thread {
    ...ThreadCommonFields
  }
  ${ThreadCommonFieldsFragmentDoc}
`;
export const ThreadEdgeFieldsFragmentDoc = gql`
  fragment ThreadEdgeFields on ThreadEdge {
    id
    cursor
    recipientRole
    isArchived
    remindAt
    isMentioned @client
    isStarred
    isRead
    isSeen
    lastReadID
    lastSeenID
    subscription
    unreadMessageCounts {
      ...UnreadCountFields
    }
  }
  ${UnreadCountFieldsFragmentDoc}
`;
export const GroupEdgeFieldsFragmentDoc = gql`
  fragment GroupEdgeFields on GroupEdge {
    ...GroupEdgeCommonFields
    persistentChatEdge {
      node {
        ...ThreadSimpleFields
      }
      ...ThreadEdgeFields
    }
  }
  ${GroupEdgeCommonFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
`;
export const InviteLinkFragmentDoc = gql`
  fragment InviteLink on WorkspaceInviteLink {
    id
    count
    enabled
    expiresAt
    maxCount
    workspaceID
  }
`;
export const LambdaFieldsFragmentDoc = gql`
  fragment LambdaFields on Lambda {
    id
    name
    description
    event
    source
    appId
    enabled
    activeVersion
  }
`;
export const LambdaSecretFieldsFragmentDoc = gql`
  fragment LambdaSecretFields on LambdaSecret {
    id
    name
  }
`;
export const MessagePreviewFieldsFragmentDoc = gql`
  fragment MessagePreviewFields on MessagePreview {
    id
    createdAt
    threadID
    user {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export const LinkFieldsFragmentDoc = gql`
  fragment LinkFields on Link {
    id
    linked {
      ... on User {
        ...UserFields
      }
      ... on ExternalObject {
        ...ExternalObjectFields
      }
      ... on File {
        ...FileFields
      }
    }
    message {
      ...MessagePreviewFields
    }
  }
  ${UserFieldsFragmentDoc}
  ${ExternalObjectFieldsFragmentDoc}
  ${FileFieldsFragmentDoc}
  ${MessagePreviewFieldsFragmentDoc}
`;
export const LinkConnectionFieldsFragmentDoc = gql`
  fragment LinkConnectionFields on LinkConnection {
    edges {
      node {
        ...LinkFields
      }
    }
    totalCount
    pageInfo {
      hasPreviousPage
      startCursor
    }
  }
  ${LinkFieldsFragmentDoc}
`;
export const LlmModelFieldsFragmentDoc = gql`
  fragment LLMModelFields on LLMModel {
    id
    name
    provider
    providerLogoURL
    providerName
  }
`;
export const MessageAiResponseInfoFieldsFragmentDoc = gql`
  fragment MessageAIResponseInfoFields on MessageAIResponseInfo {
    model {
      ...LLMModelFields
    }
    sourcesDescription
    toUserID
  }
  ${LlmModelFieldsFragmentDoc}
`;
export const MessageMetadataFieldsFragmentDoc = gql`
  fragment MessageMetadataFields on MessageMetadata {
    id
    messageID
    aiResponseInfo {
      ...MessageAIResponseInfoFields
    }
  }
  ${MessageAiResponseInfoFieldsFragmentDoc}
`;
export const NotificationEdgeFieldsFragmentDoc = gql`
  fragment NotificationEdgeFields on NotificationEdge {
    id
    isRead
    isSeen
  }
`;
export const GroupPreviewFieldsFragmentDoc = gql`
  fragment GroupPreviewFields on GroupPreview {
    ...GroupPreviewCommonFields
    admin {
      ...UserFields
    }
    members(first: $membersLimit, after: $membersAfter) {
      totalCount
      edges {
        node {
          ...UserFields
        }
      }
    }
  }
  ${GroupPreviewCommonFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const JoinApprovalCommonFieldsFragmentDoc = gql`
  fragment JoinApprovalCommonFields on JoinApproval {
    id
    adminApprovedAt
    joiningApprovedAt
    requestedAt
  }
`;
export const WorkspacePreviewFieldsFragmentDoc = gql`
  fragment WorkspacePreviewFields on WorkspacePreview {
    ...WorkspacePreviewCommonFields
    admin {
      ...UserFields
    }
    members(first: $membersLimit, after: $membersAfter) {
      totalCount
      edges {
        node {
          ...UserFields
        }
      }
    }
  }
  ${WorkspacePreviewCommonFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const JoinApprovalFieldsFragmentDoc = gql`
  fragment JoinApprovalFields on JoinApproval {
    ...JoinApprovalCommonFields
    admin {
      ...UserFields
    }
    joining {
      ...RecipientFields
    }
    joinable {
      ... on GroupPreview {
        ...GroupPreviewFields
      }
      ... on ThreadPreview {
        ...ThreadPreviewFields
      }
      ... on WorkspacePreview {
        ...WorkspacePreviewFields
      }
    }
    requester {
      ...UserFields
    }
  }
  ${JoinApprovalCommonFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${RecipientFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;
export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on Notification {
    id
    text
    activityCount
    actorCount
    verb
    activities {
      id
      time
      verb
      actor {
        ...UserFields
      }
      object {
        ... on User {
          ...UserFields
        }
        ... on Message {
          ...MessageFields
        }
        ... on GroupPreview {
          ...GroupPreviewFields
        }
        ... on ThreadPreview {
          ...ThreadPreviewFields
        }
        ... on JoinApproval {
          ...JoinApprovalFields
        }
        ... on Link {
          ...LinkFields
        }
      }
      target {
        ... on GroupPreview {
          ...GroupPreviewFields
        }
        ... on ThreadPreview {
          ...ThreadPreviewFields
        }
        ... on WorkspacePreview {
          ...WorkspacePreviewSimpleFields
        }
      }
      meta {
        ... on ActivityReactedToMessageMeta {
          reaction
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
  ${JoinApprovalFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
`;
export const SlackImportConfigFragmentDoc = gql`
  fragment SlackImportConfig on SlackImportConfig {
    groups
    includeChannels
    skipChannels
  }
`;
export const SlackImportProgressItemFragmentDoc = gql`
  fragment SlackImportProgressItem on SlackImportProgressItem {
    imported
    total
  }
`;
export const SlackImportProgressFragmentDoc = gql`
  fragment SlackImportProgress on SlackImportProgress {
    channels {
      ...SlackImportProgressItem
    }
    messages {
      ...SlackImportProgressItem
    }
    users {
      ...SlackImportProgressItem
    }
  }
  ${SlackImportProgressItemFragmentDoc}
`;
export const SlackImportFragmentDoc = gql`
  fragment SlackImport on SlackImport {
    id
    config {
      ...SlackImportConfig
    }
    progress {
      ...SlackImportProgress
    }
    notificationsThreadID
    status
  }
  ${SlackImportConfigFragmentDoc}
  ${SlackImportProgressFragmentDoc}
`;
export const AppFieldsFragmentDoc = gql`
  fragment AppFields on App {
    id
    name
    avatarURL
    configureGroup
    configureGroupPath
    configureThread
    configureThreadPath
    configureWorkspace
    configureWorkspacePath
    description
    docsURL
    homeURL
    instructions
    publishedAt
    threadActions {
      description
      label
      name
      path
    }
    workspace {
      id
      name
    }
  }
`;
export const WebhookFieldsFragmentDoc = gql`
  fragment WebhookFields on WorkspaceAppWebhook {
    id
    url
  }
`;
export const WorkspaceAppEdgeFieldsFragmentDoc = gql`
  fragment WorkspaceAppEdgeFields on WorkspaceAppEdge {
    id
    node {
      ...AppFields
    }
    webhooks {
      ...WebhookFields
    }
    creator {
      ...UserFields
    }
    appUser {
      ...UserFields
    }
    workspaceID
  }
  ${AppFieldsFragmentDoc}
  ${WebhookFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export const ThreadFieldsFragmentDoc = gql`
  fragment ThreadFields on Thread {
    ...ThreadCommonFields
    replyToMessage {
      ...MessageFields
    }
    firstMessage {
      ...MessageFields
    }
    recentMessages: messages(last: 1) {
      replyCount
    }
    recentMessagesUsers {
      ...UserFields
    }
    users(last: 2) {
      totalCount
      edges {
        node {
          ...UserFields
        }
      }
    }
    apps {
      edges {
        ...WorkspaceAppEdgeFields
      }
    }
    workspaceID
  }
  ${ThreadCommonFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${WorkspaceAppEdgeFieldsFragmentDoc}
`;
export const MessageEdgeFieldsFragmentDoc = gql`
  fragment MessageEdgeFields on MessageEdge {
    id
    cursor
  }
`;
export const ThreadInFeedFieldsFragmentDoc = gql`
  fragment ThreadInFeedFields on Thread {
    ...ThreadFields
    recentMessages: messages(last: 1) {
      replyCount
      edges {
        ...MessageEdgeFields
        node {
          ...MessageFields
        }
      }
    }
    lastRead: messages(last: $lastReadLimit, before: $beforeLastRead)
      @include(if: $includeLastRead) {
      edges {
        ...MessageEdgeFields
        node {
          ...MessageFields
        }
      }
    }
    aroundLastRead: messages(aroundLastRead: 2) {
      edges {
        ...MessageEdgeFields
        node {
          ...MessageFields
        }
      }
    }
    lastUnread: messages(last: $lastUnreadLimit, before: $beforeLastUnread) {
      edges {
        ...MessageEdgeFields
        node {
          ...MessageFields
        }
      }
    }
  }
  ${ThreadFieldsFragmentDoc}
  ${MessageEdgeFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
`;
export const ThreadEdgeSimpleAndNodeFieldsFragmentDoc = gql`
  fragment ThreadEdgeSimpleAndNodeFields on ThreadEdge {
    ...ThreadEdgeFields
    node {
      ...ThreadSimpleFields
    }
  }
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
`;
export const ThreadMetadataFieldsFragmentDoc = gql`
  fragment ThreadMetadataFields on ThreadMetadata {
    id
    threadID
    aiSettings {
      chatModel
    }
  }
`;
export const ThreadSummaryPointFieldsFragmentDoc = gql`
  fragment ThreadSummaryPointFields on ThreadSummaryPoint {
    id
    point
    threadID
  }
`;
export const UserEdgeCommonFieldsFragmentDoc = gql`
  fragment UserEdgeCommonFields on UserEdge {
    id
    status
    lastInteractionAt
    unreadThreadCounts {
      ...UnreadCountFields
    }
  }
  ${UnreadCountFieldsFragmentDoc}
`;
export const UserEdgeFieldsFragmentDoc = gql`
  fragment UserEdgeFields on UserEdge {
    ...UserEdgeCommonFields
    persistentChatEdge {
      node {
        ...ThreadSimpleFields
      }
      ...ThreadEdgeFields
    }
  }
  ${UserEdgeCommonFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
`;
export const SettingsFieldsFragmentDoc = gql`
  fragment SettingsFields on UserSettings {
    notifyMessages
    llmSettings {
      chatModel
    }
  }
`;
export const WorkspaceCommonFieldsFragmentDoc = gql`
  fragment WorkspaceCommonFields on Workspace {
    id
    admin {
      ...UserFields
    }
    name
    domains
    avatarURL
  }
  ${UserFieldsFragmentDoc}
`;
export const WorkspaceSimpleFieldsFragmentDoc = gql`
  fragment WorkspaceSimpleFields on Workspace {
    ...WorkspaceCommonFields
  }
  ${WorkspaceCommonFieldsFragmentDoc}
`;
export const JoinApprovalSimpleFieldsFragmentDoc = gql`
  fragment JoinApprovalSimpleFields on JoinApproval {
    ...JoinApprovalCommonFields
    admin {
      ...UserFields
    }
    joining {
      ...RecipientFields
    }
    requester {
      ...UserFields
    }
  }
  ${JoinApprovalCommonFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${RecipientFieldsFragmentDoc}
`;
export const GroupFieldsFragmentDoc = gql`
  fragment GroupFields on Group {
    ...GroupCommonFields
    members(first: $membersLimit, after: $membersAfter) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        memberRole
        node {
          ...UserFields
        }
      }
    }
    pendingApprovals(
      first: $pendingApprovalsLimit
      after: $pendingApprovalsAfter
    ) {
      totalCount
      edges {
        node {
          ...JoinApprovalSimpleFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
    apps {
      edges {
        ...WorkspaceAppEdgeFields
      }
    }
  }
  ${GroupCommonFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${JoinApprovalSimpleFieldsFragmentDoc}
  ${WorkspaceAppEdgeFieldsFragmentDoc}
`;
export const WorkspaceFieldsFragmentDoc = gql`
  fragment WorkspaceFields on Workspace {
    ...WorkspaceCommonFields
    apps {
      edges {
        ...WorkspaceAppEdgeFields
      }
    }
    groups(first: $groupsLimit, after: $groupsAfter, order: $groupsOrder) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          ...GroupFields
          ...GroupPreviewFields
        }
      }
    }
    members(first: $membersLimit, after: $membersAfter) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        memberRole
        pending
        node {
          ...UserFields
        }
      }
    }
    pendingApprovals(
      first: $pendingApprovalsLimit
      after: $pendingApprovalsAfter
    ) {
      totalCount
      edges {
        cursor
        node {
          ...JoinApprovalSimpleFields
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${WorkspaceCommonFieldsFragmentDoc}
  ${WorkspaceAppEdgeFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${JoinApprovalSimpleFieldsFragmentDoc}
`;
export const WorkspaceEdgeCommonFieldsFragmentDoc = gql`
  fragment WorkspaceEdgeCommonFields on WorkspaceEdge {
    id
    cursor
    memberRole
    unreadThreadCounts {
      ...UnreadCountFields
    }
    unreadGroupThreadCounts {
      ...UnreadCountFields
    }
  }
  ${UnreadCountFieldsFragmentDoc}
`;
export const WorkspaceEdgeSimpleFieldsFragmentDoc = gql`
  fragment WorkspaceEdgeSimpleFields on WorkspaceEdge {
    ...WorkspaceEdgeCommonFields
  }
  ${WorkspaceEdgeCommonFieldsFragmentDoc}
`;
export const WorkspaceEdgeFieldsFragmentDoc = gql`
  fragment WorkspaceEdgeFields on WorkspaceEdge {
    ...WorkspaceEdgeCommonFields
    persistentChatEdge {
      node {
        ...ThreadSimpleFields
      }
      ...ThreadEdgeFields
    }
  }
  ${WorkspaceEdgeCommonFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
`;
export const WorkspaceLlmapiKeyFieldsFragmentDoc = gql`
  fragment WorkspaceLLMAPIKeyFields on WorkspaceLLMAPIKey {
    id
    keyPreview
    llmProvider
    userID
  }
`;
export const WorkspaceSettingsFieldsFragmentDoc = gql`
  fragment WorkspaceSettingsFields on WorkspaceSettings {
    id
    llmSettings {
      llmAPIKeys {
        ...WorkspaceLLMAPIKeyFields
      }
    }
  }
  ${WorkspaceLlmapiKeyFieldsFragmentDoc}
`;
export const LlmModelFragmentDoc = gql`
  fragment LLMModel on LLMModel {
    id
    name
    providerLogoURL
    provider
    providerName
  }
`;
export const SubscriptionPlanFieldsFragmentDoc = gql`
  fragment SubscriptionPlanFields on SubscriptionPlan {
    id
    name
    description
    interval
    amount
    currency
  }
`;
export const InvoiceFieldsFragmentDoc = gql`
  fragment InvoiceFields on Invoice {
    id
    periodStart
    periodEnd
    description
    total
    currency
    status
  }
`;
export const WorkspaceSubscriptionFieldsFragmentDoc = gql`
  fragment WorkspaceSubscriptionFields on WorkspaceSubscription {
    id
    plan {
      ...SubscriptionPlanFields
    }
    status
    quantity
    cancelAtPeriodEnd
    currentPeriodStart
    currentPeriodEnd
    latestInvoice {
      ...InvoiceFields
    }
    nextInvoice {
      ...InvoiceFields
    }
  }
  ${SubscriptionPlanFieldsFragmentDoc}
  ${InvoiceFieldsFragmentDoc}
`;
export const RegisterDeviceDocument = gql`
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      id
    }
  }
`;
export type RegisterDeviceMutationFn = Apollo.MutationFunction<
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables
>;

/**
 * __useRegisterDeviceMutation__
 *
 * To run a mutation, you first call `useRegisterDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDeviceMutation, { data, loading, error }] = useRegisterDeviceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterDeviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterDeviceMutation,
    RegisterDeviceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterDeviceMutation,
    RegisterDeviceMutationVariables
  >(RegisterDeviceDocument, options);
}
export type RegisterDeviceMutationHookResult = ReturnType<
  typeof useRegisterDeviceMutation
>;
export type RegisterDeviceMutationResult =
  Apollo.MutationResult<RegisterDeviceMutation>;
export type RegisterDeviceMutationOptions = Apollo.BaseMutationOptions<
  RegisterDeviceMutation,
  RegisterDeviceMutationVariables
>;
export const ThreadSummaryDocument = gql`
  query ThreadSummary($threadID: ID!, $level: Int!, $afterMessageID: ID) {
    threadSummaryPoints(
      filter: {
        threadID: $threadID
        level: $level
        afterMessageID: $afterMessageID
      }
    ) {
      edges {
        node {
          ...ThreadSummaryPointFields
        }
      }
    }
  }
  ${ThreadSummaryPointFieldsFragmentDoc}
`;

/**
 * __useThreadSummaryQuery__
 *
 * To run a query within a React component, call `useThreadSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadSummaryQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *      level: // value for 'level'
 *      afterMessageID: // value for 'afterMessageID'
 *   },
 * });
 */
export function useThreadSummaryQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadSummaryQuery,
    ThreadSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadSummaryQuery, ThreadSummaryQueryVariables>(
    ThreadSummaryDocument,
    options
  );
}
export function useThreadSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadSummaryQuery,
    ThreadSummaryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadSummaryQuery, ThreadSummaryQueryVariables>(
    ThreadSummaryDocument,
    options
  );
}
export type ThreadSummaryQueryHookResult = ReturnType<
  typeof useThreadSummaryQuery
>;
export type ThreadSummaryLazyQueryHookResult = ReturnType<
  typeof useThreadSummaryLazyQuery
>;
export type ThreadSummaryQueryResult = Apollo.QueryResult<
  ThreadSummaryQuery,
  ThreadSummaryQueryVariables
>;
export const ConnectWithUsersDocument = gql`
  mutation ConnectWithUsers($users: [String!]!) {
    connectWithUsers(users: $users) {
      edges {
        ...UserEdgeFields
        node {
          ...UserFields
        }
      }
    }
  }
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type ConnectWithUsersMutationFn = Apollo.MutationFunction<
  ConnectWithUsersMutation,
  ConnectWithUsersMutationVariables
>;

/**
 * __useConnectWithUsersMutation__
 *
 * To run a mutation, you first call `useConnectWithUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectWithUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectWithUsersMutation, { data, loading, error }] = useConnectWithUsersMutation({
 *   variables: {
 *      users: // value for 'users'
 *   },
 * });
 */
export function useConnectWithUsersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectWithUsersMutation,
    ConnectWithUsersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectWithUsersMutation,
    ConnectWithUsersMutationVariables
  >(ConnectWithUsersDocument, options);
}
export type ConnectWithUsersMutationHookResult = ReturnType<
  typeof useConnectWithUsersMutation
>;
export type ConnectWithUsersMutationResult =
  Apollo.MutationResult<ConnectWithUsersMutation>;
export type ConnectWithUsersMutationOptions = Apollo.BaseMutationOptions<
  ConnectWithUsersMutation,
  ConnectWithUsersMutationVariables
>;
export const UpdateWorkspaceInviteLinkDocument = gql`
  mutation UpdateWorkspaceInviteLink($input: UpdateWorkspaceInviteLinkInput!) {
    updateWorkspaceInviteLink(input: $input) {
      ...InviteLink
    }
  }
  ${InviteLinkFragmentDoc}
`;
export type UpdateWorkspaceInviteLinkMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceInviteLinkMutation,
  UpdateWorkspaceInviteLinkMutationVariables
>;

/**
 * __useUpdateWorkspaceInviteLinkMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceInviteLinkMutation, { data, loading, error }] = useUpdateWorkspaceInviteLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkspaceInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceInviteLinkMutation,
    UpdateWorkspaceInviteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceInviteLinkMutation,
    UpdateWorkspaceInviteLinkMutationVariables
  >(UpdateWorkspaceInviteLinkDocument, options);
}
export type UpdateWorkspaceInviteLinkMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceInviteLinkMutation
>;
export type UpdateWorkspaceInviteLinkMutationResult =
  Apollo.MutationResult<UpdateWorkspaceInviteLinkMutation>;
export type UpdateWorkspaceInviteLinkMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWorkspaceInviteLinkMutation,
    UpdateWorkspaceInviteLinkMutationVariables
  >;
export const ResetWorkspaceInviteLinkDocument = gql`
  mutation ResetWorkspaceInviteLink($workspaceID: ID!) {
    resetWorkspaceInviteLink(workspaceID: $workspaceID)
  }
`;
export type ResetWorkspaceInviteLinkMutationFn = Apollo.MutationFunction<
  ResetWorkspaceInviteLinkMutation,
  ResetWorkspaceInviteLinkMutationVariables
>;

/**
 * __useResetWorkspaceInviteLinkMutation__
 *
 * To run a mutation, you first call `useResetWorkspaceInviteLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetWorkspaceInviteLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetWorkspaceInviteLinkMutation, { data, loading, error }] = useResetWorkspaceInviteLinkMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useResetWorkspaceInviteLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetWorkspaceInviteLinkMutation,
    ResetWorkspaceInviteLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetWorkspaceInviteLinkMutation,
    ResetWorkspaceInviteLinkMutationVariables
  >(ResetWorkspaceInviteLinkDocument, options);
}
export type ResetWorkspaceInviteLinkMutationHookResult = ReturnType<
  typeof useResetWorkspaceInviteLinkMutation
>;
export type ResetWorkspaceInviteLinkMutationResult =
  Apollo.MutationResult<ResetWorkspaceInviteLinkMutation>;
export type ResetWorkspaceInviteLinkMutationOptions =
  Apollo.BaseMutationOptions<
    ResetWorkspaceInviteLinkMutation,
    ResetWorkspaceInviteLinkMutationVariables
  >;
export const WorkspaceInviteLinkDocument = gql`
  query WorkspaceInviteLink($workspaceID: ID!) {
    workspaceInviteLink(workspaceID: $workspaceID) {
      ...InviteLink
    }
  }
  ${InviteLinkFragmentDoc}
`;

/**
 * __useWorkspaceInviteLinkQuery__
 *
 * To run a query within a React component, call `useWorkspaceInviteLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInviteLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInviteLinkQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useWorkspaceInviteLinkQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceInviteLinkQuery,
    WorkspaceInviteLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInviteLinkQuery,
    WorkspaceInviteLinkQueryVariables
  >(WorkspaceInviteLinkDocument, options);
}
export function useWorkspaceInviteLinkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInviteLinkQuery,
    WorkspaceInviteLinkQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInviteLinkQuery,
    WorkspaceInviteLinkQueryVariables
  >(WorkspaceInviteLinkDocument, options);
}
export type WorkspaceInviteLinkQueryHookResult = ReturnType<
  typeof useWorkspaceInviteLinkQuery
>;
export type WorkspaceInviteLinkLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInviteLinkLazyQuery
>;
export type WorkspaceInviteLinkQueryResult = Apollo.QueryResult<
  WorkspaceInviteLinkQuery,
  WorkspaceInviteLinkQueryVariables
>;
export const WorkspaceInviteLinkSignedDocument = gql`
  query WorkspaceInviteLinkSigned($workspaceID: ID!) {
    workspaceInviteLinkSigned(workspaceID: $workspaceID)
  }
`;

/**
 * __useWorkspaceInviteLinkSignedQuery__
 *
 * To run a query within a React component, call `useWorkspaceInviteLinkSignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceInviteLinkSignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceInviteLinkSignedQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useWorkspaceInviteLinkSignedQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceInviteLinkSignedQuery,
    WorkspaceInviteLinkSignedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceInviteLinkSignedQuery,
    WorkspaceInviteLinkSignedQueryVariables
  >(WorkspaceInviteLinkSignedDocument, options);
}
export function useWorkspaceInviteLinkSignedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceInviteLinkSignedQuery,
    WorkspaceInviteLinkSignedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceInviteLinkSignedQuery,
    WorkspaceInviteLinkSignedQueryVariables
  >(WorkspaceInviteLinkSignedDocument, options);
}
export type WorkspaceInviteLinkSignedQueryHookResult = ReturnType<
  typeof useWorkspaceInviteLinkSignedQuery
>;
export type WorkspaceInviteLinkSignedLazyQueryHookResult = ReturnType<
  typeof useWorkspaceInviteLinkSignedLazyQuery
>;
export type WorkspaceInviteLinkSignedQueryResult = Apollo.QueryResult<
  WorkspaceInviteLinkSignedQuery,
  WorkspaceInviteLinkSignedQueryVariables
>;
export const MasonryRequestDocument = gql`
  mutation MasonryRequest($input: MasonryRequestInput!) {
    masonryRequest(input: $input) {
      responseType
      context
      data
      actions
    }
  }
`;
export type MasonryRequestMutationFn = Apollo.MutationFunction<
  MasonryRequestMutation,
  MasonryRequestMutationVariables
>;

/**
 * __useMasonryRequestMutation__
 *
 * To run a mutation, you first call `useMasonryRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasonryRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masonryRequestMutation, { data, loading, error }] = useMasonryRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMasonryRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MasonryRequestMutation,
    MasonryRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MasonryRequestMutation,
    MasonryRequestMutationVariables
  >(MasonryRequestDocument, options);
}
export type MasonryRequestMutationHookResult = ReturnType<
  typeof useMasonryRequestMutation
>;
export type MasonryRequestMutationResult =
  Apollo.MutationResult<MasonryRequestMutation>;
export type MasonryRequestMutationOptions = Apollo.BaseMutationOptions<
  MasonryRequestMutation,
  MasonryRequestMutationVariables
>;
export const MasonrySessionDocument = gql`
  mutation MasonrySession($input: MasonrySessionOriginationInput!) {
    masonrySession(input: $input) {
      origination
      context
    }
  }
`;
export type MasonrySessionMutationFn = Apollo.MutationFunction<
  MasonrySessionMutation,
  MasonrySessionMutationVariables
>;

/**
 * __useMasonrySessionMutation__
 *
 * To run a mutation, you first call `useMasonrySessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMasonrySessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [masonrySessionMutation, { data, loading, error }] = useMasonrySessionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMasonrySessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MasonrySessionMutation,
    MasonrySessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MasonrySessionMutation,
    MasonrySessionMutationVariables
  >(MasonrySessionDocument, options);
}
export type MasonrySessionMutationHookResult = ReturnType<
  typeof useMasonrySessionMutation
>;
export type MasonrySessionMutationResult =
  Apollo.MutationResult<MasonrySessionMutation>;
export type MasonrySessionMutationOptions = Apollo.BaseMutationOptions<
  MasonrySessionMutation,
  MasonrySessionMutationVariables
>;
export const AuthorizeWorkspaceAppDocument = gql`
  mutation AuthorizeWorkspaceApp($input: AuthorizeWorkspaceAppInput!) {
    authorizeWorkspaceApp(input: $input) {
      authorizationCode
    }
  }
`;
export type AuthorizeWorkspaceAppMutationFn = Apollo.MutationFunction<
  AuthorizeWorkspaceAppMutation,
  AuthorizeWorkspaceAppMutationVariables
>;

/**
 * __useAuthorizeWorkspaceAppMutation__
 *
 * To run a mutation, you first call `useAuthorizeWorkspaceAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeWorkspaceAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeWorkspaceAppMutation, { data, loading, error }] = useAuthorizeWorkspaceAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAuthorizeWorkspaceAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthorizeWorkspaceAppMutation,
    AuthorizeWorkspaceAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthorizeWorkspaceAppMutation,
    AuthorizeWorkspaceAppMutationVariables
  >(AuthorizeWorkspaceAppDocument, options);
}
export type AuthorizeWorkspaceAppMutationHookResult = ReturnType<
  typeof useAuthorizeWorkspaceAppMutation
>;
export type AuthorizeWorkspaceAppMutationResult =
  Apollo.MutationResult<AuthorizeWorkspaceAppMutation>;
export type AuthorizeWorkspaceAppMutationOptions = Apollo.BaseMutationOptions<
  AuthorizeWorkspaceAppMutation,
  AuthorizeWorkspaceAppMutationVariables
>;
export const FetchAppDocument = gql`
  query FetchApp($id: ID!) {
    node(id: $id) {
      id
      ... on App {
        ...AppFields
      }
    }
  }
  ${AppFieldsFragmentDoc}
`;

/**
 * __useFetchAppQuery__
 *
 * To run a query within a React component, call `useFetchAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchAppQuery(
  baseOptions: Apollo.QueryHookOptions<FetchAppQuery, FetchAppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAppQuery, FetchAppQueryVariables>(
    FetchAppDocument,
    options
  );
}
export function useFetchAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAppQuery,
    FetchAppQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAppQuery, FetchAppQueryVariables>(
    FetchAppDocument,
    options
  );
}
export type FetchAppQueryHookResult = ReturnType<typeof useFetchAppQuery>;
export type FetchAppLazyQueryHookResult = ReturnType<
  typeof useFetchAppLazyQuery
>;
export type FetchAppQueryResult = Apollo.QueryResult<
  FetchAppQuery,
  FetchAppQueryVariables
>;
export const UploadTicketDocument = gql`
  mutation UploadTicket($input: UploadTicketInput!) {
    uploadTicket(input: $input) {
      ... on File {
        ...FileFields
      }
      ... on UploadTicket {
        ...UploadTicketFields
      }
    }
  }
  ${FileFieldsFragmentDoc}
  ${UploadTicketFieldsFragmentDoc}
`;
export type UploadTicketMutationFn = Apollo.MutationFunction<
  UploadTicketMutation,
  UploadTicketMutationVariables
>;

/**
 * __useUploadTicketMutation__
 *
 * To run a mutation, you first call `useUploadTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTicketMutation, { data, loading, error }] = useUploadTicketMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadTicketMutation,
    UploadTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadTicketMutation,
    UploadTicketMutationVariables
  >(UploadTicketDocument, options);
}
export type UploadTicketMutationHookResult = ReturnType<
  typeof useUploadTicketMutation
>;
export type UploadTicketMutationResult =
  Apollo.MutationResult<UploadTicketMutation>;
export type UploadTicketMutationOptions = Apollo.BaseMutationOptions<
  UploadTicketMutation,
  UploadTicketMutationVariables
>;
export const CreateFileDocument = gql`
  mutation CreateFile($uploadTicketID: String!) {
    createFile(uploadTicketID: $uploadTicketID) {
      ...FileFields
    }
  }
  ${FileFieldsFragmentDoc}
`;
export type CreateFileMutationFn = Apollo.MutationFunction<
  CreateFileMutation,
  CreateFileMutationVariables
>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      uploadTicketID: // value for 'uploadTicketID'
 *   },
 * });
 */
export function useCreateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFileMutation,
    CreateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument,
    options
  );
}
export type CreateFileMutationHookResult = ReturnType<
  typeof useCreateFileMutation
>;
export type CreateFileMutationResult =
  Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFileMutation,
  CreateFileMutationVariables
>;
export const UnfurlUrlDocument = gql`
  mutation UnfurlURL($input: UnfurlURLInput!) {
    unfurlURL(input: $input) {
      externalObject {
        ...ExternalObjectFields
      }
      appUnfurlSetup {
        path
        message
        workspaceAppID
        url
        appUser {
          ...UserFields
        }
      }
    }
  }
  ${ExternalObjectFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type UnfurlUrlMutationFn = Apollo.MutationFunction<
  UnfurlUrlMutation,
  UnfurlUrlMutationVariables
>;

/**
 * __useUnfurlUrlMutation__
 *
 * To run a mutation, you first call `useUnfurlUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfurlUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfurlUrlMutation, { data, loading, error }] = useUnfurlUrlMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnfurlUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnfurlUrlMutation,
    UnfurlUrlMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnfurlUrlMutation, UnfurlUrlMutationVariables>(
    UnfurlUrlDocument,
    options
  );
}
export type UnfurlUrlMutationHookResult = ReturnType<
  typeof useUnfurlUrlMutation
>;
export type UnfurlUrlMutationResult = Apollo.MutationResult<UnfurlUrlMutation>;
export type UnfurlUrlMutationOptions = Apollo.BaseMutationOptions<
  UnfurlUrlMutation,
  UnfurlUrlMutationVariables
>;
export const PerformThreadActionDocument = gql`
  mutation PerformThreadAction($input: ThreadActionInput!) {
    performThreadAction(input: $input) {
      success
    }
  }
`;
export type PerformThreadActionMutationFn = Apollo.MutationFunction<
  PerformThreadActionMutation,
  PerformThreadActionMutationVariables
>;

/**
 * __usePerformThreadActionMutation__
 *
 * To run a mutation, you first call `usePerformThreadActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePerformThreadActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [performThreadActionMutation, { data, loading, error }] = usePerformThreadActionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePerformThreadActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PerformThreadActionMutation,
    PerformThreadActionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PerformThreadActionMutation,
    PerformThreadActionMutationVariables
  >(PerformThreadActionDocument, options);
}
export type PerformThreadActionMutationHookResult = ReturnType<
  typeof usePerformThreadActionMutation
>;
export type PerformThreadActionMutationResult =
  Apollo.MutationResult<PerformThreadActionMutation>;
export type PerformThreadActionMutationOptions = Apollo.BaseMutationOptions<
  PerformThreadActionMutation,
  PerformThreadActionMutationVariables
>;
export const FetchRecipientDocument = gql`
  query FetchRecipient($id: ID!) {
    node(id: $id) {
      ... on User {
        ...UserFields
      }
      ... on Group {
        ...GroupSimpleFields
      }
      ... on GroupPreview {
        ...GroupPreviewSimpleFields
      }
      ... on ThreadEdge {
        ...ThreadEdgeFields
        node {
          ...ThreadSimpleFields
        }
      }
      ... on ThreadPreviewEdge {
        node {
          ...ThreadPreviewFields
        }
      }
      ... on Workspace {
        ...WorkspaceSimpleFields
      }
      ... on WorkspacePreview {
        ...WorkspacePreviewSimpleFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
  ${GroupPreviewSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
`;

/**
 * __useFetchRecipientQuery__
 *
 * To run a query within a React component, call `useFetchRecipientQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRecipientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRecipientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchRecipientQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchRecipientQuery,
    FetchRecipientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchRecipientQuery, FetchRecipientQueryVariables>(
    FetchRecipientDocument,
    options
  );
}
export function useFetchRecipientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchRecipientQuery,
    FetchRecipientQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchRecipientQuery, FetchRecipientQueryVariables>(
    FetchRecipientDocument,
    options
  );
}
export type FetchRecipientQueryHookResult = ReturnType<
  typeof useFetchRecipientQuery
>;
export type FetchRecipientLazyQueryHookResult = ReturnType<
  typeof useFetchRecipientLazyQuery
>;
export type FetchRecipientQueryResult = Apollo.QueryResult<
  FetchRecipientQuery,
  FetchRecipientQueryVariables
>;
export const NotificationCountsDocument = gql`
  query NotificationCounts {
    notifications(filter: { feedType: all }) {
      totalCount
      unreadCount
      unseenCount
    }
    joinApprovals {
      totalCount
    }
  }
`;

/**
 * __useNotificationCountsQuery__
 *
 * To run a query within a React component, call `useNotificationCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationCountsQuery,
    NotificationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NotificationCountsQuery,
    NotificationCountsQueryVariables
  >(NotificationCountsDocument, options);
}
export function useNotificationCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationCountsQuery,
    NotificationCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NotificationCountsQuery,
    NotificationCountsQueryVariables
  >(NotificationCountsDocument, options);
}
export type NotificationCountsQueryHookResult = ReturnType<
  typeof useNotificationCountsQuery
>;
export type NotificationCountsLazyQueryHookResult = ReturnType<
  typeof useNotificationCountsLazyQuery
>;
export type NotificationCountsQueryResult = Apollo.QueryResult<
  NotificationCountsQuery,
  NotificationCountsQueryVariables
>;
export const MailboxCountsDocument = gql`
  query MailboxCounts {
    mentionedThreads: threads(filter: { mailbox: mentioned }) {
      totalCount
    }
    unreadInbox: threads(filter: { mailbox: unread }) {
      totalCount
    }
    unseenFeed: threads(
      filter: { mailbox: unseen, excludePersistentChats: true }
    ) {
      totalCount
    }
  }
`;

/**
 * __useMailboxCountsQuery__
 *
 * To run a query within a React component, call `useMailboxCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMailboxCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMailboxCountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMailboxCountsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MailboxCountsQuery,
    MailboxCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MailboxCountsQuery, MailboxCountsQueryVariables>(
    MailboxCountsDocument,
    options
  );
}
export function useMailboxCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MailboxCountsQuery,
    MailboxCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MailboxCountsQuery, MailboxCountsQueryVariables>(
    MailboxCountsDocument,
    options
  );
}
export type MailboxCountsQueryHookResult = ReturnType<
  typeof useMailboxCountsQuery
>;
export type MailboxCountsLazyQueryHookResult = ReturnType<
  typeof useMailboxCountsLazyQuery
>;
export type MailboxCountsQueryResult = Apollo.QueryResult<
  MailboxCountsQuery,
  MailboxCountsQueryVariables
>;
export const OldWorkspacesAndGroupsListDocument = gql`
  query OldWorkspacesAndGroupsList(
    $recentGroupsLimit: Int = 9
    $groupsLimit: Int = 100
    $groupsAfter: String
  ) {
    groupsByWorkspace {
      edges {
        node {
          id
          workspaceEdge {
            ...WorkspaceEdgeSimpleFields
            ... on WorkspaceEdge {
              node {
                ...WorkspaceSimpleFields
              }
            }
            ... on WorkspacePreviewEdge {
              node {
                ...WorkspacePreviewSimpleFields
              }
            }
          }
          unreadGroups: groups(
            filter: { unreadThreads: true }
            last: $groupsLimit
          ) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
          recentGroups: groups(last: $recentGroupsLimit, order: interaction) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
          otherGroups: groups(
            filter: { unreadThreads: false }
            first: $groupsLimit
            after: $groupsAfter
            order: name
          ) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
        }
      }
    }
  }
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
  ${GroupEdgeSimpleFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
`;

/**
 * __useOldWorkspacesAndGroupsListQuery__
 *
 * To run a query within a React component, call `useOldWorkspacesAndGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOldWorkspacesAndGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOldWorkspacesAndGroupsListQuery({
 *   variables: {
 *      recentGroupsLimit: // value for 'recentGroupsLimit'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *   },
 * });
 */
export function useOldWorkspacesAndGroupsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OldWorkspacesAndGroupsListQuery,
    OldWorkspacesAndGroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OldWorkspacesAndGroupsListQuery,
    OldWorkspacesAndGroupsListQueryVariables
  >(OldWorkspacesAndGroupsListDocument, options);
}
export function useOldWorkspacesAndGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OldWorkspacesAndGroupsListQuery,
    OldWorkspacesAndGroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OldWorkspacesAndGroupsListQuery,
    OldWorkspacesAndGroupsListQueryVariables
  >(OldWorkspacesAndGroupsListDocument, options);
}
export type OldWorkspacesAndGroupsListQueryHookResult = ReturnType<
  typeof useOldWorkspacesAndGroupsListQuery
>;
export type OldWorkspacesAndGroupsListLazyQueryHookResult = ReturnType<
  typeof useOldWorkspacesAndGroupsListLazyQuery
>;
export type OldWorkspacesAndGroupsListQueryResult = Apollo.QueryResult<
  OldWorkspacesAndGroupsListQuery,
  OldWorkspacesAndGroupsListQueryVariables
>;
export const WorkspacesAndGroupsListDocument = gql`
  query WorkspacesAndGroupsList(
    $recentGroupsLimit: Int = 14
    $groupsLimit: Int = 100
    $groupsAfter: String
  ) {
    groupsByWorkspace {
      edges {
        node {
          id
          workspaceEdge {
            ...WorkspaceEdgeSimpleFields
            ... on WorkspaceEdge {
              node {
                ...WorkspaceSimpleFields
              }
            }
            ... on WorkspacePreviewEdge {
              node {
                ...WorkspacePreviewSimpleFields
              }
            }
          }
          unseenGroups: groups(
            filter: { unseenThreads: true }
            last: $groupsLimit
            order: name
          ) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
          recentGroups: groups(
            filter: { unseenThreads: false }
            last: $recentGroupsLimit
            order: interaction
          ) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
          otherGroups: groups(
            filter: { unseenThreads: false }
            first: $groupsLimit
            after: $groupsAfter
            order: name
          ) {
            edges {
              ...GroupEdgeSimpleFields
              node {
                ...GroupSimpleFields
              }
            }
          }
        }
      }
    }
  }
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
  ${GroupEdgeSimpleFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
`;

/**
 * __useWorkspacesAndGroupsListQuery__
 *
 * To run a query within a React component, call `useWorkspacesAndGroupsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesAndGroupsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesAndGroupsListQuery({
 *   variables: {
 *      recentGroupsLimit: // value for 'recentGroupsLimit'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *   },
 * });
 */
export function useWorkspacesAndGroupsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspacesAndGroupsListQuery,
    WorkspacesAndGroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspacesAndGroupsListQuery,
    WorkspacesAndGroupsListQueryVariables
  >(WorkspacesAndGroupsListDocument, options);
}
export function useWorkspacesAndGroupsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspacesAndGroupsListQuery,
    WorkspacesAndGroupsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspacesAndGroupsListQuery,
    WorkspacesAndGroupsListQueryVariables
  >(WorkspacesAndGroupsListDocument, options);
}
export type WorkspacesAndGroupsListQueryHookResult = ReturnType<
  typeof useWorkspacesAndGroupsListQuery
>;
export type WorkspacesAndGroupsListLazyQueryHookResult = ReturnType<
  typeof useWorkspacesAndGroupsListLazyQuery
>;
export type WorkspacesAndGroupsListQueryResult = Apollo.QueryResult<
  WorkspacesAndGroupsListQuery,
  WorkspacesAndGroupsListQueryVariables
>;
export const CreateSlackImportDocument = gql`
  mutation CreateSlackImport($workspaceID: ID!) {
    createSlackImport(workspaceID: $workspaceID) {
      ...SlackImport
    }
  }
  ${SlackImportFragmentDoc}
`;
export type CreateSlackImportMutationFn = Apollo.MutationFunction<
  CreateSlackImportMutation,
  CreateSlackImportMutationVariables
>;

/**
 * __useCreateSlackImportMutation__
 *
 * To run a mutation, you first call `useCreateSlackImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackImportMutation, { data, loading, error }] = useCreateSlackImportMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useCreateSlackImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSlackImportMutation,
    CreateSlackImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSlackImportMutation,
    CreateSlackImportMutationVariables
  >(CreateSlackImportDocument, options);
}
export type CreateSlackImportMutationHookResult = ReturnType<
  typeof useCreateSlackImportMutation
>;
export type CreateSlackImportMutationResult =
  Apollo.MutationResult<CreateSlackImportMutation>;
export type CreateSlackImportMutationOptions = Apollo.BaseMutationOptions<
  CreateSlackImportMutation,
  CreateSlackImportMutationVariables
>;
export const LoadSlackImportDocument = gql`
  mutation LoadSlackImport($workspaceID: ID!, $fileID: ID!) {
    loadSlackImport(workspaceID: $workspaceID, fileID: $fileID) {
      ...SlackImport
    }
  }
  ${SlackImportFragmentDoc}
`;
export type LoadSlackImportMutationFn = Apollo.MutationFunction<
  LoadSlackImportMutation,
  LoadSlackImportMutationVariables
>;

/**
 * __useLoadSlackImportMutation__
 *
 * To run a mutation, you first call `useLoadSlackImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadSlackImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadSlackImportMutation, { data, loading, error }] = useLoadSlackImportMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *      fileID: // value for 'fileID'
 *   },
 * });
 */
export function useLoadSlackImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoadSlackImportMutation,
    LoadSlackImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LoadSlackImportMutation,
    LoadSlackImportMutationVariables
  >(LoadSlackImportDocument, options);
}
export type LoadSlackImportMutationHookResult = ReturnType<
  typeof useLoadSlackImportMutation
>;
export type LoadSlackImportMutationResult =
  Apollo.MutationResult<LoadSlackImportMutation>;
export type LoadSlackImportMutationOptions = Apollo.BaseMutationOptions<
  LoadSlackImportMutation,
  LoadSlackImportMutationVariables
>;
export const UpdateSlackImportDocument = gql`
  mutation UpdateSlackImport(
    $workspaceID: ID!
    $config: SlackImportConfigInput!
  ) {
    updateSlackImport(workspaceID: $workspaceID, config: $config) {
      ...SlackImport
    }
  }
  ${SlackImportFragmentDoc}
`;
export type UpdateSlackImportMutationFn = Apollo.MutationFunction<
  UpdateSlackImportMutation,
  UpdateSlackImportMutationVariables
>;

/**
 * __useUpdateSlackImportMutation__
 *
 * To run a mutation, you first call `useUpdateSlackImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlackImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlackImportMutation, { data, loading, error }] = useUpdateSlackImportMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useUpdateSlackImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSlackImportMutation,
    UpdateSlackImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSlackImportMutation,
    UpdateSlackImportMutationVariables
  >(UpdateSlackImportDocument, options);
}
export type UpdateSlackImportMutationHookResult = ReturnType<
  typeof useUpdateSlackImportMutation
>;
export type UpdateSlackImportMutationResult =
  Apollo.MutationResult<UpdateSlackImportMutation>;
export type UpdateSlackImportMutationOptions = Apollo.BaseMutationOptions<
  UpdateSlackImportMutation,
  UpdateSlackImportMutationVariables
>;
export const StartSlackImportDocument = gql`
  mutation StartSlackImport($workspaceID: ID!) {
    startSlackImport(workspaceID: $workspaceID) {
      ...SlackImport
    }
  }
  ${SlackImportFragmentDoc}
`;
export type StartSlackImportMutationFn = Apollo.MutationFunction<
  StartSlackImportMutation,
  StartSlackImportMutationVariables
>;

/**
 * __useStartSlackImportMutation__
 *
 * To run a mutation, you first call `useStartSlackImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSlackImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSlackImportMutation, { data, loading, error }] = useStartSlackImportMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useStartSlackImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartSlackImportMutation,
    StartSlackImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartSlackImportMutation,
    StartSlackImportMutationVariables
  >(StartSlackImportDocument, options);
}
export type StartSlackImportMutationHookResult = ReturnType<
  typeof useStartSlackImportMutation
>;
export type StartSlackImportMutationResult =
  Apollo.MutationResult<StartSlackImportMutation>;
export type StartSlackImportMutationOptions = Apollo.BaseMutationOptions<
  StartSlackImportMutation,
  StartSlackImportMutationVariables
>;
export const DeleteSlackImportDocument = gql`
  mutation DeleteSlackImport($workspaceID: ID!) {
    deleteSlackImport(workspaceID: $workspaceID)
  }
`;
export type DeleteSlackImportMutationFn = Apollo.MutationFunction<
  DeleteSlackImportMutation,
  DeleteSlackImportMutationVariables
>;

/**
 * __useDeleteSlackImportMutation__
 *
 * To run a mutation, you first call `useDeleteSlackImportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSlackImportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSlackImportMutation, { data, loading, error }] = useDeleteSlackImportMutation({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useDeleteSlackImportMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSlackImportMutation,
    DeleteSlackImportMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSlackImportMutation,
    DeleteSlackImportMutationVariables
  >(DeleteSlackImportDocument, options);
}
export type DeleteSlackImportMutationHookResult = ReturnType<
  typeof useDeleteSlackImportMutation
>;
export type DeleteSlackImportMutationResult =
  Apollo.MutationResult<DeleteSlackImportMutation>;
export type DeleteSlackImportMutationOptions = Apollo.BaseMutationOptions<
  DeleteSlackImportMutation,
  DeleteSlackImportMutationVariables
>;
export const SlackImportDocument = gql`
  query SlackImport($workspaceID: ID!) {
    slackImport(workspaceID: $workspaceID) {
      ...SlackImport
    }
  }
  ${SlackImportFragmentDoc}
`;

/**
 * __useSlackImportQuery__
 *
 * To run a query within a React component, call `useSlackImportQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackImportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackImportQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useSlackImportQuery(
  baseOptions: Apollo.QueryHookOptions<
    SlackImportQuery,
    SlackImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SlackImportQuery, SlackImportQueryVariables>(
    SlackImportDocument,
    options
  );
}
export function useSlackImportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SlackImportQuery,
    SlackImportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SlackImportQuery, SlackImportQueryVariables>(
    SlackImportDocument,
    options
  );
}
export type SlackImportQueryHookResult = ReturnType<typeof useSlackImportQuery>;
export type SlackImportLazyQueryHookResult = ReturnType<
  typeof useSlackImportLazyQuery
>;
export type SlackImportQueryResult = Apollo.QueryResult<
  SlackImportQuery,
  SlackImportQueryVariables
>;
export const CreateLambdaDocument = gql`
  mutation CreateLambda(
    $name: String!
    $description: String!
    $event: String!
    $source: String!
    $appID: ID!
  ) {
    createLambda(
      input: {
        name: $name
        description: $description
        event: $event
        source: $source
        appID: $appID
      }
    ) {
      ...LambdaFields
    }
  }
  ${LambdaFieldsFragmentDoc}
`;
export type CreateLambdaMutationFn = Apollo.MutationFunction<
  CreateLambdaMutation,
  CreateLambdaMutationVariables
>;

/**
 * __useCreateLambdaMutation__
 *
 * To run a mutation, you first call `useCreateLambdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLambdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLambdaMutation, { data, loading, error }] = useCreateLambdaMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      event: // value for 'event'
 *      source: // value for 'source'
 *      appID: // value for 'appID'
 *   },
 * });
 */
export function useCreateLambdaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLambdaMutation,
    CreateLambdaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLambdaMutation,
    CreateLambdaMutationVariables
  >(CreateLambdaDocument, options);
}
export type CreateLambdaMutationHookResult = ReturnType<
  typeof useCreateLambdaMutation
>;
export type CreateLambdaMutationResult =
  Apollo.MutationResult<CreateLambdaMutation>;
export type CreateLambdaMutationOptions = Apollo.BaseMutationOptions<
  CreateLambdaMutation,
  CreateLambdaMutationVariables
>;
export const DeleteLambdaDocument = gql`
  mutation DeleteLambda($id: ID!) {
    deleteLambda(id: $id)
  }
`;
export type DeleteLambdaMutationFn = Apollo.MutationFunction<
  DeleteLambdaMutation,
  DeleteLambdaMutationVariables
>;

/**
 * __useDeleteLambdaMutation__
 *
 * To run a mutation, you first call `useDeleteLambdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLambdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLambdaMutation, { data, loading, error }] = useDeleteLambdaMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLambdaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLambdaMutation,
    DeleteLambdaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLambdaMutation,
    DeleteLambdaMutationVariables
  >(DeleteLambdaDocument, options);
}
export type DeleteLambdaMutationHookResult = ReturnType<
  typeof useDeleteLambdaMutation
>;
export type DeleteLambdaMutationResult =
  Apollo.MutationResult<DeleteLambdaMutation>;
export type DeleteLambdaMutationOptions = Apollo.BaseMutationOptions<
  DeleteLambdaMutation,
  DeleteLambdaMutationVariables
>;
export const EnableLambdaDocument = gql`
  mutation EnableLambda($id: ID!, $enabled: Boolean!) {
    enableLambda(id: $id, enabled: $enabled) {
      ...LambdaFields
    }
  }
  ${LambdaFieldsFragmentDoc}
`;
export type EnableLambdaMutationFn = Apollo.MutationFunction<
  EnableLambdaMutation,
  EnableLambdaMutationVariables
>;

/**
 * __useEnableLambdaMutation__
 *
 * To run a mutation, you first call `useEnableLambdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableLambdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableLambdaMutation, { data, loading, error }] = useEnableLambdaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useEnableLambdaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EnableLambdaMutation,
    EnableLambdaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EnableLambdaMutation,
    EnableLambdaMutationVariables
  >(EnableLambdaDocument, options);
}
export type EnableLambdaMutationHookResult = ReturnType<
  typeof useEnableLambdaMutation
>;
export type EnableLambdaMutationResult =
  Apollo.MutationResult<EnableLambdaMutation>;
export type EnableLambdaMutationOptions = Apollo.BaseMutationOptions<
  EnableLambdaMutation,
  EnableLambdaMutationVariables
>;
export const UpdateLambdaDocument = gql`
  mutation UpdateLambda(
    $id: ID!
    $name: String!
    $description: String!
    $event: String!
    $source: String!
  ) {
    updateLambda(
      id: $id
      input: {
        name: $name
        description: $description
        event: $event
        source: $source
      }
    ) {
      ...LambdaFields
    }
  }
  ${LambdaFieldsFragmentDoc}
`;
export type UpdateLambdaMutationFn = Apollo.MutationFunction<
  UpdateLambdaMutation,
  UpdateLambdaMutationVariables
>;

/**
 * __useUpdateLambdaMutation__
 *
 * To run a mutation, you first call `useUpdateLambdaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLambdaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLambdaMutation, { data, loading, error }] = useUpdateLambdaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      event: // value for 'event'
 *      source: // value for 'source'
 *   },
 * });
 */
export function useUpdateLambdaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLambdaMutation,
    UpdateLambdaMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLambdaMutation,
    UpdateLambdaMutationVariables
  >(UpdateLambdaDocument, options);
}
export type UpdateLambdaMutationHookResult = ReturnType<
  typeof useUpdateLambdaMutation
>;
export type UpdateLambdaMutationResult =
  Apollo.MutationResult<UpdateLambdaMutation>;
export type UpdateLambdaMutationOptions = Apollo.BaseMutationOptions<
  UpdateLambdaMutation,
  UpdateLambdaMutationVariables
>;
export const CreateSecretDocument = gql`
  mutation CreateSecret($lambdaID: ID!, $name: String!, $value: String!) {
    createLambdaSecret(lambdaID: $lambdaID, name: $name, value: $value) {
      ...LambdaSecretFields
    }
  }
  ${LambdaSecretFieldsFragmentDoc}
`;
export type CreateSecretMutationFn = Apollo.MutationFunction<
  CreateSecretMutation,
  CreateSecretMutationVariables
>;

/**
 * __useCreateSecretMutation__
 *
 * To run a mutation, you first call `useCreateSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSecretMutation, { data, loading, error }] = useCreateSecretMutation({
 *   variables: {
 *      lambdaID: // value for 'lambdaID'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSecretMutation,
    CreateSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSecretMutation,
    CreateSecretMutationVariables
  >(CreateSecretDocument, options);
}
export type CreateSecretMutationHookResult = ReturnType<
  typeof useCreateSecretMutation
>;
export type CreateSecretMutationResult =
  Apollo.MutationResult<CreateSecretMutation>;
export type CreateSecretMutationOptions = Apollo.BaseMutationOptions<
  CreateSecretMutation,
  CreateSecretMutationVariables
>;
export const UpdateSecretDocument = gql`
  mutation UpdateSecret($secretID: ID!, $value: String!) {
    updateLambdaSecret(secretID: $secretID, value: $value)
  }
`;
export type UpdateSecretMutationFn = Apollo.MutationFunction<
  UpdateSecretMutation,
  UpdateSecretMutationVariables
>;

/**
 * __useUpdateSecretMutation__
 *
 * To run a mutation, you first call `useUpdateSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSecretMutation, { data, loading, error }] = useUpdateSecretMutation({
 *   variables: {
 *      secretID: // value for 'secretID'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSecretMutation,
    UpdateSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSecretMutation,
    UpdateSecretMutationVariables
  >(UpdateSecretDocument, options);
}
export type UpdateSecretMutationHookResult = ReturnType<
  typeof useUpdateSecretMutation
>;
export type UpdateSecretMutationResult =
  Apollo.MutationResult<UpdateSecretMutation>;
export type UpdateSecretMutationOptions = Apollo.BaseMutationOptions<
  UpdateSecretMutation,
  UpdateSecretMutationVariables
>;
export const DeleteSecretDocument = gql`
  mutation DeleteSecret($secretID: ID!) {
    deleteLambdaSecret(secretID: $secretID)
  }
`;
export type DeleteSecretMutationFn = Apollo.MutationFunction<
  DeleteSecretMutation,
  DeleteSecretMutationVariables
>;

/**
 * __useDeleteSecretMutation__
 *
 * To run a mutation, you first call `useDeleteSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSecretMutation, { data, loading, error }] = useDeleteSecretMutation({
 *   variables: {
 *      secretID: // value for 'secretID'
 *   },
 * });
 */
export function useDeleteSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSecretMutation,
    DeleteSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSecretMutation,
    DeleteSecretMutationVariables
  >(DeleteSecretDocument, options);
}
export type DeleteSecretMutationHookResult = ReturnType<
  typeof useDeleteSecretMutation
>;
export type DeleteSecretMutationResult =
  Apollo.MutationResult<DeleteSecretMutation>;
export type DeleteSecretMutationOptions = Apollo.BaseMutationOptions<
  DeleteSecretMutation,
  DeleteSecretMutationVariables
>;
export const LambdasDocument = gql`
  query Lambdas($appId: ID!, $first: Int, $after: String) {
    lambdas(appId: $appId, first: $first, after: $after) {
      edges {
        node {
          ...LambdaFields
        }
      }
    }
  }
  ${LambdaFieldsFragmentDoc}
`;

/**
 * __useLambdasQuery__
 *
 * To run a query within a React component, call `useLambdasQuery` and pass it any options that fit your needs.
 * When your component renders, `useLambdasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLambdasQuery({
 *   variables: {
 *      appId: // value for 'appId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useLambdasQuery(
  baseOptions: Apollo.QueryHookOptions<LambdasQuery, LambdasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LambdasQuery, LambdasQueryVariables>(
    LambdasDocument,
    options
  );
}
export function useLambdasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LambdasQuery, LambdasQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LambdasQuery, LambdasQueryVariables>(
    LambdasDocument,
    options
  );
}
export type LambdasQueryHookResult = ReturnType<typeof useLambdasQuery>;
export type LambdasLazyQueryHookResult = ReturnType<typeof useLambdasLazyQuery>;
export type LambdasQueryResult = Apollo.QueryResult<
  LambdasQuery,
  LambdasQueryVariables
>;
export const LambdaSecretsDocument = gql`
  query LambdaSecrets($lambdaId: ID!) {
    lambdaSecrets(lambdaId: $lambdaId) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useLambdaSecretsQuery__
 *
 * To run a query within a React component, call `useLambdaSecretsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLambdaSecretsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLambdaSecretsQuery({
 *   variables: {
 *      lambdaId: // value for 'lambdaId'
 *   },
 * });
 */
export function useLambdaSecretsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LambdaSecretsQuery,
    LambdaSecretsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LambdaSecretsQuery, LambdaSecretsQueryVariables>(
    LambdaSecretsDocument,
    options
  );
}
export function useLambdaSecretsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LambdaSecretsQuery,
    LambdaSecretsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LambdaSecretsQuery, LambdaSecretsQueryVariables>(
    LambdaSecretsDocument,
    options
  );
}
export type LambdaSecretsQueryHookResult = ReturnType<
  typeof useLambdaSecretsQuery
>;
export type LambdaSecretsLazyQueryHookResult = ReturnType<
  typeof useLambdaSecretsLazyQuery
>;
export type LambdaSecretsQueryResult = Apollo.QueryResult<
  LambdaSecretsQuery,
  LambdaSecretsQueryVariables
>;
export const AddWorkspaceAppDocument = gql`
  mutation AddWorkspaceApp($input: WorkspaceAppInput!) {
    addWorkspaceApp(input: $input) {
      ...WorkspaceAppEdgeFields
    }
  }
  ${WorkspaceAppEdgeFieldsFragmentDoc}
`;
export type AddWorkspaceAppMutationFn = Apollo.MutationFunction<
  AddWorkspaceAppMutation,
  AddWorkspaceAppMutationVariables
>;

/**
 * __useAddWorkspaceAppMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceAppMutation, { data, loading, error }] = useAddWorkspaceAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddWorkspaceAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWorkspaceAppMutation,
    AddWorkspaceAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWorkspaceAppMutation,
    AddWorkspaceAppMutationVariables
  >(AddWorkspaceAppDocument, options);
}
export type AddWorkspaceAppMutationHookResult = ReturnType<
  typeof useAddWorkspaceAppMutation
>;
export type AddWorkspaceAppMutationResult =
  Apollo.MutationResult<AddWorkspaceAppMutation>;
export type AddWorkspaceAppMutationOptions = Apollo.BaseMutationOptions<
  AddWorkspaceAppMutation,
  AddWorkspaceAppMutationVariables
>;
export const AppsDocument = gql`
  query Apps($first: Int, $after: String) {
    apps(first: $first, after: $after) {
      edges {
        node {
          ...AppFields
        }
      }
    }
  }
  ${AppFieldsFragmentDoc}
`;

/**
 * __useAppsQuery__
 *
 * To run a query within a React component, call `useAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<AppsQuery, AppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppsQuery, AppsQueryVariables>(AppsDocument, options);
}
export function useAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppsQuery, AppsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppsQuery, AppsQueryVariables>(
    AppsDocument,
    options
  );
}
export type AppsQueryHookResult = ReturnType<typeof useAppsQuery>;
export type AppsLazyQueryHookResult = ReturnType<typeof useAppsLazyQuery>;
export type AppsQueryResult = Apollo.QueryResult<AppsQuery, AppsQueryVariables>;
export const AppDocument = gql`
  query App($id: ID!) {
    node(id: $id) {
      id
      ... on App {
        ...AppFields
      }
    }
  }
  ${AppFieldsFragmentDoc}
`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAppQuery(
  baseOptions: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
}
export function useAppLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
}
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const WorkspaceAppsDocument = gql`
  query WorkspaceApps {
    workspaces {
      edges {
        id
        node {
          id
          avatarURL
          name
          apps {
            edges {
              id
              node {
                ...AppFields
              }
            }
          }
        }
      }
    }
  }
  ${AppFieldsFragmentDoc}
`;

/**
 * __useWorkspaceAppsQuery__
 *
 * To run a query within a React component, call `useWorkspaceAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceAppsQuery,
    WorkspaceAppsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspaceAppsQuery, WorkspaceAppsQueryVariables>(
    WorkspaceAppsDocument,
    options
  );
}
export function useWorkspaceAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceAppsQuery,
    WorkspaceAppsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspaceAppsQuery, WorkspaceAppsQueryVariables>(
    WorkspaceAppsDocument,
    options
  );
}
export type WorkspaceAppsQueryHookResult = ReturnType<
  typeof useWorkspaceAppsQuery
>;
export type WorkspaceAppsLazyQueryHookResult = ReturnType<
  typeof useWorkspaceAppsLazyQuery
>;
export type WorkspaceAppsQueryResult = Apollo.QueryResult<
  WorkspaceAppsQuery,
  WorkspaceAppsQueryVariables
>;
export const WorkspaceAppConfigDocument = gql`
  query WorkspaceAppConfig(
    $appID: ID!
    $configurableID: ID!
    $workspaceID: ID!
  ) {
    workspaceAppConfig(
      appID: $appID
      configurableID: $configurableID
      workspaceID: $workspaceID
    ) {
      url
      token
    }
  }
`;

/**
 * __useWorkspaceAppConfigQuery__
 *
 * To run a query within a React component, call `useWorkspaceAppConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceAppConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceAppConfigQuery({
 *   variables: {
 *      appID: // value for 'appID'
 *      configurableID: // value for 'configurableID'
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useWorkspaceAppConfigQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceAppConfigQuery,
    WorkspaceAppConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceAppConfigQuery,
    WorkspaceAppConfigQueryVariables
  >(WorkspaceAppConfigDocument, options);
}
export function useWorkspaceAppConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceAppConfigQuery,
    WorkspaceAppConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceAppConfigQuery,
    WorkspaceAppConfigQueryVariables
  >(WorkspaceAppConfigDocument, options);
}
export type WorkspaceAppConfigQueryHookResult = ReturnType<
  typeof useWorkspaceAppConfigQuery
>;
export type WorkspaceAppConfigLazyQueryHookResult = ReturnType<
  typeof useWorkspaceAppConfigLazyQuery
>;
export type WorkspaceAppConfigQueryResult = Apollo.QueryResult<
  WorkspaceAppConfigQuery,
  WorkspaceAppConfigQueryVariables
>;
export const DevicesDocument = gql`
  query Devices {
    devices {
      edges {
        node {
          id
          platform
        }
      }
    }
  }
`;

/**
 * __useDevicesQuery__
 *
 * To run a query within a React component, call `useDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDevicesQuery(
  baseOptions?: Apollo.QueryHookOptions<DevicesQuery, DevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    options
  );
}
export function useDevicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DevicesQuery, DevicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DevicesQuery, DevicesQueryVariables>(
    DevicesDocument,
    options
  );
}
export type DevicesQueryHookResult = ReturnType<typeof useDevicesQuery>;
export type DevicesLazyQueryHookResult = ReturnType<typeof useDevicesLazyQuery>;
export type DevicesQueryResult = Apollo.QueryResult<
  DevicesQuery,
  DevicesQueryVariables
>;
export const CreateCustomEmojiDocument = gql`
  mutation CreateCustomEmoji($input: CreateCustomEmojiInput!) {
    createCustomEmoji(input: $input) {
      ...CustomEmojiFields
    }
  }
  ${CustomEmojiFieldsFragmentDoc}
`;
export type CreateCustomEmojiMutationFn = Apollo.MutationFunction<
  CreateCustomEmojiMutation,
  CreateCustomEmojiMutationVariables
>;

/**
 * __useCreateCustomEmojiMutation__
 *
 * To run a mutation, you first call `useCreateCustomEmojiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomEmojiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomEmojiMutation, { data, loading, error }] = useCreateCustomEmojiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomEmojiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomEmojiMutation,
    CreateCustomEmojiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomEmojiMutation,
    CreateCustomEmojiMutationVariables
  >(CreateCustomEmojiDocument, options);
}
export type CreateCustomEmojiMutationHookResult = ReturnType<
  typeof useCreateCustomEmojiMutation
>;
export type CreateCustomEmojiMutationResult =
  Apollo.MutationResult<CreateCustomEmojiMutation>;
export type CreateCustomEmojiMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomEmojiMutation,
  CreateCustomEmojiMutationVariables
>;
export const DeleteCustomEmojiDocument = gql`
  mutation DeleteCustomEmoji($id: ID!) {
    deleteCustomEmoji(id: $id)
  }
`;
export type DeleteCustomEmojiMutationFn = Apollo.MutationFunction<
  DeleteCustomEmojiMutation,
  DeleteCustomEmojiMutationVariables
>;

/**
 * __useDeleteCustomEmojiMutation__
 *
 * To run a mutation, you first call `useDeleteCustomEmojiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomEmojiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomEmojiMutation, { data, loading, error }] = useDeleteCustomEmojiMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomEmojiMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomEmojiMutation,
    DeleteCustomEmojiMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomEmojiMutation,
    DeleteCustomEmojiMutationVariables
  >(DeleteCustomEmojiDocument, options);
}
export type DeleteCustomEmojiMutationHookResult = ReturnType<
  typeof useDeleteCustomEmojiMutation
>;
export type DeleteCustomEmojiMutationResult =
  Apollo.MutationResult<DeleteCustomEmojiMutation>;
export type DeleteCustomEmojiMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomEmojiMutation,
  DeleteCustomEmojiMutationVariables
>;
export const GenerateEmojiImageDocument = gql`
  mutation GenerateEmojiImage($input: GenerateEmojiImageInput!) {
    generateEmojiImage(input: $input) {
      ...FileFields
    }
  }
  ${FileFieldsFragmentDoc}
`;
export type GenerateEmojiImageMutationFn = Apollo.MutationFunction<
  GenerateEmojiImageMutation,
  GenerateEmojiImageMutationVariables
>;

/**
 * __useGenerateEmojiImageMutation__
 *
 * To run a mutation, you first call `useGenerateEmojiImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmojiImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmojiImageMutation, { data, loading, error }] = useGenerateEmojiImageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateEmojiImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateEmojiImageMutation,
    GenerateEmojiImageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateEmojiImageMutation,
    GenerateEmojiImageMutationVariables
  >(GenerateEmojiImageDocument, options);
}
export type GenerateEmojiImageMutationHookResult = ReturnType<
  typeof useGenerateEmojiImageMutation
>;
export type GenerateEmojiImageMutationResult =
  Apollo.MutationResult<GenerateEmojiImageMutation>;
export type GenerateEmojiImageMutationOptions = Apollo.BaseMutationOptions<
  GenerateEmojiImageMutation,
  GenerateEmojiImageMutationVariables
>;
export const CustomEmojisDocument = gql`
  query CustomEmojis($after: String, $first: Int = 200) {
    customEmojis(after: $after, first: $first) {
      edges {
        node {
          ...CustomEmojiFields
        }
      }
    }
  }
  ${CustomEmojiFieldsFragmentDoc}
`;

/**
 * __useCustomEmojisQuery__
 *
 * To run a query within a React component, call `useCustomEmojisQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomEmojisQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomEmojisQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useCustomEmojisQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CustomEmojisQuery,
    CustomEmojisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomEmojisQuery, CustomEmojisQueryVariables>(
    CustomEmojisDocument,
    options
  );
}
export function useCustomEmojisLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomEmojisQuery,
    CustomEmojisQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomEmojisQuery, CustomEmojisQueryVariables>(
    CustomEmojisDocument,
    options
  );
}
export type CustomEmojisQueryHookResult = ReturnType<
  typeof useCustomEmojisQuery
>;
export type CustomEmojisLazyQueryHookResult = ReturnType<
  typeof useCustomEmojisLazyQuery
>;
export type CustomEmojisQueryResult = Apollo.QueryResult<
  CustomEmojisQuery,
  CustomEmojisQueryVariables
>;
export const CustomEmojiDocument = gql`
  query customEmoji($id: ID!) {
    node(id: $id) {
      ... on CustomEmoji {
        ...CustomEmojiFields
      }
    }
  }
  ${CustomEmojiFieldsFragmentDoc}
`;

/**
 * __useCustomEmojiQuery__
 *
 * To run a query within a React component, call `useCustomEmojiQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomEmojiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomEmojiQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomEmojiQuery(
  baseOptions: Apollo.QueryHookOptions<
    CustomEmojiQuery,
    CustomEmojiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CustomEmojiQuery, CustomEmojiQueryVariables>(
    CustomEmojiDocument,
    options
  );
}
export function useCustomEmojiLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CustomEmojiQuery,
    CustomEmojiQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CustomEmojiQuery, CustomEmojiQueryVariables>(
    CustomEmojiDocument,
    options
  );
}
export type CustomEmojiQueryHookResult = ReturnType<typeof useCustomEmojiQuery>;
export type CustomEmojiLazyQueryHookResult = ReturnType<
  typeof useCustomEmojiLazyQuery
>;
export type CustomEmojiQueryResult = Apollo.QueryResult<
  CustomEmojiQuery,
  CustomEmojiQueryVariables
>;
export const EmojiNameSuggestionDocument = gql`
  query EmojiNameSuggestion($input: EmojiNameSuggestionInput!) {
    emojiNameSuggestion(input: $input) {
      names
    }
  }
`;

/**
 * __useEmojiNameSuggestionQuery__
 *
 * To run a query within a React component, call `useEmojiNameSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmojiNameSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmojiNameSuggestionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmojiNameSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmojiNameSuggestionQuery,
    EmojiNameSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EmojiNameSuggestionQuery,
    EmojiNameSuggestionQueryVariables
  >(EmojiNameSuggestionDocument, options);
}
export function useEmojiNameSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmojiNameSuggestionQuery,
    EmojiNameSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmojiNameSuggestionQuery,
    EmojiNameSuggestionQueryVariables
  >(EmojiNameSuggestionDocument, options);
}
export type EmojiNameSuggestionQueryHookResult = ReturnType<
  typeof useEmojiNameSuggestionQuery
>;
export type EmojiNameSuggestionLazyQueryHookResult = ReturnType<
  typeof useEmojiNameSuggestionLazyQuery
>;
export type EmojiNameSuggestionQueryResult = Apollo.QueryResult<
  EmojiNameSuggestionQuery,
  EmojiNameSuggestionQueryVariables
>;
export const CreateGroupDocument = gql`
  mutation CreateGroup(
    $input: CreateGroupInput!
    $membersLimit: Int = 0
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    createGroup(input: $input) {
      ...GroupFields
    }
  }
  ${GroupFieldsFragmentDoc}
`;
export type CreateGroupMutationFn = Apollo.MutationFunction<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useCreateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGroupMutation,
    CreateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(
    CreateGroupDocument,
    options
  );
}
export type CreateGroupMutationHookResult = ReturnType<
  typeof useCreateGroupMutation
>;
export type CreateGroupMutationResult =
  Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<
  CreateGroupMutation,
  CreateGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation UpdateGroup(
    $id: ID!
    $input: UpdateGroupInput!
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    updateGroup(id: $id, input: $input) {
      ...GroupFields
    }
  }
  ${GroupFieldsFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    options
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    options
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const RemoveGroupMembersDocument = gql`
  mutation RemoveGroupMembers(
    $id: ID!
    $memberIDs: [ID!]!
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    removeGroupMembers(id: $id, memberIDs: $memberIDs) {
      ...GroupFields
    }
  }
  ${GroupFieldsFragmentDoc}
`;
export type RemoveGroupMembersMutationFn = Apollo.MutationFunction<
  RemoveGroupMembersMutation,
  RemoveGroupMembersMutationVariables
>;

/**
 * __useRemoveGroupMembersMutation__
 *
 * To run a mutation, you first call `useRemoveGroupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGroupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGroupMembersMutation, { data, loading, error }] = useRemoveGroupMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberIDs: // value for 'memberIDs'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useRemoveGroupMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGroupMembersMutation,
    RemoveGroupMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGroupMembersMutation,
    RemoveGroupMembersMutationVariables
  >(RemoveGroupMembersDocument, options);
}
export type RemoveGroupMembersMutationHookResult = ReturnType<
  typeof useRemoveGroupMembersMutation
>;
export type RemoveGroupMembersMutationResult =
  Apollo.MutationResult<RemoveGroupMembersMutation>;
export type RemoveGroupMembersMutationOptions = Apollo.BaseMutationOptions<
  RemoveGroupMembersMutation,
  RemoveGroupMembersMutationVariables
>;
export const FetchGroupDocument = gql`
  query FetchGroup(
    $id: ID!
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    node(id: $id) {
      id
      ... on Group {
        ...GroupFields
      }
    }
  }
  ${GroupFieldsFragmentDoc}
`;

/**
 * __useFetchGroupQuery__
 *
 * To run a query within a React component, call `useFetchGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchGroupQuery,
    FetchGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGroupQuery, FetchGroupQueryVariables>(
    FetchGroupDocument,
    options
  );
}
export function useFetchGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchGroupQuery,
    FetchGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGroupQuery, FetchGroupQueryVariables>(
    FetchGroupDocument,
    options
  );
}
export type FetchGroupQueryHookResult = ReturnType<typeof useFetchGroupQuery>;
export type FetchGroupLazyQueryHookResult = ReturnType<
  typeof useFetchGroupLazyQuery
>;
export type FetchGroupQueryResult = Apollo.QueryResult<
  FetchGroupQuery,
  FetchGroupQueryVariables
>;
export const EmojiSuggestionDocument = gql`
  query EmojiSuggestion($name: String!) {
    emojiSuggestion(name: $name) {
      emoji
    }
  }
`;

/**
 * __useEmojiSuggestionQuery__
 *
 * To run a query within a React component, call `useEmojiSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmojiSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmojiSuggestionQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEmojiSuggestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    EmojiSuggestionQuery,
    EmojiSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EmojiSuggestionQuery, EmojiSuggestionQueryVariables>(
    EmojiSuggestionDocument,
    options
  );
}
export function useEmojiSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EmojiSuggestionQuery,
    EmojiSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EmojiSuggestionQuery,
    EmojiSuggestionQueryVariables
  >(EmojiSuggestionDocument, options);
}
export type EmojiSuggestionQueryHookResult = ReturnType<
  typeof useEmojiSuggestionQuery
>;
export type EmojiSuggestionLazyQueryHookResult = ReturnType<
  typeof useEmojiSuggestionLazyQuery
>;
export type EmojiSuggestionQueryResult = Apollo.QueryResult<
  EmojiSuggestionQuery,
  EmojiSuggestionQueryVariables
>;
export const UpdateGroupEdgeDocument = gql`
  mutation UpdateGroupEdge($id: ID!, $threadSubscription: ThreadSubscription!) {
    updateGroupEdge(
      id: $id
      input: { threadSubscription: $threadSubscription }
    ) {
      ...GroupEdgeFields
    }
  }
  ${GroupEdgeFieldsFragmentDoc}
`;
export type UpdateGroupEdgeMutationFn = Apollo.MutationFunction<
  UpdateGroupEdgeMutation,
  UpdateGroupEdgeMutationVariables
>;

/**
 * __useUpdateGroupEdgeMutation__
 *
 * To run a mutation, you first call `useUpdateGroupEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupEdgeMutation, { data, loading, error }] = useUpdateGroupEdgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      threadSubscription: // value for 'threadSubscription'
 *   },
 * });
 */
export function useUpdateGroupEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupEdgeMutation,
    UpdateGroupEdgeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGroupEdgeMutation,
    UpdateGroupEdgeMutationVariables
  >(UpdateGroupEdgeDocument, options);
}
export type UpdateGroupEdgeMutationHookResult = ReturnType<
  typeof useUpdateGroupEdgeMutation
>;
export type UpdateGroupEdgeMutationResult =
  Apollo.MutationResult<UpdateGroupEdgeMutation>;
export type UpdateGroupEdgeMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupEdgeMutation,
  UpdateGroupEdgeMutationVariables
>;
export const LeaveGroupDocument = gql`
  mutation LeaveGroup($id: ID!) {
    leaveGroup(id: $id)
  }
`;
export type LeaveGroupMutationFn = Apollo.MutationFunction<
  LeaveGroupMutation,
  LeaveGroupMutationVariables
>;

/**
 * __useLeaveGroupMutation__
 *
 * To run a mutation, you first call `useLeaveGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveGroupMutation, { data, loading, error }] = useLeaveGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveGroupMutation,
    LeaveGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveGroupMutation, LeaveGroupMutationVariables>(
    LeaveGroupDocument,
    options
  );
}
export type LeaveGroupMutationHookResult = ReturnType<
  typeof useLeaveGroupMutation
>;
export type LeaveGroupMutationResult =
  Apollo.MutationResult<LeaveGroupMutation>;
export type LeaveGroupMutationOptions = Apollo.BaseMutationOptions<
  LeaveGroupMutation,
  LeaveGroupMutationVariables
>;
export const FetchGroupOrPreviewEdgeDocument = gql`
  query FetchGroupOrPreviewEdge(
    $id: ID!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    node(id: $id) {
      id
      ... on GroupEdge {
        ...GroupEdgeFields
        node {
          ...GroupFields
        }
      }
      ... on GroupPreviewEdge {
        node {
          ...GroupPreviewFields
        }
      }
      ... on WorkspaceEdge {
        ...WorkspaceEdgeFields
        node {
          ...WorkspaceFields
        }
      }
      ... on WorkspacePreviewEdge {
        node {
          ...WorkspacePreviewFields
        }
      }
    }
  }
  ${GroupEdgeFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${WorkspaceEdgeFieldsFragmentDoc}
  ${WorkspaceFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useFetchGroupOrPreviewEdgeQuery__
 *
 * To run a query within a React component, call `useFetchGroupOrPreviewEdgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGroupOrPreviewEdgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGroupOrPreviewEdgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchGroupOrPreviewEdgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchGroupOrPreviewEdgeQuery,
    FetchGroupOrPreviewEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchGroupOrPreviewEdgeQuery,
    FetchGroupOrPreviewEdgeQueryVariables
  >(FetchGroupOrPreviewEdgeDocument, options);
}
export function useFetchGroupOrPreviewEdgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchGroupOrPreviewEdgeQuery,
    FetchGroupOrPreviewEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchGroupOrPreviewEdgeQuery,
    FetchGroupOrPreviewEdgeQueryVariables
  >(FetchGroupOrPreviewEdgeDocument, options);
}
export type FetchGroupOrPreviewEdgeQueryHookResult = ReturnType<
  typeof useFetchGroupOrPreviewEdgeQuery
>;
export type FetchGroupOrPreviewEdgeLazyQueryHookResult = ReturnType<
  typeof useFetchGroupOrPreviewEdgeLazyQuery
>;
export type FetchGroupOrPreviewEdgeQueryResult = Apollo.QueryResult<
  FetchGroupOrPreviewEdgeQuery,
  FetchGroupOrPreviewEdgeQueryVariables
>;
export const FetchGroupsDocument = gql`
  query fetchGroups($limit: Int = 100) {
    groups(last: $limit) {
      edges {
        ...GroupEdgeSimpleFields
        node {
          ...GroupSimpleFields
        }
      }
    }
  }
  ${GroupEdgeSimpleFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
`;

/**
 * __useFetchGroupsQuery__
 *
 * To run a query within a React component, call `useFetchGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGroupsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFetchGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchGroupsQuery,
    FetchGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchGroupsQuery, FetchGroupsQueryVariables>(
    FetchGroupsDocument,
    options
  );
}
export function useFetchGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchGroupsQuery,
    FetchGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchGroupsQuery, FetchGroupsQueryVariables>(
    FetchGroupsDocument,
    options
  );
}
export type FetchGroupsQueryHookResult = ReturnType<typeof useFetchGroupsQuery>;
export type FetchGroupsLazyQueryHookResult = ReturnType<
  typeof useFetchGroupsLazyQuery
>;
export type FetchGroupsQueryResult = Apollo.QueryResult<
  FetchGroupsQuery,
  FetchGroupsQueryVariables
>;
export const AddThreadRecipientsDocument = gql`
  mutation AddThreadRecipients($id: ID!, $recipients: [RecipientRoleInput!]!) {
    addThreadRecipients(id: $id, recipients: $recipients) {
      ...ThreadFields
    }
  }
  ${ThreadFieldsFragmentDoc}
`;
export type AddThreadRecipientsMutationFn = Apollo.MutationFunction<
  AddThreadRecipientsMutation,
  AddThreadRecipientsMutationVariables
>;

/**
 * __useAddThreadRecipientsMutation__
 *
 * To run a mutation, you first call `useAddThreadRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThreadRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThreadRecipientsMutation, { data, loading, error }] = useAddThreadRecipientsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recipients: // value for 'recipients'
 *   },
 * });
 */
export function useAddThreadRecipientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddThreadRecipientsMutation,
    AddThreadRecipientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddThreadRecipientsMutation,
    AddThreadRecipientsMutationVariables
  >(AddThreadRecipientsDocument, options);
}
export type AddThreadRecipientsMutationHookResult = ReturnType<
  typeof useAddThreadRecipientsMutation
>;
export type AddThreadRecipientsMutationResult =
  Apollo.MutationResult<AddThreadRecipientsMutation>;
export type AddThreadRecipientsMutationOptions = Apollo.BaseMutationOptions<
  AddThreadRecipientsMutation,
  AddThreadRecipientsMutationVariables
>;
export const RemoveThreadRecipientsDocument = gql`
  mutation RemoveThreadRecipients($id: ID!, $recipientIDs: [ID!]!) {
    removeThreadRecipients(id: $id, recipientIDs: $recipientIDs) {
      ...ThreadFields
    }
  }
  ${ThreadFieldsFragmentDoc}
`;
export type RemoveThreadRecipientsMutationFn = Apollo.MutationFunction<
  RemoveThreadRecipientsMutation,
  RemoveThreadRecipientsMutationVariables
>;

/**
 * __useRemoveThreadRecipientsMutation__
 *
 * To run a mutation, you first call `useRemoveThreadRecipientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveThreadRecipientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeThreadRecipientsMutation, { data, loading, error }] = useRemoveThreadRecipientsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      recipientIDs: // value for 'recipientIDs'
 *   },
 * });
 */
export function useRemoveThreadRecipientsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveThreadRecipientsMutation,
    RemoveThreadRecipientsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveThreadRecipientsMutation,
    RemoveThreadRecipientsMutationVariables
  >(RemoveThreadRecipientsDocument, options);
}
export type RemoveThreadRecipientsMutationHookResult = ReturnType<
  typeof useRemoveThreadRecipientsMutation
>;
export type RemoveThreadRecipientsMutationResult =
  Apollo.MutationResult<RemoveThreadRecipientsMutation>;
export type RemoveThreadRecipientsMutationOptions = Apollo.BaseMutationOptions<
  RemoveThreadRecipientsMutation,
  RemoveThreadRecipientsMutationVariables
>;
export const RequestJoinThreadDocument = gql`
  mutation requestJoinThread($input: JoinRequestInput!) {
    sendJoinRequest(input: $input) {
      id
    }
  }
`;
export type RequestJoinThreadMutationFn = Apollo.MutationFunction<
  RequestJoinThreadMutation,
  RequestJoinThreadMutationVariables
>;

/**
 * __useRequestJoinThreadMutation__
 *
 * To run a mutation, you first call `useRequestJoinThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestJoinThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestJoinThreadMutation, { data, loading, error }] = useRequestJoinThreadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRequestJoinThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestJoinThreadMutation,
    RequestJoinThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestJoinThreadMutation,
    RequestJoinThreadMutationVariables
  >(RequestJoinThreadDocument, options);
}
export type RequestJoinThreadMutationHookResult = ReturnType<
  typeof useRequestJoinThreadMutation
>;
export type RequestJoinThreadMutationResult =
  Apollo.MutationResult<RequestJoinThreadMutation>;
export type RequestJoinThreadMutationOptions = Apollo.BaseMutationOptions<
  RequestJoinThreadMutation,
  RequestJoinThreadMutationVariables
>;
export const CancelRequestJoinThreadDocument = gql`
  mutation cancelRequestJoinThread($id: ID!) {
    cancelJoinApproval(id: $id)
  }
`;
export type CancelRequestJoinThreadMutationFn = Apollo.MutationFunction<
  CancelRequestJoinThreadMutation,
  CancelRequestJoinThreadMutationVariables
>;

/**
 * __useCancelRequestJoinThreadMutation__
 *
 * To run a mutation, you first call `useCancelRequestJoinThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestJoinThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestJoinThreadMutation, { data, loading, error }] = useCancelRequestJoinThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRequestJoinThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRequestJoinThreadMutation,
    CancelRequestJoinThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRequestJoinThreadMutation,
    CancelRequestJoinThreadMutationVariables
  >(CancelRequestJoinThreadDocument, options);
}
export type CancelRequestJoinThreadMutationHookResult = ReturnType<
  typeof useCancelRequestJoinThreadMutation
>;
export type CancelRequestJoinThreadMutationResult =
  Apollo.MutationResult<CancelRequestJoinThreadMutation>;
export type CancelRequestJoinThreadMutationOptions = Apollo.BaseMutationOptions<
  CancelRequestJoinThreadMutation,
  CancelRequestJoinThreadMutationVariables
>;
export const NotInThreadDocument = gql`
  query NotInThread($id: ID!, $joinableID: ID!) {
    node(id: $id) {
      id
      ... on ThreadEdge {
        node {
          ...ThreadSimpleFields
        }
        ...ThreadEdgeFields
      }
      ... on ThreadPreviewEdge {
        node {
          ...ThreadPreviewFields
        }
      }
    }
    joinRequest(joinableID: $joinableID) {
      id
    }
  }
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;

/**
 * __useNotInThreadQuery__
 *
 * To run a query within a React component, call `useNotInThreadQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotInThreadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotInThreadQuery({
 *   variables: {
 *      id: // value for 'id'
 *      joinableID: // value for 'joinableID'
 *   },
 * });
 */
export function useNotInThreadQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotInThreadQuery,
    NotInThreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotInThreadQuery, NotInThreadQueryVariables>(
    NotInThreadDocument,
    options
  );
}
export function useNotInThreadLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotInThreadQuery,
    NotInThreadQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotInThreadQuery, NotInThreadQueryVariables>(
    NotInThreadDocument,
    options
  );
}
export type NotInThreadQueryHookResult = ReturnType<typeof useNotInThreadQuery>;
export type NotInThreadLazyQueryHookResult = ReturnType<
  typeof useNotInThreadLazyQuery
>;
export type NotInThreadQueryResult = Apollo.QueryResult<
  NotInThreadQuery,
  NotInThreadQueryVariables
>;
export const SaveDraftDocument = gql`
  mutation SaveDraft($id: ID, $input: SaveDraftInput!) {
    saveDraft(id: $id, input: $input) {
      ...DraftFields
    }
  }
  ${DraftFieldsFragmentDoc}
`;
export type SaveDraftMutationFn = Apollo.MutationFunction<
  SaveDraftMutation,
  SaveDraftMutationVariables
>;

/**
 * __useSaveDraftMutation__
 *
 * To run a mutation, you first call `useSaveDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveDraftMutation, { data, loading, error }] = useSaveDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveDraftMutation,
    SaveDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveDraftMutation, SaveDraftMutationVariables>(
    SaveDraftDocument,
    options
  );
}
export type SaveDraftMutationHookResult = ReturnType<
  typeof useSaveDraftMutation
>;
export type SaveDraftMutationResult = Apollo.MutationResult<SaveDraftMutation>;
export type SaveDraftMutationOptions = Apollo.BaseMutationOptions<
  SaveDraftMutation,
  SaveDraftMutationVariables
>;
export const SendDraftDocument = gql`
  mutation SendDraft($id: ID, $input: SaveDraftInput!) {
    sendDraft(id: $id, input: $input) {
      ...ThreadFields
    }
  }
  ${ThreadFieldsFragmentDoc}
`;
export type SendDraftMutationFn = Apollo.MutationFunction<
  SendDraftMutation,
  SendDraftMutationVariables
>;

/**
 * __useSendDraftMutation__
 *
 * To run a mutation, you first call `useSendDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDraftMutation, { data, loading, error }] = useSendDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendDraftMutation,
    SendDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendDraftMutation, SendDraftMutationVariables>(
    SendDraftDocument,
    options
  );
}
export type SendDraftMutationHookResult = ReturnType<
  typeof useSendDraftMutation
>;
export type SendDraftMutationResult = Apollo.MutationResult<SendDraftMutation>;
export type SendDraftMutationOptions = Apollo.BaseMutationOptions<
  SendDraftMutation,
  SendDraftMutationVariables
>;
export const DeleteDraftDocument = gql`
  mutation DeleteDraft($id: ID!) {
    deleteDraft(id: $id)
  }
`;
export type DeleteDraftMutationFn = Apollo.MutationFunction<
  DeleteDraftMutation,
  DeleteDraftMutationVariables
>;

/**
 * __useDeleteDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftMutation, { data, loading, error }] = useDeleteDraftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDraftMutation,
    DeleteDraftMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(
    DeleteDraftDocument,
    options
  );
}
export type DeleteDraftMutationHookResult = ReturnType<
  typeof useDeleteDraftMutation
>;
export type DeleteDraftMutationResult =
  Apollo.MutationResult<DeleteDraftMutation>;
export type DeleteDraftMutationOptions = Apollo.BaseMutationOptions<
  DeleteDraftMutation,
  DeleteDraftMutationVariables
>;
export const SendMessageDocument = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      message {
        ...MessageFields
      }
      thread {
        ...ThreadFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
  ${ThreadFieldsFragmentDoc}
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  SendMessageMutation,
  SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendMessageMutation,
    SendMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(
    SendMessageDocument,
    options
  );
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  SendMessageMutation,
  SendMessageMutationVariables
>;
export const FetchDraftDocument = gql`
  query FetchDraft($draftID: ID!) {
    node(id: $draftID) {
      id
      ... on Draft {
        ...DraftFields
      }
    }
  }
  ${DraftFieldsFragmentDoc}
`;

/**
 * __useFetchDraftQuery__
 *
 * To run a query within a React component, call `useFetchDraftQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDraftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDraftQuery({
 *   variables: {
 *      draftID: // value for 'draftID'
 *   },
 * });
 */
export function useFetchDraftQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchDraftQuery,
    FetchDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDraftQuery, FetchDraftQueryVariables>(
    FetchDraftDocument,
    options
  );
}
export function useFetchDraftLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDraftQuery,
    FetchDraftQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDraftQuery, FetchDraftQueryVariables>(
    FetchDraftDocument,
    options
  );
}
export type FetchDraftQueryHookResult = ReturnType<typeof useFetchDraftQuery>;
export type FetchDraftLazyQueryHookResult = ReturnType<
  typeof useFetchDraftLazyQuery
>;
export type FetchDraftQueryResult = Apollo.QueryResult<
  FetchDraftQuery,
  FetchDraftQueryVariables
>;
export const FetchJoinApprovalsDocument = gql`
  query FetchJoinApprovals($membersLimit: Int = 5, $membersAfter: String) {
    joinApprovals {
      totalCount
      edges {
        node {
          ...JoinApprovalFields
        }
      }
    }
  }
  ${JoinApprovalFieldsFragmentDoc}
`;

/**
 * __useFetchJoinApprovalsQuery__
 *
 * To run a query within a React component, call `useFetchJoinApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJoinApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJoinApprovalsQuery({
 *   variables: {
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *   },
 * });
 */
export function useFetchJoinApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchJoinApprovalsQuery,
    FetchJoinApprovalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchJoinApprovalsQuery,
    FetchJoinApprovalsQueryVariables
  >(FetchJoinApprovalsDocument, options);
}
export function useFetchJoinApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchJoinApprovalsQuery,
    FetchJoinApprovalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchJoinApprovalsQuery,
    FetchJoinApprovalsQueryVariables
  >(FetchJoinApprovalsDocument, options);
}
export type FetchJoinApprovalsQueryHookResult = ReturnType<
  typeof useFetchJoinApprovalsQuery
>;
export type FetchJoinApprovalsLazyQueryHookResult = ReturnType<
  typeof useFetchJoinApprovalsLazyQuery
>;
export type FetchJoinApprovalsQueryResult = Apollo.QueryResult<
  FetchJoinApprovalsQuery,
  FetchJoinApprovalsQueryVariables
>;
export const ThreadListDocument = gql`
  query ThreadList(
    $mailbox: ThreadsMailbox = all
    $recipientID: ID
    $excludeChats: Boolean
    $excludeStarred: Boolean
    $limit: Int = 15
    $before: String
    $order: ThreadsOrder
  ) {
    threads(
      filter: {
        excludePersistentChats: $excludeChats
        excludeStarred: $excludeStarred
        mailbox: $mailbox
        recipientID: $recipientID
      }
      last: $limit
      before: $before
      order: $order
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        ...ThreadEdgeFields
        node {
          ...ThreadSimpleFields
        }
      }
    }
  }
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
`;

/**
 * __useThreadListQuery__
 *
 * To run a query within a React component, call `useThreadListQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadListQuery({
 *   variables: {
 *      mailbox: // value for 'mailbox'
 *      recipientID: // value for 'recipientID'
 *      excludeChats: // value for 'excludeChats'
 *      excludeStarred: // value for 'excludeStarred'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useThreadListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThreadListQuery,
    ThreadListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadListQuery, ThreadListQueryVariables>(
    ThreadListDocument,
    options
  );
}
export function useThreadListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadListQuery,
    ThreadListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadListQuery, ThreadListQueryVariables>(
    ThreadListDocument,
    options
  );
}
export type ThreadListQueryHookResult = ReturnType<typeof useThreadListQuery>;
export type ThreadListLazyQueryHookResult = ReturnType<
  typeof useThreadListLazyQuery
>;
export type ThreadListQueryResult = Apollo.QueryResult<
  ThreadListQuery,
  ThreadListQueryVariables
>;
export const ThreadFeedDocument = gql`
  query ThreadFeed(
    $mailbox: ThreadsMailbox = all
    $recipientID: ID
    $excludeChats: Boolean
    $excludeStarred: Boolean
    $limit: Int = 15
    $before: String
    $order: ThreadsOrder
    $includeLastRead: Boolean = false
    $beforeLastRead: String
    $beforeLastUnread: String
    $lastReadLimit: Int = 1
    $lastUnreadLimit: Int = 1
  ) {
    threads(
      filter: {
        excludePersistentChats: $excludeChats
        excludeStarred: $excludeStarred
        mailbox: $mailbox
        recipientID: $recipientID
      }
      last: $limit
      before: $before
      order: $order
    ) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        ...ThreadEdgeFields
        node {
          ...ThreadInFeedFields
        }
      }
    }
  }
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadInFeedFieldsFragmentDoc}
`;

/**
 * __useThreadFeedQuery__
 *
 * To run a query within a React component, call `useThreadFeedQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadFeedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadFeedQuery({
 *   variables: {
 *      mailbox: // value for 'mailbox'
 *      recipientID: // value for 'recipientID'
 *      excludeChats: // value for 'excludeChats'
 *      excludeStarred: // value for 'excludeStarred'
 *      limit: // value for 'limit'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *      includeLastRead: // value for 'includeLastRead'
 *      beforeLastRead: // value for 'beforeLastRead'
 *      beforeLastUnread: // value for 'beforeLastUnread'
 *      lastReadLimit: // value for 'lastReadLimit'
 *      lastUnreadLimit: // value for 'lastUnreadLimit'
 *   },
 * });
 */
export function useThreadFeedQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ThreadFeedQuery,
    ThreadFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadFeedQuery, ThreadFeedQueryVariables>(
    ThreadFeedDocument,
    options
  );
}
export function useThreadFeedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadFeedQuery,
    ThreadFeedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadFeedQuery, ThreadFeedQueryVariables>(
    ThreadFeedDocument,
    options
  );
}
export type ThreadFeedQueryHookResult = ReturnType<typeof useThreadFeedQuery>;
export type ThreadFeedLazyQueryHookResult = ReturnType<
  typeof useThreadFeedLazyQuery
>;
export type ThreadFeedQueryResult = Apollo.QueryResult<
  ThreadFeedQuery,
  ThreadFeedQueryVariables
>;
export const DraftListDocument = gql`
  query DraftList($last: Int, $before: String) {
    drafts(last: $last, before: $before) {
      totalCount
      edges {
        node {
          ...DraftFields
        }
      }
      pageInfo {
        hasPreviousPage
        startCursor
      }
    }
  }
  ${DraftFieldsFragmentDoc}
`;

/**
 * __useDraftListQuery__
 *
 * To run a query within a React component, call `useDraftListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftListQuery({
 *   variables: {
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useDraftListQuery(
  baseOptions?: Apollo.QueryHookOptions<DraftListQuery, DraftListQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DraftListQuery, DraftListQueryVariables>(
    DraftListDocument,
    options
  );
}
export function useDraftListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DraftListQuery,
    DraftListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DraftListQuery, DraftListQueryVariables>(
    DraftListDocument,
    options
  );
}
export type DraftListQueryHookResult = ReturnType<typeof useDraftListQuery>;
export type DraftListLazyQueryHookResult = ReturnType<
  typeof useDraftListLazyQuery
>;
export type DraftListQueryResult = Apollo.QueryResult<
  DraftListQuery,
  DraftListQueryVariables
>;
export const UpdateThreadDocument = gql`
  mutation UpdateThread($id: ID!, $input: UpdateThreadInput!) {
    updateThread(id: $id, input: $input) {
      ...ThreadFields
    }
  }
  ${ThreadFieldsFragmentDoc}
`;
export type UpdateThreadMutationFn = Apollo.MutationFunction<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;

/**
 * __useUpdateThreadMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMutation, { data, loading, error }] = useUpdateThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadMutation,
    UpdateThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThreadMutation,
    UpdateThreadMutationVariables
  >(UpdateThreadDocument, options);
}
export type UpdateThreadMutationHookResult = ReturnType<
  typeof useUpdateThreadMutation
>;
export type UpdateThreadMutationResult =
  Apollo.MutationResult<UpdateThreadMutation>;
export type UpdateThreadMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadMutation,
  UpdateThreadMutationVariables
>;
export const LeaveThreadDocument = gql`
  mutation LeaveThread($id: ID!) {
    leaveThread(id: $id)
  }
`;
export type LeaveThreadMutationFn = Apollo.MutationFunction<
  LeaveThreadMutation,
  LeaveThreadMutationVariables
>;

/**
 * __useLeaveThreadMutation__
 *
 * To run a mutation, you first call `useLeaveThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveThreadMutation, { data, loading, error }] = useLeaveThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveThreadMutation,
    LeaveThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LeaveThreadMutation, LeaveThreadMutationVariables>(
    LeaveThreadDocument,
    options
  );
}
export type LeaveThreadMutationHookResult = ReturnType<
  typeof useLeaveThreadMutation
>;
export type LeaveThreadMutationResult =
  Apollo.MutationResult<LeaveThreadMutation>;
export type LeaveThreadMutationOptions = Apollo.BaseMutationOptions<
  LeaveThreadMutation,
  LeaveThreadMutationVariables
>;
export const DeleteThreadDocument = gql`
  mutation DeleteThread($id: ID!) {
    deleteThread(id: $id)
  }
`;
export type DeleteThreadMutationFn = Apollo.MutationFunction<
  DeleteThreadMutation,
  DeleteThreadMutationVariables
>;

/**
 * __useDeleteThreadMutation__
 *
 * To run a mutation, you first call `useDeleteThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteThreadMutation, { data, loading, error }] = useDeleteThreadMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteThreadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteThreadMutation,
    DeleteThreadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteThreadMutation,
    DeleteThreadMutationVariables
  >(DeleteThreadDocument, options);
}
export type DeleteThreadMutationHookResult = ReturnType<
  typeof useDeleteThreadMutation
>;
export type DeleteThreadMutationResult =
  Apollo.MutationResult<DeleteThreadMutation>;
export type DeleteThreadMutationOptions = Apollo.BaseMutationOptions<
  DeleteThreadMutation,
  DeleteThreadMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation DeleteMessage($id: ID!) {
    deleteMessage(id: $id)
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteMessageMutation,
    DeleteMessageMutationVariables
  >(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<
  typeof useDeleteMessageMutation
>;
export type DeleteMessageMutationResult =
  Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const FetchThreadEdgeDocument = gql`
  query FetchThreadEdge($id: ID!) {
    node(id: $id) {
      id
      ... on ThreadEdge {
        node {
          ...ThreadFields
        }
        ...ThreadEdgeFields
      }
      ... on ThreadPreviewEdge {
        node {
          ...ThreadPreviewFields
        }
      }
    }
  }
  ${ThreadFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;

/**
 * __useFetchThreadEdgeQuery__
 *
 * To run a query within a React component, call `useFetchThreadEdgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchThreadEdgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchThreadEdgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchThreadEdgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchThreadEdgeQuery,
    FetchThreadEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchThreadEdgeQuery, FetchThreadEdgeQueryVariables>(
    FetchThreadEdgeDocument,
    options
  );
}
export function useFetchThreadEdgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchThreadEdgeQuery,
    FetchThreadEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchThreadEdgeQuery,
    FetchThreadEdgeQueryVariables
  >(FetchThreadEdgeDocument, options);
}
export type FetchThreadEdgeQueryHookResult = ReturnType<
  typeof useFetchThreadEdgeQuery
>;
export type FetchThreadEdgeLazyQueryHookResult = ReturnType<
  typeof useFetchThreadEdgeLazyQuery
>;
export type FetchThreadEdgeQueryResult = Apollo.QueryResult<
  FetchThreadEdgeQuery,
  FetchThreadEdgeQueryVariables
>;
export const ThreadMessagesDocument = gql`
  query ThreadMessages($id: ID!, $last: Int, $before: String) {
    node(id: $id) {
      id
      ... on Thread {
        messages(last: $last, before: $before) {
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            id
            node {
              ...MessageFields
            }
          }
        }
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`;

/**
 * __useThreadMessagesQuery__
 *
 * To run a query within a React component, call `useThreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useThreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThreadMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useThreadMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ThreadMessagesQuery,
    ThreadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ThreadMessagesQuery, ThreadMessagesQueryVariables>(
    ThreadMessagesDocument,
    options
  );
}
export function useThreadMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ThreadMessagesQuery,
    ThreadMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ThreadMessagesQuery, ThreadMessagesQueryVariables>(
    ThreadMessagesDocument,
    options
  );
}
export type ThreadMessagesQueryHookResult = ReturnType<
  typeof useThreadMessagesQuery
>;
export type ThreadMessagesLazyQueryHookResult = ReturnType<
  typeof useThreadMessagesLazyQuery
>;
export type ThreadMessagesQueryResult = Apollo.QueryResult<
  ThreadMessagesQuery,
  ThreadMessagesQueryVariables
>;
export const FetchThreadEdgePaginatedDocument = gql`
  query FetchThreadEdgePaginated(
    $id: ID!
    $includeLastRead: Boolean = false
    $beforeLastRead: String
    $beforeLastUnread: String
    $lastReadLimit: Int = 1
    $lastUnreadLimit: Int = 1
  ) {
    node(id: $id) {
      id
      ... on ThreadEdge {
        node {
          ...ThreadInFeedFields
        }
        ...ThreadEdgeFields
      }
      ... on ThreadPreviewEdge {
        node {
          ...ThreadPreviewFields
        }
      }
    }
  }
  ${ThreadInFeedFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;

/**
 * __useFetchThreadEdgePaginatedQuery__
 *
 * To run a query within a React component, call `useFetchThreadEdgePaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchThreadEdgePaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchThreadEdgePaginatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      includeLastRead: // value for 'includeLastRead'
 *      beforeLastRead: // value for 'beforeLastRead'
 *      beforeLastUnread: // value for 'beforeLastUnread'
 *      lastReadLimit: // value for 'lastReadLimit'
 *      lastUnreadLimit: // value for 'lastUnreadLimit'
 *   },
 * });
 */
export function useFetchThreadEdgePaginatedQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchThreadEdgePaginatedQuery,
    FetchThreadEdgePaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchThreadEdgePaginatedQuery,
    FetchThreadEdgePaginatedQueryVariables
  >(FetchThreadEdgePaginatedDocument, options);
}
export function useFetchThreadEdgePaginatedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchThreadEdgePaginatedQuery,
    FetchThreadEdgePaginatedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchThreadEdgePaginatedQuery,
    FetchThreadEdgePaginatedQueryVariables
  >(FetchThreadEdgePaginatedDocument, options);
}
export type FetchThreadEdgePaginatedQueryHookResult = ReturnType<
  typeof useFetchThreadEdgePaginatedQuery
>;
export type FetchThreadEdgePaginatedLazyQueryHookResult = ReturnType<
  typeof useFetchThreadEdgePaginatedLazyQuery
>;
export type FetchThreadEdgePaginatedQueryResult = Apollo.QueryResult<
  FetchThreadEdgePaginatedQuery,
  FetchThreadEdgePaginatedQueryVariables
>;
export const FetchPendingJoinApprovalsDocument = gql`
  query FetchPendingJoinApprovals(
    $id: ID
    $membersLimit: Int = 0
    $membersAfter: String
  ) {
    joinApprovals(filter: { joinableID: $id }) {
      totalCount
      edges {
        node {
          ...JoinApprovalFields
        }
      }
    }
    joinRequests(filter: { joinableID: $id }) {
      totalCount
      edges {
        node {
          ...JoinApprovalFields
        }
      }
    }
  }
  ${JoinApprovalFieldsFragmentDoc}
`;

/**
 * __useFetchPendingJoinApprovalsQuery__
 *
 * To run a query within a React component, call `useFetchPendingJoinApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPendingJoinApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPendingJoinApprovalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *   },
 * });
 */
export function useFetchPendingJoinApprovalsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPendingJoinApprovalsQuery,
    FetchPendingJoinApprovalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPendingJoinApprovalsQuery,
    FetchPendingJoinApprovalsQueryVariables
  >(FetchPendingJoinApprovalsDocument, options);
}
export function useFetchPendingJoinApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPendingJoinApprovalsQuery,
    FetchPendingJoinApprovalsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPendingJoinApprovalsQuery,
    FetchPendingJoinApprovalsQueryVariables
  >(FetchPendingJoinApprovalsDocument, options);
}
export type FetchPendingJoinApprovalsQueryHookResult = ReturnType<
  typeof useFetchPendingJoinApprovalsQuery
>;
export type FetchPendingJoinApprovalsLazyQueryHookResult = ReturnType<
  typeof useFetchPendingJoinApprovalsLazyQuery
>;
export type FetchPendingJoinApprovalsQueryResult = Apollo.QueryResult<
  FetchPendingJoinApprovalsQuery,
  FetchPendingJoinApprovalsQueryVariables
>;
export const FetchBoxFilePreviewDocument = gql`
  query FetchBoxFilePreview($id: ID!) {
    filePreview(fileID: $id) {
      boxFileID
      boxAccessToken
    }
  }
`;

/**
 * __useFetchBoxFilePreviewQuery__
 *
 * To run a query within a React component, call `useFetchBoxFilePreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBoxFilePreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBoxFilePreviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchBoxFilePreviewQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchBoxFilePreviewQuery,
    FetchBoxFilePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchBoxFilePreviewQuery,
    FetchBoxFilePreviewQueryVariables
  >(FetchBoxFilePreviewDocument, options);
}
export function useFetchBoxFilePreviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchBoxFilePreviewQuery,
    FetchBoxFilePreviewQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchBoxFilePreviewQuery,
    FetchBoxFilePreviewQueryVariables
  >(FetchBoxFilePreviewDocument, options);
}
export type FetchBoxFilePreviewQueryHookResult = ReturnType<
  typeof useFetchBoxFilePreviewQuery
>;
export type FetchBoxFilePreviewLazyQueryHookResult = ReturnType<
  typeof useFetchBoxFilePreviewLazyQuery
>;
export type FetchBoxFilePreviewQueryResult = Apollo.QueryResult<
  FetchBoxFilePreviewQuery,
  FetchBoxFilePreviewQueryVariables
>;
export const FetchMessageMetadataDocument = gql`
  query FetchMessageMetadata($id: ID!) {
    messageMetadata(messageID: $id) {
      ...MessageMetadataFields
    }
  }
  ${MessageMetadataFieldsFragmentDoc}
`;

/**
 * __useFetchMessageMetadataQuery__
 *
 * To run a query within a React component, call `useFetchMessageMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageMetadataQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchMessageMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMessageMetadataQuery,
    FetchMessageMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchMessageMetadataQuery,
    FetchMessageMetadataQueryVariables
  >(FetchMessageMetadataDocument, options);
}
export function useFetchMessageMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMessageMetadataQuery,
    FetchMessageMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchMessageMetadataQuery,
    FetchMessageMetadataQueryVariables
  >(FetchMessageMetadataDocument, options);
}
export type FetchMessageMetadataQueryHookResult = ReturnType<
  typeof useFetchMessageMetadataQuery
>;
export type FetchMessageMetadataLazyQueryHookResult = ReturnType<
  typeof useFetchMessageMetadataLazyQuery
>;
export type FetchMessageMetadataQueryResult = Apollo.QueryResult<
  FetchMessageMetadataQuery,
  FetchMessageMetadataQueryVariables
>;
export const MessagePinsDocument = gql`
  query MessagePins($threadID: ID!, $first: Int, $after: String) {
    messagePins(filter: { threadID: $threadID }, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          user {
            ... on User {
              ...UserFields
            }
          }
          message {
            ... on Message {
              ...MessageFields
            }
          }
          createdAt
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
`;

/**
 * __useMessagePinsQuery__
 *
 * To run a query within a React component, call `useMessagePinsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagePinsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagePinsQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useMessagePinsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MessagePinsQuery,
    MessagePinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MessagePinsQuery, MessagePinsQueryVariables>(
    MessagePinsDocument,
    options
  );
}
export function useMessagePinsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MessagePinsQuery,
    MessagePinsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MessagePinsQuery, MessagePinsQueryVariables>(
    MessagePinsDocument,
    options
  );
}
export type MessagePinsQueryHookResult = ReturnType<typeof useMessagePinsQuery>;
export type MessagePinsLazyQueryHookResult = ReturnType<
  typeof useMessagePinsLazyQuery
>;
export type MessagePinsQueryResult = Apollo.QueryResult<
  MessagePinsQuery,
  MessagePinsQueryVariables
>;
export const FetchReplyToMessageDocument = gql`
  query FetchReplyToMessage($messageID: ID!, $threadID: ID!) {
    message: node(id: $messageID) {
      ... on Message {
        ...MessageFields
      }
    }
    thread: node(id: $threadID) {
      ... on Thread {
        ...ThreadSimpleFields
      }
      ... on ThreadPreview {
        ...ThreadPreviewFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;

/**
 * __useFetchReplyToMessageQuery__
 *
 * To run a query within a React component, call `useFetchReplyToMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchReplyToMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchReplyToMessageQuery({
 *   variables: {
 *      messageID: // value for 'messageID'
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useFetchReplyToMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchReplyToMessageQuery,
    FetchReplyToMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchReplyToMessageQuery,
    FetchReplyToMessageQueryVariables
  >(FetchReplyToMessageDocument, options);
}
export function useFetchReplyToMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchReplyToMessageQuery,
    FetchReplyToMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchReplyToMessageQuery,
    FetchReplyToMessageQueryVariables
  >(FetchReplyToMessageDocument, options);
}
export type FetchReplyToMessageQueryHookResult = ReturnType<
  typeof useFetchReplyToMessageQuery
>;
export type FetchReplyToMessageLazyQueryHookResult = ReturnType<
  typeof useFetchReplyToMessageLazyQuery
>;
export type FetchReplyToMessageQueryResult = Apollo.QueryResult<
  FetchReplyToMessageQuery,
  FetchReplyToMessageQueryVariables
>;
export const SubjectSuggestionDocument = gql`
  query SubjectSuggestion($replyToMessageID: ID, $input: SendMessageInput) {
    draftSuggestion(replyToMessageID: $replyToMessageID, input: $input) {
      subject
    }
  }
`;

/**
 * __useSubjectSuggestionQuery__
 *
 * To run a query within a React component, call `useSubjectSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubjectSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubjectSuggestionQuery({
 *   variables: {
 *      replyToMessageID: // value for 'replyToMessageID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubjectSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SubjectSuggestionQuery,
    SubjectSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SubjectSuggestionQuery,
    SubjectSuggestionQueryVariables
  >(SubjectSuggestionDocument, options);
}
export function useSubjectSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SubjectSuggestionQuery,
    SubjectSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SubjectSuggestionQuery,
    SubjectSuggestionQueryVariables
  >(SubjectSuggestionDocument, options);
}
export type SubjectSuggestionQueryHookResult = ReturnType<
  typeof useSubjectSuggestionQuery
>;
export type SubjectSuggestionLazyQueryHookResult = ReturnType<
  typeof useSubjectSuggestionLazyQuery
>;
export type SubjectSuggestionQueryResult = Apollo.QueryResult<
  SubjectSuggestionQuery,
  SubjectSuggestionQueryVariables
>;
export const RecipientsSuggestionDocument = gql`
  query RecipientsSuggestion($replyToMessageID: ID, $input: SendMessageInput) {
    draftSuggestion(replyToMessageID: $replyToMessageID, input: $input) {
      recipients
    }
  }
`;

/**
 * __useRecipientsSuggestionQuery__
 *
 * To run a query within a React component, call `useRecipientsSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipientsSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipientsSuggestionQuery({
 *   variables: {
 *      replyToMessageID: // value for 'replyToMessageID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecipientsSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RecipientsSuggestionQuery,
    RecipientsSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    RecipientsSuggestionQuery,
    RecipientsSuggestionQueryVariables
  >(RecipientsSuggestionDocument, options);
}
export function useRecipientsSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RecipientsSuggestionQuery,
    RecipientsSuggestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RecipientsSuggestionQuery,
    RecipientsSuggestionQueryVariables
  >(RecipientsSuggestionDocument, options);
}
export type RecipientsSuggestionQueryHookResult = ReturnType<
  typeof useRecipientsSuggestionQuery
>;
export type RecipientsSuggestionLazyQueryHookResult = ReturnType<
  typeof useRecipientsSuggestionLazyQuery
>;
export type RecipientsSuggestionQueryResult = Apollo.QueryResult<
  RecipientsSuggestionQuery,
  RecipientsSuggestionQueryVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($input: UpdateMeInput!) {
    updateMe(input: $input) {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProfileMutation,
    UpdateProfileMutationVariables
  >(UpdateProfileDocument, options);
}
export type UpdateProfileMutationHookResult = ReturnType<
  typeof useUpdateProfileMutation
>;
export type UpdateProfileMutationResult =
  Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation DeleteAccount($input: DeleteMeInput!) {
    deleteMe(input: $input)
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAccountMutation,
    DeleteAccountMutationVariables
  >(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<
  typeof useDeleteAccountMutation
>;
export type DeleteAccountMutationResult =
  Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const UpdateSettingsDocument = gql`
  mutation updateSettings($settings: UpdateSettingsInput!) {
    updateSettings(input: $settings) {
      ...SettingsFields
    }
  }
  ${SettingsFieldsFragmentDoc}
`;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables
>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSettingsMutation,
    UpdateSettingsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSettingsMutation,
    UpdateSettingsMutationVariables
  >(UpdateSettingsDocument, options);
}
export type UpdateSettingsMutationHookResult = ReturnType<
  typeof useUpdateSettingsMutation
>;
export type UpdateSettingsMutationResult =
  Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSettingsMutation,
  UpdateSettingsMutationVariables
>;
export const UserSettingsDocument = gql`
  query UserSettings {
    settings {
      ...SettingsFields
    }
  }
  ${SettingsFieldsFragmentDoc}
`;

/**
 * __useUserSettingsQuery__
 *
 * To run a query within a React component, call `useUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export function useUserSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserSettingsQuery,
    UserSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserSettingsQuery, UserSettingsQueryVariables>(
    UserSettingsDocument,
    options
  );
}
export type UserSettingsQueryHookResult = ReturnType<
  typeof useUserSettingsQuery
>;
export type UserSettingsLazyQueryHookResult = ReturnType<
  typeof useUserSettingsLazyQuery
>;
export type UserSettingsQueryResult = Apollo.QueryResult<
  UserSettingsQuery,
  UserSettingsQueryVariables
>;
export const FetchLlmModelsDocument = gql`
  query FetchLlmModels($workspaceID: ID!) {
    llmModels(workspaceID: $workspaceID) {
      edges {
        node {
          ...LLMModel
        }
      }
    }
  }
  ${LlmModelFragmentDoc}
`;

/**
 * __useFetchLlmModelsQuery__
 *
 * To run a query within a React component, call `useFetchLlmModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLlmModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLlmModelsQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useFetchLlmModelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchLlmModelsQuery,
    FetchLlmModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchLlmModelsQuery, FetchLlmModelsQueryVariables>(
    FetchLlmModelsDocument,
    options
  );
}
export function useFetchLlmModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchLlmModelsQuery,
    FetchLlmModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchLlmModelsQuery, FetchLlmModelsQueryVariables>(
    FetchLlmModelsDocument,
    options
  );
}
export type FetchLlmModelsQueryHookResult = ReturnType<
  typeof useFetchLlmModelsQuery
>;
export type FetchLlmModelsLazyQueryHookResult = ReturnType<
  typeof useFetchLlmModelsLazyQuery
>;
export type FetchLlmModelsQueryResult = Apollo.QueryResult<
  FetchLlmModelsQuery,
  FetchLlmModelsQueryVariables
>;
export const CreatePersistentChatDocument = gql`
  mutation CreatePersistentChat($input: CreatePersistentChatInput!) {
    createPersistentChat(input: $input) {
      ...ThreadFields
    }
  }
  ${ThreadFieldsFragmentDoc}
`;
export type CreatePersistentChatMutationFn = Apollo.MutationFunction<
  CreatePersistentChatMutation,
  CreatePersistentChatMutationVariables
>;

/**
 * __useCreatePersistentChatMutation__
 *
 * To run a mutation, you first call `useCreatePersistentChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersistentChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersistentChatMutation, { data, loading, error }] = useCreatePersistentChatMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePersistentChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersistentChatMutation,
    CreatePersistentChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePersistentChatMutation,
    CreatePersistentChatMutationVariables
  >(CreatePersistentChatDocument, options);
}
export type CreatePersistentChatMutationHookResult = ReturnType<
  typeof useCreatePersistentChatMutation
>;
export type CreatePersistentChatMutationResult =
  Apollo.MutationResult<CreatePersistentChatMutation>;
export type CreatePersistentChatMutationOptions = Apollo.BaseMutationOptions<
  CreatePersistentChatMutation,
  CreatePersistentChatMutationVariables
>;
export const UpdateUserEdgeDocument = gql`
  mutation UpdateUserEdge($id: ID!, $input: UpdateUserEdgeInput!) {
    updateUserEdge(id: $id, input: $input) {
      id
      ...UserEdgeFields
      node {
        ...UserFields
      }
    }
  }
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;
export type UpdateUserEdgeMutationFn = Apollo.MutationFunction<
  UpdateUserEdgeMutation,
  UpdateUserEdgeMutationVariables
>;

/**
 * __useUpdateUserEdgeMutation__
 *
 * To run a mutation, you first call `useUpdateUserEdgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEdgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEdgeMutation, { data, loading, error }] = useUpdateUserEdgeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEdgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEdgeMutation,
    UpdateUserEdgeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserEdgeMutation,
    UpdateUserEdgeMutationVariables
  >(UpdateUserEdgeDocument, options);
}
export type UpdateUserEdgeMutationHookResult = ReturnType<
  typeof useUpdateUserEdgeMutation
>;
export type UpdateUserEdgeMutationResult =
  Apollo.MutationResult<UpdateUserEdgeMutation>;
export type UpdateUserEdgeMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEdgeMutation,
  UpdateUserEdgeMutationVariables
>;
export const FetchUserEdgeDocument = gql`
  query FetchUserEdge($id: ID!) {
    node(id: $id) {
      id
      ... on UserEdge {
        ...UserEdgeFields
        node {
          ...UserFields
        }
      }
    }
  }
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useFetchUserEdgeQuery__
 *
 * To run a query within a React component, call `useFetchUserEdgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserEdgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserEdgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchUserEdgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchUserEdgeQuery,
    FetchUserEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUserEdgeQuery, FetchUserEdgeQueryVariables>(
    FetchUserEdgeDocument,
    options
  );
}
export function useFetchUserEdgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserEdgeQuery,
    FetchUserEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUserEdgeQuery, FetchUserEdgeQueryVariables>(
    FetchUserEdgeDocument,
    options
  );
}
export type FetchUserEdgeQueryHookResult = ReturnType<
  typeof useFetchUserEdgeQuery
>;
export type FetchUserEdgeLazyQueryHookResult = ReturnType<
  typeof useFetchUserEdgeLazyQuery
>;
export type FetchUserEdgeQueryResult = Apollo.QueryResult<
  FetchUserEdgeQuery,
  FetchUserEdgeQueryVariables
>;
export const FetchUsersDocument = gql`
  query FetchUsers(
    $after: String
    $filter: UsersFilter
    $first: Int
    $order: UsersOrder
  ) {
    users(after: $after, filter: $filter, first: $first, order: $order) {
      edges {
        ...UserEdgeFields
        node {
          ...UserFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useFetchUsersQuery__
 *
 * To run a query within a React component, call `useFetchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      filter: // value for 'filter'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useFetchUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchUsersQuery,
    FetchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchUsersQuery, FetchUsersQueryVariables>(
    FetchUsersDocument,
    options
  );
}
export function useFetchUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUsersQuery,
    FetchUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchUsersQuery, FetchUsersQueryVariables>(
    FetchUsersDocument,
    options
  );
}
export type FetchUsersQueryHookResult = ReturnType<typeof useFetchUsersQuery>;
export type FetchUsersLazyQueryHookResult = ReturnType<
  typeof useFetchUsersLazyQuery
>;
export type FetchUsersQueryResult = Apollo.QueryResult<
  FetchUsersQuery,
  FetchUsersQueryVariables
>;
export const ApproveJoinApprovalDocument = gql`
  mutation ApproveJoinApproval($joinApprovalID: ID!) {
    approveJoinApproval(id: $joinApprovalID) {
      id
    }
  }
`;
export type ApproveJoinApprovalMutationFn = Apollo.MutationFunction<
  ApproveJoinApprovalMutation,
  ApproveJoinApprovalMutationVariables
>;

/**
 * __useApproveJoinApprovalMutation__
 *
 * To run a mutation, you first call `useApproveJoinApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveJoinApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveJoinApprovalMutation, { data, loading, error }] = useApproveJoinApprovalMutation({
 *   variables: {
 *      joinApprovalID: // value for 'joinApprovalID'
 *   },
 * });
 */
export function useApproveJoinApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApproveJoinApprovalMutation,
    ApproveJoinApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ApproveJoinApprovalMutation,
    ApproveJoinApprovalMutationVariables
  >(ApproveJoinApprovalDocument, options);
}
export type ApproveJoinApprovalMutationHookResult = ReturnType<
  typeof useApproveJoinApprovalMutation
>;
export type ApproveJoinApprovalMutationResult =
  Apollo.MutationResult<ApproveJoinApprovalMutation>;
export type ApproveJoinApprovalMutationOptions = Apollo.BaseMutationOptions<
  ApproveJoinApprovalMutation,
  ApproveJoinApprovalMutationVariables
>;
export const IgnoreJoinApprovalDocument = gql`
  mutation IgnoreJoinApproval($joinApprovalID: ID!) {
    ignoreJoinApproval(id: $joinApprovalID)
  }
`;
export type IgnoreJoinApprovalMutationFn = Apollo.MutationFunction<
  IgnoreJoinApprovalMutation,
  IgnoreJoinApprovalMutationVariables
>;

/**
 * __useIgnoreJoinApprovalMutation__
 *
 * To run a mutation, you first call `useIgnoreJoinApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgnoreJoinApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ignoreJoinApprovalMutation, { data, loading, error }] = useIgnoreJoinApprovalMutation({
 *   variables: {
 *      joinApprovalID: // value for 'joinApprovalID'
 *   },
 * });
 */
export function useIgnoreJoinApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IgnoreJoinApprovalMutation,
    IgnoreJoinApprovalMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    IgnoreJoinApprovalMutation,
    IgnoreJoinApprovalMutationVariables
  >(IgnoreJoinApprovalDocument, options);
}
export type IgnoreJoinApprovalMutationHookResult = ReturnType<
  typeof useIgnoreJoinApprovalMutation
>;
export type IgnoreJoinApprovalMutationResult =
  Apollo.MutationResult<IgnoreJoinApprovalMutation>;
export type IgnoreJoinApprovalMutationOptions = Apollo.BaseMutationOptions<
  IgnoreJoinApprovalMutation,
  IgnoreJoinApprovalMutationVariables
>;
export const NotificationsDocument = gql`
  query Notifications(
    $markEdgesSeen: [ID!]
    $membersLimit: Int = 5
    $membersAfter: String
    $filter: NotificationsFilter
  ) {
    notifications(markEdgesSeen: $markEdgesSeen, filter: $filter) {
      totalCount
      unreadCount
      unseenCount
      edges {
        ...NotificationEdgeFields
        node {
          ...NotificationFields
        }
      }
    }
  }
  ${NotificationEdgeFieldsFragmentDoc}
  ${NotificationFieldsFragmentDoc}
`;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      markEdgesSeen: // value for 'markEdgesSeen'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotificationsQuery,
    NotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  );
}
export type NotificationsQueryHookResult = ReturnType<
  typeof useNotificationsQuery
>;
export type NotificationsLazyQueryHookResult = ReturnType<
  typeof useNotificationsLazyQuery
>;
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>;
export const FetchMessageDocument = gql`
  query FetchMessage($id: ID!) {
    node(id: $id) {
      id
      ... on Message {
        ...MessageFields
      }
    }
  }
  ${MessageFieldsFragmentDoc}
`;

/**
 * __useFetchMessageQuery__
 *
 * To run a query within a React component, call `useFetchMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchMessageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchMessageQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchMessageQuery,
    FetchMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchMessageQuery, FetchMessageQueryVariables>(
    FetchMessageDocument,
    options
  );
}
export function useFetchMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchMessageQuery,
    FetchMessageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchMessageQuery, FetchMessageQueryVariables>(
    FetchMessageDocument,
    options
  );
}
export type FetchMessageQueryHookResult = ReturnType<
  typeof useFetchMessageQuery
>;
export type FetchMessageLazyQueryHookResult = ReturnType<
  typeof useFetchMessageLazyQuery
>;
export type FetchMessageQueryResult = Apollo.QueryResult<
  FetchMessageQuery,
  FetchMessageQueryVariables
>;
export const UpdateThreadMetadataDocument = gql`
  mutation UpdateThreadMetadata($input: UpdateThreadMetadataInput!) {
    updateThreadMetadata(input: $input) {
      ...ThreadMetadataFields
    }
  }
  ${ThreadMetadataFieldsFragmentDoc}
`;
export type UpdateThreadMetadataMutationFn = Apollo.MutationFunction<
  UpdateThreadMetadataMutation,
  UpdateThreadMetadataMutationVariables
>;

/**
 * __useUpdateThreadMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateThreadMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateThreadMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateThreadMetadataMutation, { data, loading, error }] = useUpdateThreadMetadataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateThreadMetadataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateThreadMetadataMutation,
    UpdateThreadMetadataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateThreadMetadataMutation,
    UpdateThreadMetadataMutationVariables
  >(UpdateThreadMetadataDocument, options);
}
export type UpdateThreadMetadataMutationHookResult = ReturnType<
  typeof useUpdateThreadMetadataMutation
>;
export type UpdateThreadMetadataMutationResult =
  Apollo.MutationResult<UpdateThreadMetadataMutation>;
export type UpdateThreadMetadataMutationOptions = Apollo.BaseMutationOptions<
  UpdateThreadMetadataMutation,
  UpdateThreadMetadataMutationVariables
>;
export const FetchThreadMetadataDocument = gql`
  query FetchThreadMetadata($threadID: ID!) {
    threadMetadata(threadID: $threadID) {
      ...ThreadMetadataFields
    }
  }
  ${ThreadMetadataFieldsFragmentDoc}
`;

/**
 * __useFetchThreadMetadataQuery__
 *
 * To run a query within a React component, call `useFetchThreadMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchThreadMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchThreadMetadataQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useFetchThreadMetadataQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchThreadMetadataQuery,
    FetchThreadMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchThreadMetadataQuery,
    FetchThreadMetadataQueryVariables
  >(FetchThreadMetadataDocument, options);
}
export function useFetchThreadMetadataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchThreadMetadataQuery,
    FetchThreadMetadataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchThreadMetadataQuery,
    FetchThreadMetadataQueryVariables
  >(FetchThreadMetadataDocument, options);
}
export type FetchThreadMetadataQueryHookResult = ReturnType<
  typeof useFetchThreadMetadataQuery
>;
export type FetchThreadMetadataLazyQueryHookResult = ReturnType<
  typeof useFetchThreadMetadataLazyQuery
>;
export type FetchThreadMetadataQueryResult = Apollo.QueryResult<
  FetchThreadMetadataQuery,
  FetchThreadMetadataQueryVariables
>;
export const FetchExternalGroupsDocument = gql`
  query FetchExternalGroups(
    $groupsAfter: String
    $groupsFirst: Int
    $match: String
    $membersLimit: Int = 0
    $membersAfter: String
    $pendingApprovalsLimit: Int = 0
    $pendingApprovalsAfter: String
  ) {
    groups(
      after: $groupsAfter
      filter: { match: $match, mutualWorkspaces: false }
      first: $groupsFirst
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        ...GroupEdgeFields
        node {
          ...GroupFields
        }
      }
    }
  }
  ${GroupEdgeFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
`;

/**
 * __useFetchExternalGroupsQuery__
 *
 * To run a query within a React component, call `useFetchExternalGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchExternalGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchExternalGroupsQuery({
 *   variables: {
 *      groupsAfter: // value for 'groupsAfter'
 *      groupsFirst: // value for 'groupsFirst'
 *      match: // value for 'match'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchExternalGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchExternalGroupsQuery,
    FetchExternalGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchExternalGroupsQuery,
    FetchExternalGroupsQueryVariables
  >(FetchExternalGroupsDocument, options);
}
export function useFetchExternalGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchExternalGroupsQuery,
    FetchExternalGroupsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchExternalGroupsQuery,
    FetchExternalGroupsQueryVariables
  >(FetchExternalGroupsDocument, options);
}
export type FetchExternalGroupsQueryHookResult = ReturnType<
  typeof useFetchExternalGroupsQuery
>;
export type FetchExternalGroupsLazyQueryHookResult = ReturnType<
  typeof useFetchExternalGroupsLazyQuery
>;
export type FetchExternalGroupsQueryResult = Apollo.QueryResult<
  FetchExternalGroupsQuery,
  FetchExternalGroupsQueryVariables
>;
export const FetchWorkspaceGroupsDirectoryDocument = gql`
  query FetchWorkspaceGroupsDirectory(
    $id: ID!
    $groupsAfter: String
    $groupsBefore: String
    $groupsFirst: Int
    $groupsLast: Int
    $groupsOrder: GroupsOrder = name
    $match: String
    $member: Boolean
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    node(id: $id) {
      id
      ... on WorkspaceEdge {
        ...WorkspaceEdgeFields
        node {
          ... on Workspace {
            ...WorkspaceCommonFields
            apps {
              edges {
                ...WorkspaceAppEdgeFields
              }
            }
            groups(
              filter: { member: $member, match: $match }
              after: $groupsAfter
              before: $groupsBefore
              first: $groupsFirst
              last: $groupsLast
              order: $groupsOrder
            ) {
              totalCount
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              edges {
                cursor
                node {
                  ...GroupFields
                  ...GroupPreviewFields
                }
              }
            }
            members(first: $membersLimit, after: $membersAfter) {
              totalCount
              pageInfo {
                hasNextPage
                endCursor
              }
              edges {
                cursor
                memberRole
                pending
                node {
                  ...UserFields
                }
              }
            }
            pendingApprovals(
              first: $pendingApprovalsLimit
              after: $pendingApprovalsAfter
            ) {
              totalCount
              edges {
                cursor
                node {
                  ...JoinApprovalSimpleFields
                }
              }
              pageInfo {
                hasNextPage
                endCursor
              }
            }
          }
        }
      }
      ... on WorkspacePreviewEdge {
        node {
          ...WorkspacePreviewFields
        }
      }
    }
  }
  ${WorkspaceEdgeFieldsFragmentDoc}
  ${WorkspaceCommonFieldsFragmentDoc}
  ${WorkspaceAppEdgeFieldsFragmentDoc}
  ${GroupFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
  ${JoinApprovalSimpleFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceGroupsDirectoryQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceGroupsDirectoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceGroupsDirectoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceGroupsDirectoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *      groupsAfter: // value for 'groupsAfter'
 *      groupsBefore: // value for 'groupsBefore'
 *      groupsFirst: // value for 'groupsFirst'
 *      groupsLast: // value for 'groupsLast'
 *      groupsOrder: // value for 'groupsOrder'
 *      match: // value for 'match'
 *      member: // value for 'member'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchWorkspaceGroupsDirectoryQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchWorkspaceGroupsDirectoryQuery,
    FetchWorkspaceGroupsDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWorkspaceGroupsDirectoryQuery,
    FetchWorkspaceGroupsDirectoryQueryVariables
  >(FetchWorkspaceGroupsDirectoryDocument, options);
}
export function useFetchWorkspaceGroupsDirectoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkspaceGroupsDirectoryQuery,
    FetchWorkspaceGroupsDirectoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWorkspaceGroupsDirectoryQuery,
    FetchWorkspaceGroupsDirectoryQueryVariables
  >(FetchWorkspaceGroupsDirectoryDocument, options);
}
export type FetchWorkspaceGroupsDirectoryQueryHookResult = ReturnType<
  typeof useFetchWorkspaceGroupsDirectoryQuery
>;
export type FetchWorkspaceGroupsDirectoryLazyQueryHookResult = ReturnType<
  typeof useFetchWorkspaceGroupsDirectoryLazyQuery
>;
export type FetchWorkspaceGroupsDirectoryQueryResult = Apollo.QueryResult<
  FetchWorkspaceGroupsDirectoryQuery,
  FetchWorkspaceGroupsDirectoryQueryVariables
>;
export const LinksDocument = gql`
  query Links($filter: LinksFilter, $last: Int, $before: String) {
    links(filter: $filter, last: $last, before: $before) {
      ...LinkConnectionFields
    }
  }
  ${LinkConnectionFieldsFragmentDoc}
`;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useLinksQuery(
  baseOptions?: Apollo.QueryHookOptions<LinksQuery, LinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksQuery, LinksQueryVariables>(
    LinksDocument,
    options
  );
}
export function useLinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LinksQuery, LinksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksQuery, LinksQueryVariables>(
    LinksDocument,
    options
  );
}
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksQueryResult = Apollo.QueryResult<
  LinksQuery,
  LinksQueryVariables
>;
export const LinksCountsDocument = gql`
  query LinksCounts($recipientIDs: [ID!]!) {
    files: links(
      filter: { recipientIDs: $recipientIDs, linkedCategories: [file] }
    ) {
      totalCount
    }
    media: links(
      filter: { recipientIDs: $recipientIDs, linkedCategories: [media] }
    ) {
      totalCount
    }
    links: links(
      filter: { recipientIDs: $recipientIDs, linkedCategories: [link] }
    ) {
      totalCount
    }
  }
`;

/**
 * __useLinksCountsQuery__
 *
 * To run a query within a React component, call `useLinksCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksCountsQuery({
 *   variables: {
 *      recipientIDs: // value for 'recipientIDs'
 *   },
 * });
 */
export function useLinksCountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    LinksCountsQuery,
    LinksCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LinksCountsQuery, LinksCountsQueryVariables>(
    LinksCountsDocument,
    options
  );
}
export function useLinksCountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LinksCountsQuery,
    LinksCountsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LinksCountsQuery, LinksCountsQueryVariables>(
    LinksCountsDocument,
    options
  );
}
export type LinksCountsQueryHookResult = ReturnType<typeof useLinksCountsQuery>;
export type LinksCountsLazyQueryHookResult = ReturnType<
  typeof useLinksCountsLazyQuery
>;
export type LinksCountsQueryResult = Apollo.QueryResult<
  LinksCountsQuery,
  LinksCountsQueryVariables
>;
export const FileDocument = gql`
  query File($id: ID!) {
    node(id: $id) {
      __typename
      id
      ... on File {
        ...FileFields
      }
    }
  }
  ${FileFieldsFragmentDoc}
`;

/**
 * __useFileQuery__
 *
 * To run a query within a React component, call `useFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFileQuery(
  baseOptions: Apollo.QueryHookOptions<FileQuery, FileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FileQuery, FileQueryVariables>(FileDocument, options);
}
export function useFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FileQuery, FileQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FileQuery, FileQueryVariables>(
    FileDocument,
    options
  );
}
export type FileQueryHookResult = ReturnType<typeof useFileQuery>;
export type FileLazyQueryHookResult = ReturnType<typeof useFileLazyQuery>;
export type FileQueryResult = Apollo.QueryResult<FileQuery, FileQueryVariables>;
export const FetchThreadEdgeSimpleDocument = gql`
  query FetchThreadEdgeSimple($id: ID!) {
    node(id: $id) {
      id
      ... on ThreadEdge {
        node {
          ...ThreadSimpleFields
        }
        ...ThreadEdgeFields
      }
      ... on ThreadPreviewEdge {
        node {
          ...ThreadPreviewFields
        }
      }
    }
  }
  ${ThreadSimpleFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadPreviewFieldsFragmentDoc}
`;

/**
 * __useFetchThreadEdgeSimpleQuery__
 *
 * To run a query within a React component, call `useFetchThreadEdgeSimpleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchThreadEdgeSimpleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchThreadEdgeSimpleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchThreadEdgeSimpleQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchThreadEdgeSimpleQuery,
    FetchThreadEdgeSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchThreadEdgeSimpleQuery,
    FetchThreadEdgeSimpleQueryVariables
  >(FetchThreadEdgeSimpleDocument, options);
}
export function useFetchThreadEdgeSimpleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchThreadEdgeSimpleQuery,
    FetchThreadEdgeSimpleQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchThreadEdgeSimpleQuery,
    FetchThreadEdgeSimpleQueryVariables
  >(FetchThreadEdgeSimpleDocument, options);
}
export type FetchThreadEdgeSimpleQueryHookResult = ReturnType<
  typeof useFetchThreadEdgeSimpleQuery
>;
export type FetchThreadEdgeSimpleLazyQueryHookResult = ReturnType<
  typeof useFetchThreadEdgeSimpleLazyQuery
>;
export type FetchThreadEdgeSimpleQueryResult = Apollo.QueryResult<
  FetchThreadEdgeSimpleQuery,
  FetchThreadEdgeSimpleQueryVariables
>;
export const PersistentChatsDocument = gql`
  query PersistentChats(
    $chatType: ChatType = group
    $last: Int
    $before: String
    $order: ThreadsOrder
  ) {
    persistentChats(
      filter: { chatType: $chatType }
      last: $last
      before: $before
      order: $order
    ) {
      totalCount
      unreadCounts {
        ...UnreadCountFields
      }
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      edges {
        ...ThreadEdgeFields
        node {
          ...ThreadSimpleFields
        }
      }
    }
  }
  ${UnreadCountFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
`;

/**
 * __usePersistentChatsQuery__
 *
 * To run a query within a React component, call `usePersistentChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersistentChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersistentChatsQuery({
 *   variables: {
 *      chatType: // value for 'chatType'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      order: // value for 'order'
 *   },
 * });
 */
export function usePersistentChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    PersistentChatsQuery,
    PersistentChatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PersistentChatsQuery, PersistentChatsQueryVariables>(
    PersistentChatsDocument,
    options
  );
}
export function usePersistentChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PersistentChatsQuery,
    PersistentChatsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PersistentChatsQuery,
    PersistentChatsQueryVariables
  >(PersistentChatsDocument, options);
}
export type PersistentChatsQueryHookResult = ReturnType<
  typeof usePersistentChatsQuery
>;
export type PersistentChatsLazyQueryHookResult = ReturnType<
  typeof usePersistentChatsLazyQuery
>;
export type PersistentChatsQueryResult = Apollo.QueryResult<
  PersistentChatsQuery,
  PersistentChatsQueryVariables
>;
export const GlobalSearchDocument = gql`
  query GlobalSearch(
    $contactAddressIDs: [ID!]
    $excludeGroupsIDs: [ID!]
    $excludeThreadsIDs: [ID!]
    $excludeUsersIDs: [ID!]
    $mailbox: ThreadsMailbox = all
    $match: String!
    $matchMessages: String = ""
    $limit: Int = 10
    $threads: Boolean = true
    $threadID: ID
    $cacheKey: String
    $groups: Boolean = true
    $users: Boolean = true
    $userEdgeStatus: [UserEdgeStatus!]
  ) {
    threads(
      filter: {
        excludeIDs: $excludeThreadsIDs
        match: $match
        matchMessages: $matchMessages
        mailbox: $mailbox
      }
      last: $limit
    ) @connection(key: $cacheKey) @include(if: $threads) {
      totalCount
      matchedMessages {
        edges {
          ...MessageEdgeFields
          node {
            ...MessageFields
          }
        }
      }
      edges {
        ...ThreadEdgeFields
        node {
          ...ThreadSimpleFields
        }
      }
    }
    groups(
      filter: { excludeIDs: $excludeGroupsIDs, match: $match }
      last: $limit
      order: interaction
    ) @connection(key: $cacheKey) @include(if: $groups) {
      totalCount
      edges {
        id
        node {
          ...GroupSimpleFields
        }
      }
    }
    workspaces(filter: { match: $match })
      @connection(key: $cacheKey)
      @include(if: $groups) {
      totalCount
      edges {
        ...WorkspaceEdgeSimpleFields
        node {
          ...WorkspaceSimpleFields
        }
      }
    }
    users(
      filter: {
        addressIDs: $contactAddressIDs
        edgeStatus: $userEdgeStatus
        excludeIDs: $excludeUsersIDs
        match: $match
        mutualGroups: true
        mutualWorkspaces: true
        mutualThreads: true
        threadID: $threadID
      }
      first: $limit
    ) @connection(key: $cacheKey) @include(if: $users) {
      totalCount
      edges {
        ...UserEdgeFields
        node {
          ...UserFields
        }
      }
    }
  }
  ${MessageEdgeFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      contactAddressIDs: // value for 'contactAddressIDs'
 *      excludeGroupsIDs: // value for 'excludeGroupsIDs'
 *      excludeThreadsIDs: // value for 'excludeThreadsIDs'
 *      excludeUsersIDs: // value for 'excludeUsersIDs'
 *      mailbox: // value for 'mailbox'
 *      match: // value for 'match'
 *      matchMessages: // value for 'matchMessages'
 *      limit: // value for 'limit'
 *      threads: // value for 'threads'
 *      threadID: // value for 'threadID'
 *      cacheKey: // value for 'cacheKey'
 *      groups: // value for 'groups'
 *      users: // value for 'users'
 *      userEdgeStatus: // value for 'userEdgeStatus'
 *   },
 * });
 */
export function useGlobalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalSearchQuery,
    GlobalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options
  );
}
export function useGlobalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalSearchQuery,
    GlobalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(
    GlobalSearchDocument,
    options
  );
}
export type GlobalSearchQueryHookResult = ReturnType<
  typeof useGlobalSearchQuery
>;
export type GlobalSearchLazyQueryHookResult = ReturnType<
  typeof useGlobalSearchLazyQuery
>;
export type GlobalSearchQueryResult = Apollo.QueryResult<
  GlobalSearchQuery,
  GlobalSearchQueryVariables
>;
export const LocalSearchDocument = gql`
  query LocalSearch(
    $contactAddressIDs: [ID!]
    $excludeGroupsIDs: [ID!]
    $excludeThreadsIDs: [ID!]
    $excludeUsersIDs: [ID!]
    $mailbox: ThreadsMailbox = all
    $match: String!
    $matchMessages: String = ""
    $limit: Int = 10
    $threads: Boolean = true
    $groups: Boolean = true
    $users: Boolean = true
    $userEdgeStatus: [UserEdgeStatus!]
    $mutualWorkspaceIDs: [ID!]
    $threadID: ID
  ) {
    threads: localThreads(
      filter: {
        excludeIDs: $excludeThreadsIDs
        mailbox: $mailbox
        match: $match
        matchMessages: $matchMessages
      }
      last: $limit
    ) @client @include(if: $threads) {
      totalCount
      matchedMessages {
        edges {
          ...MessageEdgeFields
          node {
            ...MessageFields
          }
        }
      }
      edges {
        ...ThreadEdgeFields
        node {
          ...ThreadSimpleFields
        }
      }
    }
    groups: localGroups(
      filter: {
        excludeIDs: $excludeGroupsIDs
        match: $match
        mutualWorkspaceIDs: $mutualWorkspaceIDs
      }
      first: $limit
    ) @client @include(if: $groups) {
      totalCount
      edges {
        id
        node {
          ... on Group {
            ...GroupSimpleFields
          }
          ... on GroupPreview {
            ...GroupPreviewSimpleFields
          }
        }
      }
    }
    workspaces: localWorkspaces(filter: { match: $match })
      @client
      @include(if: $groups) {
      totalCount
      edges {
        ...WorkspaceEdgeSimpleFields
        node {
          ...WorkspaceSimpleFields
        }
      }
    }
    users: localUsers(
      filter: {
        addressIDs: $contactAddressIDs
        edgeStatus: $userEdgeStatus
        excludeIDs: $excludeUsersIDs
        match: $match
        mutualWorkspaceIDs: $mutualWorkspaceIDs
        threadID: $threadID
      }
      first: $limit
    ) @client @include(if: $users) {
      totalCount
      edges {
        ...UserEdgeFields
        node {
          ...UserFields
        }
      }
    }
  }
  ${MessageEdgeFieldsFragmentDoc}
  ${MessageFieldsFragmentDoc}
  ${ThreadEdgeFieldsFragmentDoc}
  ${ThreadSimpleFieldsFragmentDoc}
  ${GroupSimpleFieldsFragmentDoc}
  ${GroupPreviewSimpleFieldsFragmentDoc}
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${UserEdgeFieldsFragmentDoc}
  ${UserFieldsFragmentDoc}
`;

/**
 * __useLocalSearchQuery__
 *
 * To run a query within a React component, call `useLocalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocalSearchQuery({
 *   variables: {
 *      contactAddressIDs: // value for 'contactAddressIDs'
 *      excludeGroupsIDs: // value for 'excludeGroupsIDs'
 *      excludeThreadsIDs: // value for 'excludeThreadsIDs'
 *      excludeUsersIDs: // value for 'excludeUsersIDs'
 *      mailbox: // value for 'mailbox'
 *      match: // value for 'match'
 *      matchMessages: // value for 'matchMessages'
 *      limit: // value for 'limit'
 *      threads: // value for 'threads'
 *      groups: // value for 'groups'
 *      users: // value for 'users'
 *      userEdgeStatus: // value for 'userEdgeStatus'
 *      mutualWorkspaceIDs: // value for 'mutualWorkspaceIDs'
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useLocalSearchQuery(
  baseOptions: Apollo.QueryHookOptions<
    LocalSearchQuery,
    LocalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LocalSearchQuery, LocalSearchQueryVariables>(
    LocalSearchDocument,
    options
  );
}
export function useLocalSearchLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LocalSearchQuery,
    LocalSearchQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LocalSearchQuery, LocalSearchQueryVariables>(
    LocalSearchDocument,
    options
  );
}
export type LocalSearchQueryHookResult = ReturnType<typeof useLocalSearchQuery>;
export type LocalSearchLazyQueryHookResult = ReturnType<
  typeof useLocalSearchLazyQuery
>;
export type LocalSearchQueryResult = Apollo.QueryResult<
  LocalSearchQuery,
  LocalSearchQueryVariables
>;
export const JoinGroupDocument = gql`
  mutation JoinGroup(
    $id: ID!
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    joinGroup(id: $id) {
      ...GroupFields
    }
  }
  ${GroupFieldsFragmentDoc}
`;
export type JoinGroupMutationFn = Apollo.MutationFunction<
  JoinGroupMutation,
  JoinGroupMutationVariables
>;

/**
 * __useJoinGroupMutation__
 *
 * To run a mutation, you first call `useJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinGroupMutation, { data, loading, error }] = useJoinGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinGroupMutation,
    JoinGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JoinGroupMutation, JoinGroupMutationVariables>(
    JoinGroupDocument,
    options
  );
}
export type JoinGroupMutationHookResult = ReturnType<
  typeof useJoinGroupMutation
>;
export type JoinGroupMutationResult = Apollo.MutationResult<JoinGroupMutation>;
export type JoinGroupMutationOptions = Apollo.BaseMutationOptions<
  JoinGroupMutation,
  JoinGroupMutationVariables
>;
export const RequestJoinGroupDocument = gql`
  mutation RequestJoinGroup($joinableID: ID!) {
    sendJoinRequest(input: { joinableID: $joinableID }) {
      id
    }
  }
`;
export type RequestJoinGroupMutationFn = Apollo.MutationFunction<
  RequestJoinGroupMutation,
  RequestJoinGroupMutationVariables
>;

/**
 * __useRequestJoinGroupMutation__
 *
 * To run a mutation, you first call `useRequestJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestJoinGroupMutation, { data, loading, error }] = useRequestJoinGroupMutation({
 *   variables: {
 *      joinableID: // value for 'joinableID'
 *   },
 * });
 */
export function useRequestJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestJoinGroupMutation,
    RequestJoinGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestJoinGroupMutation,
    RequestJoinGroupMutationVariables
  >(RequestJoinGroupDocument, options);
}
export type RequestJoinGroupMutationHookResult = ReturnType<
  typeof useRequestJoinGroupMutation
>;
export type RequestJoinGroupMutationResult =
  Apollo.MutationResult<RequestJoinGroupMutation>;
export type RequestJoinGroupMutationOptions = Apollo.BaseMutationOptions<
  RequestJoinGroupMutation,
  RequestJoinGroupMutationVariables
>;
export const CancelRequestJoinGroupDocument = gql`
  mutation CancelRequestJoinGroup($id: ID!) {
    cancelJoinApproval(id: $id)
  }
`;
export type CancelRequestJoinGroupMutationFn = Apollo.MutationFunction<
  CancelRequestJoinGroupMutation,
  CancelRequestJoinGroupMutationVariables
>;

/**
 * __useCancelRequestJoinGroupMutation__
 *
 * To run a mutation, you first call `useCancelRequestJoinGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelRequestJoinGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelRequestJoinGroupMutation, { data, loading, error }] = useCancelRequestJoinGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelRequestJoinGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelRequestJoinGroupMutation,
    CancelRequestJoinGroupMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelRequestJoinGroupMutation,
    CancelRequestJoinGroupMutationVariables
  >(CancelRequestJoinGroupDocument, options);
}
export type CancelRequestJoinGroupMutationHookResult = ReturnType<
  typeof useCancelRequestJoinGroupMutation
>;
export type CancelRequestJoinGroupMutationResult =
  Apollo.MutationResult<CancelRequestJoinGroupMutation>;
export type CancelRequestJoinGroupMutationOptions = Apollo.BaseMutationOptions<
  CancelRequestJoinGroupMutation,
  CancelRequestJoinGroupMutationVariables
>;
export const AddGroupMembersDocument = gql`
  mutation AddGroupMembers(
    $id: ID!
    $members: [MemberRoleInput!]!
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    addGroupMembers(id: $id, members: $members) {
      ...GroupFields
    }
  }
  ${GroupFieldsFragmentDoc}
`;
export type AddGroupMembersMutationFn = Apollo.MutationFunction<
  AddGroupMembersMutation,
  AddGroupMembersMutationVariables
>;

/**
 * __useAddGroupMembersMutation__
 *
 * To run a mutation, you first call `useAddGroupMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMembersMutation, { data, loading, error }] = useAddGroupMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      members: // value for 'members'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useAddGroupMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupMembersMutation,
    AddGroupMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddGroupMembersMutation,
    AddGroupMembersMutationVariables
  >(AddGroupMembersDocument, options);
}
export type AddGroupMembersMutationHookResult = ReturnType<
  typeof useAddGroupMembersMutation
>;
export type AddGroupMembersMutationResult =
  Apollo.MutationResult<AddGroupMembersMutation>;
export type AddGroupMembersMutationOptions = Apollo.BaseMutationOptions<
  AddGroupMembersMutation,
  AddGroupMembersMutationVariables
>;
export const AddWorkspaceMembersDocument = gql`
  mutation AddWorkspaceMembers(
    $id: ID!
    $members: [MemberRoleInput!]!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    addWorkspaceMembers(id: $id, members: $members) {
      ...WorkspaceFields
    }
  }
  ${WorkspaceFieldsFragmentDoc}
`;
export type AddWorkspaceMembersMutationFn = Apollo.MutationFunction<
  AddWorkspaceMembersMutation,
  AddWorkspaceMembersMutationVariables
>;

/**
 * __useAddWorkspaceMembersMutation__
 *
 * To run a mutation, you first call `useAddWorkspaceMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkspaceMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkspaceMembersMutation, { data, loading, error }] = useAddWorkspaceMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      members: // value for 'members'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useAddWorkspaceMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWorkspaceMembersMutation,
    AddWorkspaceMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddWorkspaceMembersMutation,
    AddWorkspaceMembersMutationVariables
  >(AddWorkspaceMembersDocument, options);
}
export type AddWorkspaceMembersMutationHookResult = ReturnType<
  typeof useAddWorkspaceMembersMutation
>;
export type AddWorkspaceMembersMutationResult =
  Apollo.MutationResult<AddWorkspaceMembersMutation>;
export type AddWorkspaceMembersMutationOptions = Apollo.BaseMutationOptions<
  AddWorkspaceMembersMutation,
  AddWorkspaceMembersMutationVariables
>;
export const NotInGroupDocument = gql`
  query NotInGroup($id: ID!, $membersLimit: Int = 50, $membersAfter: String) {
    node(id: $id) {
      id
      ... on Group {
        ...GroupSimpleFields
      }
      ... on GroupPreview {
        ...GroupPreviewFields
      }
      ... on Workspace {
        ...WorkspaceSimpleFields
      }
      ... on WorkspacePreview {
        ...WorkspacePreviewFields
      }
    }
    joinRequest(joinableID: $id) {
      id
    }
  }
  ${GroupSimpleFieldsFragmentDoc}
  ${GroupPreviewFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useNotInGroupQuery__
 *
 * To run a query within a React component, call `useNotInGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotInGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotInGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *   },
 * });
 */
export function useNotInGroupQuery(
  baseOptions: Apollo.QueryHookOptions<
    NotInGroupQuery,
    NotInGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NotInGroupQuery, NotInGroupQueryVariables>(
    NotInGroupDocument,
    options
  );
}
export function useNotInGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NotInGroupQuery,
    NotInGroupQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NotInGroupQuery, NotInGroupQueryVariables>(
    NotInGroupDocument,
    options
  );
}
export type NotInGroupQueryHookResult = ReturnType<typeof useNotInGroupQuery>;
export type NotInGroupLazyQueryHookResult = ReturnType<
  typeof useNotInGroupLazyQuery
>;
export type NotInGroupQueryResult = Apollo.QueryResult<
  NotInGroupQuery,
  NotInGroupQueryVariables
>;
export const FetchDomainsDocument = gql`
  query FetchDomains(
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 0
    $groupsAfter: String
    $membersLimit: Int = 5
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    domains {
      edges {
        id
        node {
          id
          name
          workspaces {
            edges {
              ... on WorkspaceEdge {
                ...WorkspaceEdgeFields
                node {
                  ...WorkspaceFields
                }
              }
              ... on WorkspacePreviewEdge {
                node {
                  ...WorkspacePreviewFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${WorkspaceEdgeFieldsFragmentDoc}
  ${WorkspaceFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useFetchDomainsQuery__
 *
 * To run a query within a React component, call `useFetchDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDomainsQuery({
 *   variables: {
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchDomainsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDomainsQuery,
    FetchDomainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchDomainsQuery, FetchDomainsQueryVariables>(
    FetchDomainsDocument,
    options
  );
}
export function useFetchDomainsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDomainsQuery,
    FetchDomainsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchDomainsQuery, FetchDomainsQueryVariables>(
    FetchDomainsDocument,
    options
  );
}
export type FetchDomainsQueryHookResult = ReturnType<
  typeof useFetchDomainsQuery
>;
export type FetchDomainsLazyQueryHookResult = ReturnType<
  typeof useFetchDomainsLazyQuery
>;
export type FetchDomainsQueryResult = Apollo.QueryResult<
  FetchDomainsQuery,
  FetchDomainsQueryVariables
>;
export const JoinWorkspaceDocument = gql`
  mutation JoinWorkspace(
    $id: ID!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    joinWorkspace(id: $id) {
      ...WorkspaceFields
    }
  }
  ${WorkspaceFieldsFragmentDoc}
`;
export type JoinWorkspaceMutationFn = Apollo.MutationFunction<
  JoinWorkspaceMutation,
  JoinWorkspaceMutationVariables
>;

/**
 * __useJoinWorkspaceMutation__
 *
 * To run a mutation, you first call `useJoinWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJoinWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [joinWorkspaceMutation, { data, loading, error }] = useJoinWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useJoinWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    JoinWorkspaceMutation,
    JoinWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    JoinWorkspaceMutation,
    JoinWorkspaceMutationVariables
  >(JoinWorkspaceDocument, options);
}
export type JoinWorkspaceMutationHookResult = ReturnType<
  typeof useJoinWorkspaceMutation
>;
export type JoinWorkspaceMutationResult =
  Apollo.MutationResult<JoinWorkspaceMutation>;
export type JoinWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  JoinWorkspaceMutation,
  JoinWorkspaceMutationVariables
>;
export const UpdateWorkspaceSubscriptionMutationDocument = gql`
  mutation UpdateWorkspaceSubscriptionMutation(
    $input: WorkspaceSubscriptionInput!
    $workspaceID: ID!
  ) {
    updateWorkspaceSubscription(input: $input, workspaceID: $workspaceID) {
      ...WorkspaceSubscriptionFields
    }
  }
  ${WorkspaceSubscriptionFieldsFragmentDoc}
`;
export type UpdateWorkspaceSubscriptionMutationMutationFn =
  Apollo.MutationFunction<
    UpdateWorkspaceSubscriptionMutation,
    UpdateWorkspaceSubscriptionMutationVariables
  >;

/**
 * __useUpdateWorkspaceSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceSubscriptionMutation, { data, loading, error }] = useUpdateWorkspaceSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useUpdateWorkspaceSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceSubscriptionMutation,
    UpdateWorkspaceSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceSubscriptionMutation,
    UpdateWorkspaceSubscriptionMutationVariables
  >(UpdateWorkspaceSubscriptionMutationDocument, options);
}
export type UpdateWorkspaceSubscriptionMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceSubscriptionMutation
>;
export type UpdateWorkspaceSubscriptionMutationMutationResult =
  Apollo.MutationResult<UpdateWorkspaceSubscriptionMutation>;
export type UpdateWorkspaceSubscriptionMutationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWorkspaceSubscriptionMutation,
    UpdateWorkspaceSubscriptionMutationVariables
  >;
export const WorkspaceSubscriptionBillingQueryDocument = gql`
  query WorkspaceSubscriptionBillingQuery($workspaceID: ID!) {
    workspaceSubscriptionBilling(workspaceID: $workspaceID) {
      stripePortalURL
    }
  }
`;

/**
 * __useWorkspaceSubscriptionBillingQuery__
 *
 * To run a query within a React component, call `useWorkspaceSubscriptionBillingQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSubscriptionBillingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSubscriptionBillingQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useWorkspaceSubscriptionBillingQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceSubscriptionBillingQuery,
    WorkspaceSubscriptionBillingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceSubscriptionBillingQuery,
    WorkspaceSubscriptionBillingQueryVariables
  >(WorkspaceSubscriptionBillingQueryDocument, options);
}
export function useWorkspaceSubscriptionBillingQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceSubscriptionBillingQuery,
    WorkspaceSubscriptionBillingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceSubscriptionBillingQuery,
    WorkspaceSubscriptionBillingQueryVariables
  >(WorkspaceSubscriptionBillingQueryDocument, options);
}
export type WorkspaceSubscriptionBillingQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionBillingQuery
>;
export type WorkspaceSubscriptionBillingQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionBillingQueryLazyQuery
>;
export type WorkspaceSubscriptionBillingQueryQueryResult = Apollo.QueryResult<
  WorkspaceSubscriptionBillingQuery,
  WorkspaceSubscriptionBillingQueryVariables
>;
export const WorkspaceSubscriptionCheckoutQueryDocument = gql`
  query WorkspaceSubscriptionCheckoutQuery(
    $workspaceID: ID!
    $input: WorkspaceSubscriptionInput!
  ) {
    workspaceSubscriptionCheckout(workspaceID: $workspaceID, input: $input) {
      stripeClientSecret
    }
  }
`;

/**
 * __useWorkspaceSubscriptionCheckoutQuery__
 *
 * To run a query within a React component, call `useWorkspaceSubscriptionCheckoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSubscriptionCheckoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSubscriptionCheckoutQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useWorkspaceSubscriptionCheckoutQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceSubscriptionCheckoutQuery,
    WorkspaceSubscriptionCheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceSubscriptionCheckoutQuery,
    WorkspaceSubscriptionCheckoutQueryVariables
  >(WorkspaceSubscriptionCheckoutQueryDocument, options);
}
export function useWorkspaceSubscriptionCheckoutQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceSubscriptionCheckoutQuery,
    WorkspaceSubscriptionCheckoutQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceSubscriptionCheckoutQuery,
    WorkspaceSubscriptionCheckoutQueryVariables
  >(WorkspaceSubscriptionCheckoutQueryDocument, options);
}
export type WorkspaceSubscriptionCheckoutQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionCheckoutQuery
>;
export type WorkspaceSubscriptionCheckoutQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionCheckoutQueryLazyQuery
>;
export type WorkspaceSubscriptionCheckoutQueryQueryResult = Apollo.QueryResult<
  WorkspaceSubscriptionCheckoutQuery,
  WorkspaceSubscriptionCheckoutQueryVariables
>;
export const WorkspaceSubscriptionPlansQueryDocument = gql`
  query WorkspaceSubscriptionPlansQuery {
    workspaceSubscriptionPlans {
      ...SubscriptionPlanFields
    }
  }
  ${SubscriptionPlanFieldsFragmentDoc}
`;

/**
 * __useWorkspaceSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useWorkspaceSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspaceSubscriptionPlansQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspaceSubscriptionPlansQuery,
    WorkspaceSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceSubscriptionPlansQuery,
    WorkspaceSubscriptionPlansQueryVariables
  >(WorkspaceSubscriptionPlansQueryDocument, options);
}
export function useWorkspaceSubscriptionPlansQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceSubscriptionPlansQuery,
    WorkspaceSubscriptionPlansQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceSubscriptionPlansQuery,
    WorkspaceSubscriptionPlansQueryVariables
  >(WorkspaceSubscriptionPlansQueryDocument, options);
}
export type WorkspaceSubscriptionPlansQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionPlansQuery
>;
export type WorkspaceSubscriptionPlansQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionPlansQueryLazyQuery
>;
export type WorkspaceSubscriptionPlansQueryQueryResult = Apollo.QueryResult<
  WorkspaceSubscriptionPlansQuery,
  WorkspaceSubscriptionPlansQueryVariables
>;
export const WorkspaceSubscriptionQueryDocument = gql`
  query WorkspaceSubscriptionQuery($workspaceID: ID!) {
    workspaceSubscription(workspaceID: $workspaceID) {
      ...WorkspaceSubscriptionFields
    }
  }
  ${WorkspaceSubscriptionFieldsFragmentDoc}
`;

/**
 * __useWorkspaceSubscriptionQuery__
 *
 * To run a query within a React component, call `useWorkspaceSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspaceSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspaceSubscriptionQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useWorkspaceSubscriptionQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >(WorkspaceSubscriptionQueryDocument, options);
}
export function useWorkspaceSubscriptionQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkspaceSubscriptionQuery,
    WorkspaceSubscriptionQueryVariables
  >(WorkspaceSubscriptionQueryDocument, options);
}
export type WorkspaceSubscriptionQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionQuery
>;
export type WorkspaceSubscriptionQueryLazyQueryHookResult = ReturnType<
  typeof useWorkspaceSubscriptionQueryLazyQuery
>;
export type WorkspaceSubscriptionQueryQueryResult = Apollo.QueryResult<
  WorkspaceSubscriptionQuery,
  WorkspaceSubscriptionQueryVariables
>;
export const CreateWorkspaceDocument = gql`
  mutation CreateWorkspace(
    $input: CreateWorkspaceInput!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 0
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    createWorkspace(input: $input) {
      ...WorkspaceFields
    }
  }
  ${WorkspaceFieldsFragmentDoc}
`;
export type CreateWorkspaceMutationFn = Apollo.MutationFunction<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;

/**
 * __useCreateWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceMutation, { data, loading, error }] = useCreateWorkspaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useCreateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkspaceMutation,
    CreateWorkspaceMutationVariables
  >(CreateWorkspaceDocument, options);
}
export type CreateWorkspaceMutationHookResult = ReturnType<
  typeof useCreateWorkspaceMutation
>;
export type CreateWorkspaceMutationResult =
  Apollo.MutationResult<CreateWorkspaceMutation>;
export type CreateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceMutation,
  CreateWorkspaceMutationVariables
>;
export const UpdateWorkspaceDocument = gql`
  mutation UpdateWorkspace(
    $id: ID!
    $input: UpdateWorkspaceInput!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    updateWorkspace(id: $id, input: $input) {
      ...WorkspaceFields
    }
  }
  ${WorkspaceFieldsFragmentDoc}
`;
export type UpdateWorkspaceMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;

/**
 * __useUpdateWorkspaceMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceMutation, { data, loading, error }] = useUpdateWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useUpdateWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceMutation,
    UpdateWorkspaceMutationVariables
  >(UpdateWorkspaceDocument, options);
}
export type UpdateWorkspaceMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceMutation
>;
export type UpdateWorkspaceMutationResult =
  Apollo.MutationResult<UpdateWorkspaceMutation>;
export type UpdateWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  UpdateWorkspaceMutation,
  UpdateWorkspaceMutationVariables
>;
export const DeleteWorkspaceDocument = gql`
  mutation DeleteWorkspace($id: ID!) {
    deleteWorkspace(id: $id)
  }
`;
export type DeleteWorkspaceMutationFn = Apollo.MutationFunction<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;

/**
 * __useDeleteWorkspaceMutation__
 *
 * To run a mutation, you first call `useDeleteWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkspaceMutation, { data, loading, error }] = useDeleteWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWorkspaceMutation,
    DeleteWorkspaceMutationVariables
  >(DeleteWorkspaceDocument, options);
}
export type DeleteWorkspaceMutationHookResult = ReturnType<
  typeof useDeleteWorkspaceMutation
>;
export type DeleteWorkspaceMutationResult =
  Apollo.MutationResult<DeleteWorkspaceMutation>;
export type DeleteWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  DeleteWorkspaceMutation,
  DeleteWorkspaceMutationVariables
>;
export const CreateLlmapiKeyDocument = gql`
  mutation CreateLLMAPIKey($input: WorkspaceLLMAPIKeyInput!) {
    createLLMAPIKey(input: $input) {
      ...WorkspaceLLMAPIKeyFields
    }
  }
  ${WorkspaceLlmapiKeyFieldsFragmentDoc}
`;
export type CreateLlmapiKeyMutationFn = Apollo.MutationFunction<
  CreateLlmapiKeyMutation,
  CreateLlmapiKeyMutationVariables
>;

/**
 * __useCreateLlmapiKeyMutation__
 *
 * To run a mutation, you first call `useCreateLlmapiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLlmapiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLlmapiKeyMutation, { data, loading, error }] = useCreateLlmapiKeyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLlmapiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLlmapiKeyMutation,
    CreateLlmapiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLlmapiKeyMutation,
    CreateLlmapiKeyMutationVariables
  >(CreateLlmapiKeyDocument, options);
}
export type CreateLlmapiKeyMutationHookResult = ReturnType<
  typeof useCreateLlmapiKeyMutation
>;
export type CreateLlmapiKeyMutationResult =
  Apollo.MutationResult<CreateLlmapiKeyMutation>;
export type CreateLlmapiKeyMutationOptions = Apollo.BaseMutationOptions<
  CreateLlmapiKeyMutation,
  CreateLlmapiKeyMutationVariables
>;
export const DeleteLlmapiKeyDocument = gql`
  mutation DeleteLLMAPIKey($id: ID!, $workspaceID: ID!) {
    deleteLLMAPIKey(id: $id, workspaceID: $workspaceID)
  }
`;
export type DeleteLlmapiKeyMutationFn = Apollo.MutationFunction<
  DeleteLlmapiKeyMutation,
  DeleteLlmapiKeyMutationVariables
>;

/**
 * __useDeleteLlmapiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteLlmapiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLlmapiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLlmapiKeyMutation, { data, loading, error }] = useDeleteLlmapiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useDeleteLlmapiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteLlmapiKeyMutation,
    DeleteLlmapiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteLlmapiKeyMutation,
    DeleteLlmapiKeyMutationVariables
  >(DeleteLlmapiKeyDocument, options);
}
export type DeleteLlmapiKeyMutationHookResult = ReturnType<
  typeof useDeleteLlmapiKeyMutation
>;
export type DeleteLlmapiKeyMutationResult =
  Apollo.MutationResult<DeleteLlmapiKeyMutation>;
export type DeleteLlmapiKeyMutationOptions = Apollo.BaseMutationOptions<
  DeleteLlmapiKeyMutation,
  DeleteLlmapiKeyMutationVariables
>;
export const CreateWorkspaceAppDocument = gql`
  mutation CreateWorkspaceApp($input: AppInput!) {
    createWorkspaceApp(input: $input) {
      ...WorkspaceAppEdgeFields
    }
  }
  ${WorkspaceAppEdgeFieldsFragmentDoc}
`;
export type CreateWorkspaceAppMutationFn = Apollo.MutationFunction<
  CreateWorkspaceAppMutation,
  CreateWorkspaceAppMutationVariables
>;

/**
 * __useCreateWorkspaceAppMutation__
 *
 * To run a mutation, you first call `useCreateWorkspaceAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkspaceAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkspaceAppMutation, { data, loading, error }] = useCreateWorkspaceAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkspaceAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateWorkspaceAppMutation,
    CreateWorkspaceAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateWorkspaceAppMutation,
    CreateWorkspaceAppMutationVariables
  >(CreateWorkspaceAppDocument, options);
}
export type CreateWorkspaceAppMutationHookResult = ReturnType<
  typeof useCreateWorkspaceAppMutation
>;
export type CreateWorkspaceAppMutationResult =
  Apollo.MutationResult<CreateWorkspaceAppMutation>;
export type CreateWorkspaceAppMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkspaceAppMutation,
  CreateWorkspaceAppMutationVariables
>;
export const RemoveWorkspaceAppDocument = gql`
  mutation RemoveWorkspaceApp($id: ID!) {
    removeWorkspaceApp(id: $id)
  }
`;
export type RemoveWorkspaceAppMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceAppMutation,
  RemoveWorkspaceAppMutationVariables
>;

/**
 * __useRemoveWorkspaceAppMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceAppMutation, { data, loading, error }] = useRemoveWorkspaceAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWorkspaceAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWorkspaceAppMutation,
    RemoveWorkspaceAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveWorkspaceAppMutation,
    RemoveWorkspaceAppMutationVariables
  >(RemoveWorkspaceAppDocument, options);
}
export type RemoveWorkspaceAppMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceAppMutation
>;
export type RemoveWorkspaceAppMutationResult =
  Apollo.MutationResult<RemoveWorkspaceAppMutation>;
export type RemoveWorkspaceAppMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceAppMutation,
  RemoveWorkspaceAppMutationVariables
>;
export const UpdateAppDocument = gql`
  mutation UpdateApp($id: ID!, $input: AppInput!) {
    updateApp(id: $id, input: $input) {
      ...AppFields
    }
  }
  ${AppFieldsFragmentDoc}
`;
export type UpdateAppMutationFn = Apollo.MutationFunction<
  UpdateAppMutation,
  UpdateAppMutationVariables
>;

/**
 * __useUpdateAppMutation__
 *
 * To run a mutation, you first call `useUpdateAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppMutation, { data, loading, error }] = useUpdateAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppMutation,
    UpdateAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAppMutation, UpdateAppMutationVariables>(
    UpdateAppDocument,
    options
  );
}
export type UpdateAppMutationHookResult = ReturnType<
  typeof useUpdateAppMutation
>;
export type UpdateAppMutationResult = Apollo.MutationResult<UpdateAppMutation>;
export type UpdateAppMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppMutation,
  UpdateAppMutationVariables
>;
export const DeleteAppDocument = gql`
  mutation DeleteApp($id: ID!) {
    deleteApp(id: $id)
  }
`;
export type DeleteAppMutationFn = Apollo.MutationFunction<
  DeleteAppMutation,
  DeleteAppMutationVariables
>;

/**
 * __useDeleteAppMutation__
 *
 * To run a mutation, you first call `useDeleteAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAppMutation, { data, loading, error }] = useDeleteAppMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAppMutation,
    DeleteAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAppMutation, DeleteAppMutationVariables>(
    DeleteAppDocument,
    options
  );
}
export type DeleteAppMutationHookResult = ReturnType<
  typeof useDeleteAppMutation
>;
export type DeleteAppMutationResult = Apollo.MutationResult<DeleteAppMutation>;
export type DeleteAppMutationOptions = Apollo.BaseMutationOptions<
  DeleteAppMutation,
  DeleteAppMutationVariables
>;
export const LeaveWorkspaceDocument = gql`
  mutation LeaveWorkspace($id: ID!) {
    leaveWorkspace(id: $id)
  }
`;
export type LeaveWorkspaceMutationFn = Apollo.MutationFunction<
  LeaveWorkspaceMutation,
  LeaveWorkspaceMutationVariables
>;

/**
 * __useLeaveWorkspaceMutation__
 *
 * To run a mutation, you first call `useLeaveWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveWorkspaceMutation, { data, loading, error }] = useLeaveWorkspaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveWorkspaceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveWorkspaceMutation,
    LeaveWorkspaceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveWorkspaceMutation,
    LeaveWorkspaceMutationVariables
  >(LeaveWorkspaceDocument, options);
}
export type LeaveWorkspaceMutationHookResult = ReturnType<
  typeof useLeaveWorkspaceMutation
>;
export type LeaveWorkspaceMutationResult =
  Apollo.MutationResult<LeaveWorkspaceMutation>;
export type LeaveWorkspaceMutationOptions = Apollo.BaseMutationOptions<
  LeaveWorkspaceMutation,
  LeaveWorkspaceMutationVariables
>;
export const RemoveWorkspaceMembersDocument = gql`
  mutation RemoveWorkspaceMembers(
    $id: ID!
    $memberIDs: [ID!]!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 10
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    removeWorkspaceMembers(id: $id, memberIDs: $memberIDs) {
      ...WorkspaceFields
    }
  }
  ${WorkspaceFieldsFragmentDoc}
`;
export type RemoveWorkspaceMembersMutationFn = Apollo.MutationFunction<
  RemoveWorkspaceMembersMutation,
  RemoveWorkspaceMembersMutationVariables
>;

/**
 * __useRemoveWorkspaceMembersMutation__
 *
 * To run a mutation, you first call `useRemoveWorkspaceMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkspaceMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkspaceMembersMutation, { data, loading, error }] = useRemoveWorkspaceMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberIDs: // value for 'memberIDs'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useRemoveWorkspaceMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveWorkspaceMembersMutation,
    RemoveWorkspaceMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveWorkspaceMembersMutation,
    RemoveWorkspaceMembersMutationVariables
  >(RemoveWorkspaceMembersDocument, options);
}
export type RemoveWorkspaceMembersMutationHookResult = ReturnType<
  typeof useRemoveWorkspaceMembersMutation
>;
export type RemoveWorkspaceMembersMutationResult =
  Apollo.MutationResult<RemoveWorkspaceMembersMutation>;
export type RemoveWorkspaceMembersMutationOptions = Apollo.BaseMutationOptions<
  RemoveWorkspaceMembersMutation,
  RemoveWorkspaceMembersMutationVariables
>;
export const RemindWorkspaceMembersDocument = gql`
  mutation RemindWorkspaceMembers(
    $id: ID!
    $input: RemindWorkspaceMembersInput!
  ) {
    remindWorkspaceMembers(id: $id, input: $input)
  }
`;
export type RemindWorkspaceMembersMutationFn = Apollo.MutationFunction<
  RemindWorkspaceMembersMutation,
  RemindWorkspaceMembersMutationVariables
>;

/**
 * __useRemindWorkspaceMembersMutation__
 *
 * To run a mutation, you first call `useRemindWorkspaceMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemindWorkspaceMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remindWorkspaceMembersMutation, { data, loading, error }] = useRemindWorkspaceMembersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemindWorkspaceMembersMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemindWorkspaceMembersMutation,
    RemindWorkspaceMembersMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemindWorkspaceMembersMutation,
    RemindWorkspaceMembersMutationVariables
  >(RemindWorkspaceMembersDocument, options);
}
export type RemindWorkspaceMembersMutationHookResult = ReturnType<
  typeof useRemindWorkspaceMembersMutation
>;
export type RemindWorkspaceMembersMutationResult =
  Apollo.MutationResult<RemindWorkspaceMembersMutation>;
export type RemindWorkspaceMembersMutationOptions = Apollo.BaseMutationOptions<
  RemindWorkspaceMembersMutation,
  RemindWorkspaceMembersMutationVariables
>;
export const FetchWorkspaceDocument = gql`
  query FetchWorkspace(
    $id: ID!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    node(id: $id) {
      id
      ... on Workspace {
        ...WorkspaceFields
      }
      ... on WorkspacePreview {
        ...WorkspacePreviewFields
      }
    }
  }
  ${WorkspaceFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchWorkspaceQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchWorkspaceQuery,
    FetchWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchWorkspaceQuery, FetchWorkspaceQueryVariables>(
    FetchWorkspaceDocument,
    options
  );
}
export function useFetchWorkspaceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkspaceQuery,
    FetchWorkspaceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchWorkspaceQuery, FetchWorkspaceQueryVariables>(
    FetchWorkspaceDocument,
    options
  );
}
export type FetchWorkspaceQueryHookResult = ReturnType<
  typeof useFetchWorkspaceQuery
>;
export type FetchWorkspaceLazyQueryHookResult = ReturnType<
  typeof useFetchWorkspaceLazyQuery
>;
export type FetchWorkspaceQueryResult = Apollo.QueryResult<
  FetchWorkspaceQuery,
  FetchWorkspaceQueryVariables
>;
export const FetchWorkspaceOrPreviewEdgeDocument = gql`
  query FetchWorkspaceOrPreviewEdge(
    $id: ID!
    $groupsOrder: GroupsOrder = name
    $groupsLimit: Int = 100
    $groupsAfter: String
    $membersLimit: Int = 100
    $membersAfter: String
    $pendingApprovalsLimit: Int = 100
    $pendingApprovalsAfter: String
  ) {
    node(id: $id) {
      id
      ... on WorkspaceEdge {
        ...WorkspaceEdgeFields
        node {
          ...WorkspaceFields
        }
      }
      ... on WorkspacePreviewEdge {
        node {
          ...WorkspacePreviewFields
        }
      }
    }
  }
  ${WorkspaceEdgeFieldsFragmentDoc}
  ${WorkspaceFieldsFragmentDoc}
  ${WorkspacePreviewFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceOrPreviewEdgeQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceOrPreviewEdgeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceOrPreviewEdgeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceOrPreviewEdgeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      groupsOrder: // value for 'groupsOrder'
 *      groupsLimit: // value for 'groupsLimit'
 *      groupsAfter: // value for 'groupsAfter'
 *      membersLimit: // value for 'membersLimit'
 *      membersAfter: // value for 'membersAfter'
 *      pendingApprovalsLimit: // value for 'pendingApprovalsLimit'
 *      pendingApprovalsAfter: // value for 'pendingApprovalsAfter'
 *   },
 * });
 */
export function useFetchWorkspaceOrPreviewEdgeQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchWorkspaceOrPreviewEdgeQuery,
    FetchWorkspaceOrPreviewEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWorkspaceOrPreviewEdgeQuery,
    FetchWorkspaceOrPreviewEdgeQueryVariables
  >(FetchWorkspaceOrPreviewEdgeDocument, options);
}
export function useFetchWorkspaceOrPreviewEdgeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkspaceOrPreviewEdgeQuery,
    FetchWorkspaceOrPreviewEdgeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWorkspaceOrPreviewEdgeQuery,
    FetchWorkspaceOrPreviewEdgeQueryVariables
  >(FetchWorkspaceOrPreviewEdgeDocument, options);
}
export type FetchWorkspaceOrPreviewEdgeQueryHookResult = ReturnType<
  typeof useFetchWorkspaceOrPreviewEdgeQuery
>;
export type FetchWorkspaceOrPreviewEdgeLazyQueryHookResult = ReturnType<
  typeof useFetchWorkspaceOrPreviewEdgeLazyQuery
>;
export type FetchWorkspaceOrPreviewEdgeQueryResult = Apollo.QueryResult<
  FetchWorkspaceOrPreviewEdgeQuery,
  FetchWorkspaceOrPreviewEdgeQueryVariables
>;
export const FetchWorkspaceSettingsDocument = gql`
  query FetchWorkspaceSettings($workspaceID: ID!) {
    workspaceSettings(workspaceID: $workspaceID) {
      ...WorkspaceSettingsFields
    }
  }
  ${WorkspaceSettingsFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceSettingsQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceSettingsQuery({
 *   variables: {
 *      workspaceID: // value for 'workspaceID'
 *   },
 * });
 */
export function useFetchWorkspaceSettingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchWorkspaceSettingsQuery,
    FetchWorkspaceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWorkspaceSettingsQuery,
    FetchWorkspaceSettingsQueryVariables
  >(FetchWorkspaceSettingsDocument, options);
}
export function useFetchWorkspaceSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkspaceSettingsQuery,
    FetchWorkspaceSettingsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWorkspaceSettingsQuery,
    FetchWorkspaceSettingsQueryVariables
  >(FetchWorkspaceSettingsDocument, options);
}
export type FetchWorkspaceSettingsQueryHookResult = ReturnType<
  typeof useFetchWorkspaceSettingsQuery
>;
export type FetchWorkspaceSettingsLazyQueryHookResult = ReturnType<
  typeof useFetchWorkspaceSettingsLazyQuery
>;
export type FetchWorkspaceSettingsQueryResult = Apollo.QueryResult<
  FetchWorkspaceSettingsQuery,
  FetchWorkspaceSettingsQueryVariables
>;
export const WorkspacesAppsDocument = gql`
  query WorkspacesApps {
    workspaces {
      edges {
        ...WorkspaceEdgeSimpleFields
        node {
          ...WorkspaceSimpleFields
          apps {
            edges {
              ...WorkspaceAppEdgeFields
            }
          }
        }
      }
    }
  }
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspaceAppEdgeFieldsFragmentDoc}
`;

/**
 * __useWorkspacesAppsQuery__
 *
 * To run a query within a React component, call `useWorkspacesAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkspacesAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkspacesAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkspacesAppsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    WorkspacesAppsQuery,
    WorkspacesAppsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkspacesAppsQuery, WorkspacesAppsQueryVariables>(
    WorkspacesAppsDocument,
    options
  );
}
export function useWorkspacesAppsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkspacesAppsQuery,
    WorkspacesAppsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkspacesAppsQuery, WorkspacesAppsQueryVariables>(
    WorkspacesAppsDocument,
    options
  );
}
export type WorkspacesAppsQueryHookResult = ReturnType<
  typeof useWorkspacesAppsQuery
>;
export type WorkspacesAppsLazyQueryHookResult = ReturnType<
  typeof useWorkspacesAppsLazyQuery
>;
export type WorkspacesAppsQueryResult = Apollo.QueryResult<
  WorkspacesAppsQuery,
  WorkspacesAppsQueryVariables
>;
export const PinMessageDocument = gql`
  mutation PinMessage($messageID: ID!) {
    pinMessage(input: { messageID: $messageID }) {
      id
    }
  }
`;
export type PinMessageMutationFn = Apollo.MutationFunction<
  PinMessageMutation,
  PinMessageMutationVariables
>;

/**
 * __usePinMessageMutation__
 *
 * To run a mutation, you first call `usePinMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePinMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pinMessageMutation, { data, loading, error }] = usePinMessageMutation({
 *   variables: {
 *      messageID: // value for 'messageID'
 *   },
 * });
 */
export function usePinMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PinMessageMutation,
    PinMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<PinMessageMutation, PinMessageMutationVariables>(
    PinMessageDocument,
    options
  );
}
export type PinMessageMutationHookResult = ReturnType<
  typeof usePinMessageMutation
>;
export type PinMessageMutationResult =
  Apollo.MutationResult<PinMessageMutation>;
export type PinMessageMutationOptions = Apollo.BaseMutationOptions<
  PinMessageMutation,
  PinMessageMutationVariables
>;
export const UnpinMessageDocument = gql`
  mutation UnpinMessage($messageID: ID!) {
    unpinMessage(input: { messageID: $messageID })
  }
`;
export type UnpinMessageMutationFn = Apollo.MutationFunction<
  UnpinMessageMutation,
  UnpinMessageMutationVariables
>;

/**
 * __useUnpinMessageMutation__
 *
 * To run a mutation, you first call `useUnpinMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnpinMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unpinMessageMutation, { data, loading, error }] = useUnpinMessageMutation({
 *   variables: {
 *      messageID: // value for 'messageID'
 *   },
 * });
 */
export function useUnpinMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnpinMessageMutation,
    UnpinMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnpinMessageMutation,
    UnpinMessageMutationVariables
  >(UnpinMessageDocument, options);
}
export type UnpinMessageMutationHookResult = ReturnType<
  typeof useUnpinMessageMutation
>;
export type UnpinMessageMutationResult =
  Apollo.MutationResult<UnpinMessageMutation>;
export type UnpinMessageMutationOptions = Apollo.BaseMutationOptions<
  UnpinMessageMutation,
  UnpinMessageMutationVariables
>;
export const MarkThreadEdgesDocument = gql`
  mutation MarkThreadEdges(
    $ids: [ID!]!
    $read: MarkThreadEdgeReadInput
    $seen: MarkThreadEdgeSeenInput
    $subscribed: MarkThreadEdgeSubscribedInput
    $archived: MarkThreadEdgeArchivedInput
    $reminder: MarkThreadEdgeReminderInput
    $starred: MarkThreadEdgeStarredInput
  ) {
    markThreadEdges(
      ids: $ids
      archived: $archived
      read: $read
      seen: $seen
      subscribed: $subscribed
      reminder: $reminder
      starred: $starred
    ) {
      ...ThreadEdgeFields
    }
  }
  ${ThreadEdgeFieldsFragmentDoc}
`;
export type MarkThreadEdgesMutationFn = Apollo.MutationFunction<
  MarkThreadEdgesMutation,
  MarkThreadEdgesMutationVariables
>;

/**
 * __useMarkThreadEdgesMutation__
 *
 * To run a mutation, you first call `useMarkThreadEdgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkThreadEdgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markThreadEdgesMutation, { data, loading, error }] = useMarkThreadEdgesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      read: // value for 'read'
 *      seen: // value for 'seen'
 *      subscribed: // value for 'subscribed'
 *      archived: // value for 'archived'
 *      reminder: // value for 'reminder'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useMarkThreadEdgesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkThreadEdgesMutation,
    MarkThreadEdgesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkThreadEdgesMutation,
    MarkThreadEdgesMutationVariables
  >(MarkThreadEdgesDocument, options);
}
export type MarkThreadEdgesMutationHookResult = ReturnType<
  typeof useMarkThreadEdgesMutation
>;
export type MarkThreadEdgesMutationResult =
  Apollo.MutationResult<MarkThreadEdgesMutation>;
export type MarkThreadEdgesMutationOptions = Apollo.BaseMutationOptions<
  MarkThreadEdgesMutation,
  MarkThreadEdgesMutationVariables
>;
export const MarkAllThreadEdgesDocument = gql`
  mutation MarkAllThreadEdges(
    $filter: ThreadsFilter
    $read: MarkThreadEdgeReadInput
    $seen: MarkThreadEdgeSeenInput
    $subscribed: MarkThreadEdgeSubscribedInput
    $archived: MarkThreadEdgeArchivedInput
    $reminder: MarkThreadEdgeReminderInput
    $starred: MarkThreadEdgeStarredInput
  ) {
    markAllThreadEdges(
      filter: $filter
      archived: $archived
      read: $read
      seen: $seen
      subscribed: $subscribed
      reminder: $reminder
      starred: $starred
    )
  }
`;
export type MarkAllThreadEdgesMutationFn = Apollo.MutationFunction<
  MarkAllThreadEdgesMutation,
  MarkAllThreadEdgesMutationVariables
>;

/**
 * __useMarkAllThreadEdgesMutation__
 *
 * To run a mutation, you first call `useMarkAllThreadEdgesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllThreadEdgesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllThreadEdgesMutation, { data, loading, error }] = useMarkAllThreadEdgesMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      read: // value for 'read'
 *      seen: // value for 'seen'
 *      subscribed: // value for 'subscribed'
 *      archived: // value for 'archived'
 *      reminder: // value for 'reminder'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useMarkAllThreadEdgesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAllThreadEdgesMutation,
    MarkAllThreadEdgesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAllThreadEdgesMutation,
    MarkAllThreadEdgesMutationVariables
  >(MarkAllThreadEdgesDocument, options);
}
export type MarkAllThreadEdgesMutationHookResult = ReturnType<
  typeof useMarkAllThreadEdgesMutation
>;
export type MarkAllThreadEdgesMutationResult =
  Apollo.MutationResult<MarkAllThreadEdgesMutation>;
export type MarkAllThreadEdgesMutationOptions = Apollo.BaseMutationOptions<
  MarkAllThreadEdgesMutation,
  MarkAllThreadEdgesMutationVariables
>;
export const FetchWorkspaceProfileDocument = gql`
  query FetchWorkspaceProfile($id: ID!) {
    node(id: $id) {
      id
      ... on Workspace {
        ...WorkspaceSimpleFields
      }
      ... on WorkspacePreview {
        ...WorkspacePreviewSimpleFields
      }
    }
  }
  ${WorkspaceSimpleFieldsFragmentDoc}
  ${WorkspacePreviewSimpleFieldsFragmentDoc}
`;

/**
 * __useFetchWorkspaceProfileQuery__
 *
 * To run a query within a React component, call `useFetchWorkspaceProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchWorkspaceProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchWorkspaceProfileQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchWorkspaceProfileQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchWorkspaceProfileQuery,
    FetchWorkspaceProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchWorkspaceProfileQuery,
    FetchWorkspaceProfileQueryVariables
  >(FetchWorkspaceProfileDocument, options);
}
export function useFetchWorkspaceProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchWorkspaceProfileQuery,
    FetchWorkspaceProfileQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchWorkspaceProfileQuery,
    FetchWorkspaceProfileQueryVariables
  >(FetchWorkspaceProfileDocument, options);
}
export type FetchWorkspaceProfileQueryHookResult = ReturnType<
  typeof useFetchWorkspaceProfileQuery
>;
export type FetchWorkspaceProfileLazyQueryHookResult = ReturnType<
  typeof useFetchWorkspaceProfileLazyQuery
>;
export type FetchWorkspaceProfileQueryResult = Apollo.QueryResult<
  FetchWorkspaceProfileQuery,
  FetchWorkspaceProfileQueryVariables
>;
export const CompleteMilestoneDocument = gql`
  mutation CompleteMilestone($input: CompleteMilestone!) {
    completeMilestone(input: $input)
  }
`;
export type CompleteMilestoneMutationFn = Apollo.MutationFunction<
  CompleteMilestoneMutation,
  CompleteMilestoneMutationVariables
>;

/**
 * __useCompleteMilestoneMutation__
 *
 * To run a mutation, you first call `useCompleteMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeMilestoneMutation, { data, loading, error }] = useCompleteMilestoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompleteMilestoneMutation,
    CompleteMilestoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CompleteMilestoneMutation,
    CompleteMilestoneMutationVariables
  >(CompleteMilestoneDocument, options);
}
export type CompleteMilestoneMutationHookResult = ReturnType<
  typeof useCompleteMilestoneMutation
>;
export type CompleteMilestoneMutationResult =
  Apollo.MutationResult<CompleteMilestoneMutation>;
export type CompleteMilestoneMutationOptions = Apollo.BaseMutationOptions<
  CompleteMilestoneMutation,
  CompleteMilestoneMutationVariables
>;
export const AuthConfigDocument = gql`
  query AuthConfig {
    me {
      ...UserFields
    }
    addresses {
      edges {
        node {
          id
          address
        }
      }
    }
    config {
      streamToken
    }
    settings {
      ...SettingsFields
    }
    workspaces {
      edges {
        ...WorkspaceEdgeSimpleFields
        node {
          ...WorkspaceSimpleFields
          members {
            totalCount
          }
          groups {
            totalCount
          }
        }
      }
    }
  }
  ${UserFieldsFragmentDoc}
  ${SettingsFieldsFragmentDoc}
  ${WorkspaceEdgeSimpleFieldsFragmentDoc}
  ${WorkspaceSimpleFieldsFragmentDoc}
`;

/**
 * __useAuthConfigQuery__
 *
 * To run a query within a React component, call `useAuthConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthConfigQuery,
    AuthConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AuthConfigQuery, AuthConfigQueryVariables>(
    AuthConfigDocument,
    options
  );
}
export function useAuthConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthConfigQuery,
    AuthConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AuthConfigQuery, AuthConfigQueryVariables>(
    AuthConfigDocument,
    options
  );
}
export type AuthConfigQueryHookResult = ReturnType<typeof useAuthConfigQuery>;
export type AuthConfigLazyQueryHookResult = ReturnType<
  typeof useAuthConfigLazyQuery
>;
export type AuthConfigQueryResult = Apollo.QueryResult<
  AuthConfigQuery,
  AuthConfigQueryVariables
>;
