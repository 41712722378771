import SidebarHeader, {
  sidebarHeaderHeight,
} from "components/SideBar/SidebarHeader/SidebarHeader";
import { Tabs } from "components/Tabs";
import { usePartitionState } from "components/routing/RoutingPartition";
import {
  ActivityTabs,
  routeParams as getRouteParams,
  locationFromRoute,
  tabPath,
} from "components/routing/utils";
import { useHistory } from "react-router-dom";
import ActivityList from "./ActivityList";

const ActivityMainTabs = ({
  desktopHeader,
  scrollRef,
  title,
}: {
  desktopHeader?: boolean;
  title?: string;
  scrollRef?: (el: HTMLUListElement | null) => void;
}) => {
  const history = useHistory();

  const { route } = usePartitionState(({ route }) => ({ route }));
  const routeParams = getRouteParams(locationFromRoute(route));

  const currentTab = (() => {
    switch (routeParams.t) {
      case ActivityTabs.All:
      case ActivityTabs.Mentions:
      case ActivityTabs.Reactions:
      case ActivityTabs.Groups:
      case ActivityTabs.Requests:
        return routeParams.t;
      default:
        return ActivityTabs.All;
    }
  })();

  const tabs = [
    {
      tab: ActivityTabs.All,
      tabTitle: ActivityTabs.All,
    },
    {
      tab: ActivityTabs.Mentions,
      tabTitle: ActivityTabs.Mentions,
    },
    {
      tab: ActivityTabs.Reactions,
      tabTitle: ActivityTabs.Reactions,
    },
    {
      tab: ActivityTabs.Groups,
      tabTitle: ActivityTabs.Groups,
    },
    /* {
      tab: ActivityTabs.Requests,
      tabTitle: ActivityTabs.Requests,
    }, */
  ];

  return (
    <div className="flex flex-col h-full w-full">
      {desktopHeader ? (
        <SidebarHeader className={`h-[${sidebarHeaderHeight}px]`} title={title}>
          <Tabs<ActivityTabs>
            className="pl-20"
            onClickTab={tab => history.push(tabPath(tab))}
            selectedTab={currentTab}
            size="small"
            tabs={tabs}
          />
        </SidebarHeader>
      ) : (
        <Tabs<ActivityTabs>
          variant="filter"
          className="mt-16 mb-8 pl-20"
          onClickTab={tab => history.push(tabPath(tab))}
          selectedTab={currentTab}
          tabs={tabs}
        />
      )}
      <ActivityList scrollRef={scrollRef} feedType={currentTab} />
    </div>
  );
};

export default ActivityMainTabs;
