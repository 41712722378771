import { useApolloClient } from "@apollo/client";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { useEffect, useRef, useState } from "react";

import {
  SubscriptionPlan,
  WorkspaceSubscriptionCheckoutQuery,
  WorkspaceSubscriptionCheckoutQueryDocument,
} from "generated/graphql";
import useAuthData from "hooks/useAuthData";
import useOnce from "hooks/useOnce";
import env from "utils/processEnv";

type Props = {
  workspaceID?: string;
  planID?: SubscriptionPlan["id"];
  onComplete?: () => void;
  destroying?: boolean;
};

const Payment = ({ destroying, workspaceID, planID, onComplete }: Props) => {
  const apolloClient = useApolloClient();
  const { authData, authReady } = useAuthData();
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const checkoutRef = useRef<
    | Awaited<ReturnType<Exclude<typeof stripe, null>["initEmbeddedCheckout"]>>
    | undefined
  >();
  const checkoutLoading = useRef(false);
  const checkoutPlaceholderRef = useRef<HTMLDivElement | null>(null);

  const prevPlanID = useRef(planID);

  useOnce(() => {
    (async () => {
      setStripe(await loadStripe(env.stripePublicKey));
    })();
  });

  useEffect(() => {
    if (!workspaceID || destroying) return;

    (async () => {
      if (
        !authReady ||
        checkoutLoading.current ||
        !checkoutPlaceholderRef.current ||
        !checkoutRef ||
        !planID
      ) {
        return;
      }

      checkoutLoading.current = true;

      if (planID && prevPlanID.current !== planID) {
        prevPlanID.current = planID;
        checkoutRef.current?.destroy();
        checkoutRef.current = undefined;
      }

      if (!checkoutRef.current) {
        checkoutRef.current = await stripe?.initEmbeddedCheckout({
          fetchClientSecret: async () => {
            const response =
              await apolloClient.query<WorkspaceSubscriptionCheckoutQuery>({
                query: WorkspaceSubscriptionCheckoutQueryDocument,
                fetchPolicy: "network-only",
                variables: {
                  input: {
                    subscriptionPlanID: planID,
                  },
                  workspaceID,
                },
              });
            const { stripeClientSecret } =
              response.data.workspaceSubscriptionCheckout;
            return stripeClientSecret;
          },
          onComplete: () => {
            onComplete?.();
          },
        });

        if (checkoutRef.current) {
          checkoutRef.current.mount(checkoutPlaceholderRef.current);
        }
      }

      checkoutLoading.current = false;
    })();
  }, [
    apolloClient,
    authData,
    authReady,
    destroying,
    planID,
    onComplete,
    stripe,
    workspaceID,
  ]);

  useEffect(() => {
    if (!destroying) return;
    checkoutRef.current?.destroy();
    checkoutRef.current = undefined;
  }, [destroying]);

  useEffect(() => {
    return () => {
      if (!checkoutRef.current) return;
      checkoutRef.current?.destroy();
    };
  }, []);

  return <div ref={checkoutPlaceholderRef} className="w-full" />;
};

export default Payment;
